import {ReactElement} from "react";
import {useRecoilState} from "recoil";
import {uiConfigState} from "store/uiConfig";
import {Checkbox} from "@mui/material";

type Props = {
  placeholder?: string;
  checked?: boolean;
  name?: string;
  onChange?: any;
  onBlur?: any;
  disabled?: boolean;
};
function MDCheckBox({
  placeholder,
  checked,
  name,
  onChange,
  onBlur,
  disabled,
}: Props): ReactElement {
  const [uiConfig] = useRecoilState(uiConfigState);
  const {
    darkMode,
    sidenavColor,
  } = uiConfig;

  const checkColor = ({ palette }: { palette: any }) => darkMode ? palette["transparent"].main: (sidenavColor === "white" || sidenavColor === "light") ? palette["dark"].main: palette[sidenavColor].main;
  const checkboxColor = ({ palette }: { palette: any }) => (!darkMode && (sidenavColor === "white" || sidenavColor === "light")) ? palette["dark"].main: palette[sidenavColor].main;

  return (
    <Checkbox
      placeholder={placeholder}
      checked={checked}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      sx={{
        color: checkboxColor,
        "&.Mui-checked": {
          color: checkboxColor,
          "& .MuiSvgIcon-root": {
            backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 22 22'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")`,
            borderColor: checkboxColor,
            border: `1px solid ${checkboxColor}`,
            backgroundColor: checkColor,
          },
        },
      }}
    />
  );
}

export default MDCheckBox;
