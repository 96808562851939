// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled, Theme } from "@mui/material/styles";

const EVENT_LOG_WIDTH = (window.innerWidth / 7) * 3;
const EVENT_LOG_MIN_WIDTH = 820;

export default styled(Drawer)(
  ({ theme, ownerState }: { theme?: Theme; ownerState: any }) => {
    const { boxShadows, functions, transitions } = theme;
    const { openEventLog } = ownerState;

    const eventLogWidth =
      EVENT_LOG_WIDTH <= EVENT_LOG_MIN_WIDTH
        ? EVENT_LOG_MIN_WIDTH
        : EVENT_LOG_WIDTH;
    const { lg } = boxShadows;
    const { pxToRem } = functions;

    // drawer styles when openEventLog={true}
    const drawerOpenStyles = () => ({
      width: eventLogWidth,
      left: "initial",
      right: 0,
      transition: transitions.create("right", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.short,
      }),
    });

    // drawer styles when openEventLog={false}
    const drawerCloseStyles = () => ({
      left: "initial",
      right: pxToRem(eventLogWidth * -1 - 10),
      transition: transitions.create("all", {
        easing: transitions.easing.sharp,
        duration: transitions.duration.short,
      }),
    });

    return {
      "& .MuiDrawer-paper": {
        height: "100vh",
        margin: 0,
        padding: `0 ${pxToRem(10)}`,
        borderRadius: 0,
        boxShadow: lg,
        overflowY: "auto",
        zIndex: 1300,
        ...(openEventLog ? drawerOpenStyles() : drawerCloseStyles()),
      },
    };
  }
);
