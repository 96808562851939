import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { Account, Role } from "lib/types/account";

const getAll = async () => {
  const response = await api.get(requestPathname.ACCOUNT_ALL);
  return response.data;
};

const get = async (id: number) => {
  const response = await api.get<Account>(`${requestPathname.ACCOUNT}/${id}`);
  return response.data;
};

const create = async (username: string, password: string, roles: Role[]) => {
  const response = await api.post(requestPathname.ACCOUNT, {
    username,
    password,
    roles,
  });
  return response.data;
};

const modify = async (
  id: number,
  username: string,
  password: string,
  roles: Role[]
) => {
  const response = await api.put(`${requestPathname.ACCOUNT}/${id}`, {
    username,
    password,
    roles,
  });
  return response.data;
};

const del = async (id: number) => {
  const response = await api.delete(`${requestPathname.ACCOUNT}/${id}`);
  return response.data;
};

const checkPassword = async (
  id: number,
  username: string,
  password: string,
  roles: Role[]
) => {
  const response = await api.post(`${requestPathname.ACCOUNT}/${id}/checkPassword`, {
    username,
    password,
    roles,
  });
  return response.data;
};

const accountApi = {
  getAll,
  get,
  create,
  modify,
  del,
  checkPassword,
};

export default accountApi;
