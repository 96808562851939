import React, { createContext } from "react";

export type PopoverType =
  | "PASSWORD"
  | "SET_FAN_SPEED";

export type PopoverState = {
  anchorEl: Element;
  openPopover: ({
    type,
    text,
    event,
    onSuccess,
  }: {
    type?: PopoverType;
    text?: string;
    event: React.MouseEvent<HTMLElement>;
    onSuccess: (input?: string) => void;
  }) => void;
};

const PopoverContext = createContext<PopoverState>({
  anchorEl: null,
  openPopover: () => {},
});

export default PopoverContext;
