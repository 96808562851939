import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

type Props = {
  darkMode: boolean;
  handleClickCloseButton: () => void;
};

function EventLogHeader({ darkMode, handleClickCloseButton }: Props) {
  const { t } = useTranslation("Translation");
  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems="baseline"
      pt={4}
      pb={0.5}
      px={2}
    >
      <MDBox display="flex" justifyContent="center">
        <Grid container spacing={1} alignItems="center">
          <Grid item ml={2} mt={0.5} lineHeight={1}>
            <MDBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor="black"
              color="white"
              width="2rem"
              height="2rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              <Icon fontSize="medium">event_note</Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={1} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium">
                {t("Event log")}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Icon
        sx={({ typography: { size }, palette: { dark, white } }) => ({
          fontSize: `${size.lg} !important`,
          color: darkMode ? white.main : dark.main,
          stroke: "currentColor",
          strokeWidth: "2px",
          cursor: "pointer",
          transform: "translateY(5px)",
        })}
        onClick={handleClickCloseButton}
      >
        close
      </Icon>
    </MDBox>
  );
}

export default EventLogHeader;
