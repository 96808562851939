import {ReactElement} from "react";
import {useRecoilState} from "recoil";
import {uiConfigState} from "store/uiConfig";
import {TextField} from "@mui/material";

type Props = {
  id?: string;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: any;
  onBlur?: any;
  disabled?: boolean;
};
function MDTextField({
  id,
  type,
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled
}: Props): ReactElement {
  const [uiConfig] = useRecoilState(uiConfigState);
  const {
    darkMode,
    sidenavColor,
  } = uiConfig;

  return (
    <TextField
      id={id}
      type={type}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      fullWidth
      sx={{
        colorScheme: darkMode ? "dark" : "light",
        "& .MuiInputLabel-root.Mui-focused": {
          color: ({ palette }: { palette: any }) => palette[sidenavColor].main,
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: ({ palette }: { palette: any }) => palette[sidenavColor].main,
          color: ({ palette }: { palette: any }) => palette[sidenavColor].main,
        },
      }}
    />
  );
}

export default MDTextField;
