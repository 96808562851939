import isEqual from "lodash.isequal";
import React, {memo, ReactElement, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {DropzoneFile} from "dropzone";
import MDDropzone from "components/MDDropzone";
import dashboardApi from "apis/dashboard/api";
import useApiRequest from "lib/hooks/useApiRequest";

function FirmwareUpdate(): ReactElement {
  const [isInitialized, setIsInitialized] = useState(false);
  const { request: controlRequest } = useApiRequest();
  const { t } = useTranslation("Translation");

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
      controlRequest({
        targetApi: () => dashboardApi.deleteFirmwareFile(""),
      });
    }
  }, [isInitialized, controlRequest]);

  return (
    <MDDropzone url="/api/systemBMS/uploadFirmwareFile" options={{
      addRemoveLinks: true,
      maxFiles: 1,
      parallelUploads: 1,
      dictRemoveFile: t("Delete"),
      dictDefaultMessage: t("Drag or click a file here to upload it."),
      uploadMultiple: false,
      acceptedFiles: ".bin",
      autoProcessQueue: true,
      autoQueue: true,
      removedfile: function (file: DropzoneFile) {
        controlRequest({
          targetApi: () => dashboardApi.deleteFirmwareFile(file.name),
        });
        file.previewElement.remove();
      }
    }} />
  );
}

export default memo(FirmwareUpdate, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
