import { recoilKey } from "lib/consts/recoilKey";
import { Setting } from "lib/types/setting";
import { atom } from "recoil";

export type Runtime = {
  isStart: boolean;
  isLock: boolean;
  isLocal: boolean;
  isStartStopProcessing: boolean;
  isLockUnlockProcessing: boolean;
  isLocalRemoteProcessing: boolean;
};
export type SystemConfigState = {
  setting: Setting;
  runtime: Runtime;
  openConfigurator: boolean;
  openEventLog: boolean;
};

const INITIAL_RUNTIME_DATA = {
  isStart: false,
  isLock: false,
  isLocal: false,
  isStartStopProcessing: true,
  isLockUnlockProcessing: true,
  isLocalRemoteProcessing: true,
};

export const systemConfigState = atom<SystemConfigState>({
  key: recoilKey.SYSTEM_CONFIG,
  default: {
    setting: null,
    runtime: INITIAL_RUNTIME_DATA,
    openConfigurator: false,
    openEventLog: false,
  },
});
