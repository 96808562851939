import Modal from "@mui/material/Modal";
import MDBox from "components/MDBox";
import { PropsWithChildren } from "react";
import colors from "../../assets/theme-dark/base/colors";
import {useRecoilState} from "recoil";
import {uiConfigState} from "../../store/uiConfig";

type Props = {
  open: boolean;
  onClose: () => void;
};

function MDModal({ open, onClose, children }: PropsWithChildren<Props>) {
  const [uiConfig] = useRecoilState(uiConfigState);
  const { darkMode } = uiConfig;
  const { white, dark } = colors;
  return (
    <Modal open={open} onClose={onClose}>
      <MDBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 20,
          borderRadius: "10px",
          outline: "none",
          py: 4,
          px: 3,
          backgroundColor: darkMode ? dark.main : white.main
        }}
      >
        {children}
      </MDBox>
    </Modal>
  );
}

export default MDModal;
