import { ControlType } from "lib/types/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import useApiRequest from "lib/hooks/useApiRequest";
import useSystemButtons, {
  SystemButton,
  SystemButtons,
} from "lib/hooks/useSystemButtons";
import {
  PackBMSData,
  PackBMSStatus,
  Se1001,
  Se1002,
} from "lib/types/pack";
import {changeDecimalPoint, getUserRole} from "lib/utils/common";
import {
  getPackBMSEnvironmentFanColor,
  getPackBMSEnvironmentSensorColor,
} from "lib/utils/pack";
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import useWebSocket from "lib/hooks/useWebSocket";
import { requestSocketPathname } from "lib/consts/socket";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { userState } from "store/user";
import environmentApi from "apis/devices/packBMS/environment/api";
import PopoverContext from "context/popover/popoverContext";

type Props = {
  packBMSNo: number;
};

type SensorInitData = {
  device: Se1001;
  status: boolean;
};

type FanInitData = {
  device: Se1002;
  status: boolean;
};

type SensorButtons = {
  sensorControl?: SystemButton[];
  sensorTemperature?: SystemButton[];
  sensorOther?: SystemButton[];
};

type FanButtons = {
  fan?: SystemButton[];
};

type ControlModeType =
  | "SENSOR"
  | "FAN";

const SENSOR_INIT_DATA: SensorInitData = {
  device: null,
  status: null,
};

const FAN_INIT_DATA: FanInitData = {
  device: null,
  status: null,
};

const INIT_SENSOR_BUTTONS = (systemButtons: SystemButtons) => ({
  sensorControl: systemButtons.sensorControl,
  sensorTemperature: systemButtons.sensorTemperature,
  sensorOther: systemButtons.sensorOther,
});

const INIT_FAN_BUTTONS = (systemButtons: SystemButtons) => ({
  fan: systemButtons.fan,
});

function useEnvironment({ packBMSNo }: Props) {
  const user = useRecoilValue(userState);
  const { setting, runtime } = useRecoilValue(systemConfigState);
  const { subscribe, isConnected } = useWebSocket();
  const { systemButtons } = useSystemButtons();
  const [sensor, setSensor] = useState<SensorInitData>(SENSOR_INIT_DATA);
  const [fan, setFan] = useState<FanInitData>(FAN_INIT_DATA);
  const [sensorButtons, setSensorButtons] = useState<SensorButtons>(
    INIT_SENSOR_BUTTONS(systemButtons)
  );
  const [fanButtons, setFanButtons] = useState<FanButtons>(
    INIT_FAN_BUTTONS(systemButtons)
  );
  const ROLE = getUserRole(user);
  const isRemote = !runtime.isLocal || runtime.isLocalRemoteProcessing;
  const isUseSensor = useMemo(() => setting.packBMSSettingList[packBMSNo].useSensor, [packBMSNo, setting.packBMSSettingList]);
  const isUseFan = useMemo(() => setting.packBMSSettingList[packBMSNo].useFan, [packBMSNo, setting.packBMSSettingList]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [deviceData, setDeviceData] = useState<PackBMSData>();
  const [statusData, setStatusData] = useState<PackBMSStatus>();
  const { request: statusRequest } = useApiRequest<PackBMSStatus>();
  const { request: deviceRequest } = useApiRequest<PackBMSData>();
  const { request: controlRequest, isLoading: isControlButtonsLoading } = useApiRequest();
  const [eventCurrentTarget, setEventCurrentTarget] = useState(null);
  const { openPopover } = useContext(PopoverContext);
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation("Translation");
  const se1001 = sensor.device;
  const se1002 = fan.device;

  const init = useCallback((sensorStatus: boolean, fanStatus: boolean) => {
    if (!sensorStatus) {
      setSensorButtons(INIT_SENSOR_BUTTONS(systemButtons));
    }
    if (!fanStatus) {
      setFanButtons(INIT_FAN_BUTTONS(systemButtons));
    }
  }, [systemButtons]);

  const deviceRequestOnSuccess = useCallback(
    (response: PackBMSData) => {
      response ? setDeviceData(response) : init(false, false);
    },
    [init]
  );

  const statusRequestOnSuccess = useCallback(
    (response: PackBMSStatus) => {
      response ? setStatusData(response) : init(false, false);
    },
    [init]
  );

  const initialApiRequest = useCallback(() => {
    deviceRequest({
      targetApi: () => environmentApi.getData(packBMSNo),
      onSuccess: (response) => deviceRequestOnSuccess(response as PackBMSData),
    });
    statusRequest({
      targetApi: () => environmentApi.getStatus(packBMSNo),
      onSuccess: (response) =>
        statusRequestOnSuccess(response as PackBMSStatus),
    });
  }, [
    packBMSNo,
    deviceRequest,
    statusRequest,
    deviceRequestOnSuccess,
    statusRequestOnSuccess,
  ]);

  const getSensorTemperatureTitle = useCallback(
    ({
       buttonName,
       index,
       se1001,
     }: {
      buttonName: string;
      index: number;
      se1001: Se1001;
    }) => {
      const targetButton = systemButtons.sensorTemperature[index];

      switch (buttonName) {
        case "temperature_1_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp0, se1001.tmp_SF)} °C)`;
        case "temperature_2_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp1, se1001.tmp_SF)} °C)`;
        case "temperature_3_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp2, se1001.tmp_SF)} °C)`;
        case "temperature_4_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp3, se1001.tmp_SF)} °C)`;
        case "temperature_5_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp4, se1001.tmp_SF)} °C)`;
        case "temperature_6_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp5, se1001.tmp_SF)} °C)`;
        case "temperature_7_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp6, se1001.tmp_SF)} °C)`;
        case "temperature_8_button":
          return `${targetButton.title} (${changeDecimalPoint(se1001.tmp7, se1001.tmp_SF)} °C)`;
        default:
          return `${targetButton.title}`;
      }
    },
    [systemButtons]
  );

  const getSensorOtherTitle = useCallback(
    ({
       buttonName,
       index,
       se1001,
     }: {
      buttonName: string;
      index: number;
      se1001: Se1001;
    }) => {
      const targetButton = systemButtons.sensorOther[index];

      switch (buttonName) {
        case "hydrogen1_button":
          const hydrogen1 = se1001.hydrogen1;
          return `${targetButton.title} (${changeDecimalPoint(
            hydrogen1,
            se1001.hydrogen_SF
          )} ppm)`;
        case "hydrogen2_button":
          const hydrogen2 = se1001.hydrogen2;
          return `${targetButton.title} (${changeDecimalPoint(
            hydrogen2,
            se1001.hydrogen_SF
          )} ppm)`;
        case "humidity_button":
          const humidity = se1001.humidity;
          return `${targetButton.title} (${changeDecimalPoint(
            humidity,
            se1001.humidity_SF
          )} %)`;
        default:
          return `${targetButton.title}`;
      }
    },
    [systemButtons]
  );

  const getFanTitle = useCallback(
    ({
       buttonName,
       index,
       se1002,
     }: {
      buttonName: string;
      index: number;
      se1002: Se1002;
    }) => {
      const targetButton = systemButtons.fan[index];

      switch (buttonName) {
        case "fan_1_button":
          return `${targetButton.title} (${se1002.fan0} %)`;
        case "fan_2_button":
          return `${targetButton.title} (${se1002.fan1} %)`;
        case "fan_3_button":
          return `${targetButton.title} (${se1002.fan2} %)`;
        case "fan_4_button":
          return `${targetButton.title} (${se1002.fan3} %)`;
        case "fan_5_button":
          return `${targetButton.title} (${se1002.fan4} %)`;
        case "fan_6_button":
          return `${targetButton.title} (${se1002.fan5} %)`;
        case "fan_7_button":
          return `${targetButton.title} (${se1002.fan6} %)`;
        case "fan_8_button":
          return `${targetButton.title} (${se1002.fan7} %)`;
        case "fan_9_button":
          return `${targetButton.title} (${se1002.fan8} %)`;
        case "fan_10_button":
          return `${targetButton.title} (${se1002.fan9} %)`;
        case "fan_11_button":
          return `${targetButton.title} (${se1002.fan10} %)`;
        case "fan_12_button":
          return `${targetButton.title} (${se1002.fan11} %)`;
        case "fan_13_button":
          return `${targetButton.title} (${se1002.fan12} %)`;
        case "fan_14_button":
          return `${targetButton.title} (${se1002.fan13} %)`;
        case "fan_15_button":
          return `${targetButton.title} (${se1002.fan14} %)`;
        case "fan_16_button":
          return `${targetButton.title} (${se1002.fan15} %)`;
        case "fan_17_button":
          return `${targetButton.title} (${se1002.fan16} %)`;
        case "fan_18_button":
          return `${targetButton.title} (${se1002.fan17} %)`;
        case "fan_19_button":
          return `${targetButton.title} (${se1002.fan18} %)`;
        case "fan_20_button":
          return `${targetButton.title} (${se1002.fan19} %)`;
        case "fan_21_button":
          return `${targetButton.title} (${se1002.fan20} %)`;
        case "fan_22_button":
          return `${targetButton.title} (${se1002.fan21} %)`;
        case "fan_23_button":
          return `${targetButton.title} (${se1002.fan22} %)`;
        case "fan_24_button":
          return `${targetButton.title} (${se1002.fan23} %)`;
        default:
          return `${targetButton.title}`;
      }
    },
    [systemButtons]
  );

  const handleClickSensorControlButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        const { name } = e.target;
        const { color } = sensorButtons.sensorControl.find(
          (button) => button.name === name
        );
        const isOn = color === "warning";

        const getTargetApi = (type: ControlType, value: number) => () =>
          controlRequest({
            targetApi: () => environmentApi.sensorControl(packBMSNo, { type, value }),
          });

        const requestMap = {
          write_warning_light_button: getTargetApi("SET_WARN_LIGHT", isOn ? 1 : 2),
        };

        requestMap[name as keyof typeof requestMap]();
      }
    },
    [sensorButtons.sensorControl, controlRequest, packBMSNo]
  );

  const handleClickFanButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, input?: string) => {
      if (e.target instanceof HTMLButtonElement) {
        const { name } = e.target;
        const speed = Number(input);
        const no = name.match(/\d+/g);
        if (no) {
          const fanNo = Number(no.join('')) - 1;
          const getTargetApi = (type: ControlType, value: number) => () =>
            controlRequest({
              targetApi: () => environmentApi.fanControl(packBMSNo, fanNo, { type, value }),
            });

          const requestMap = {
            fan_1_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_2_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_3_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_4_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_5_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_6_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_7_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_8_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_9_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_10_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_11_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_12_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_13_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_14_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_15_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_16_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_17_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_18_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_19_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_20_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_21_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_22_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_23_button: getTargetApi("SET_FAN_SPEED", speed),
            fan_24_button: getTargetApi("SET_FAN_SPEED", speed),
          };

          requestMap[name as keyof typeof requestMap]();
        }
      }
    },
    [controlRequest, packBMSNo]
  );

  const handleOpenModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
      if (e.target instanceof HTMLButtonElement) {
        const { innerText } = e.target;
        if (isRemote) {
          openModal(
            <DefaultModal
              status="error"
              title={t("Unable to control due to remote condition.")}
            />
          );
          return;
        }

        const controlModeMap = {
          SENSOR: {
            modalTitle: `"${innerText}" ${t("Do you want to sensor control?")}`,
            onSuccess: () => handleClickSensorControlButton(e),
          },
          FAN: {
            modalTitle: function (input?: string) { return `"${innerText}" => ${input}% ${t("Do you want to fan control?")}`},
            onSuccess: function(input?: string) {handleClickFanButton(e, input)},
          },
        };

        if (type === "FAN") {
          if (e.currentTarget === null) {
            e.currentTarget = eventCurrentTarget;
          }
          openPopover({
            type: "SET_FAN_SPEED",
            text: t("Fan Speed"),
            event: e,
            onSuccess: (input?: string) => {
              setEventCurrentTarget(null);
              openModal(
                <DefaultModal
                  type="confirm"
                  status="warning"
                  title={controlModeMap[type].modalTitle(input)}
                  onSuccess={() => controlModeMap[type].onSuccess(input)}
                />
              );
            },
          });
        } else {
          openModal(
            <DefaultModal
              type="confirm"
              status="warning"
              title={controlModeMap[type].modalTitle}
              onSuccess={controlModeMap[type].onSuccess}
            />
          );
        }
      }
    },
    [
      isRemote,
      t,
      openModal,
      openPopover,
      handleClickSensorControlButton,
      handleClickFanButton,
      eventCurrentTarget
    ]
  );

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
      setEventCurrentTarget(e.currentTarget);
      openPopover({
        event: e,
        onSuccess: () => {
          handleOpenModal(e, type);
        },
      });
    },
    [handleOpenModal, openPopover]
  );

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;

    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_DATA(packBMSNo),
      (body: PackBMSData) => {
        setDeviceData(body);
      }
    );
    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_STATUS(packBMSNo),
      (body: PackBMSStatus) => {
        setStatusData(body);
      }
    );
  }, [subscribe, isConnected, packBMSNo]);

  useEffect(() => {
    if (deviceData && statusData) {
      if (!statusData.sensorStatus || !statusData.fanStatus) {
        init(statusData.sensorStatus, statusData.fanStatus);
        if (!statusData.sensorStatus && !statusData.fanStatus) {
          return;
        }
      }
      const se1001 = deviceData.se1001;
      const se1002 = deviceData.se1002;
      setSensor((prev) => {
        return {
          ...prev,
          device: se1001,
        };
      });
      setFan((prev) => {
        return {
          ...prev,
          device: se1002,
        };
      });
      setSensorButtons((prev) => {
        return {
          ...prev,
          sensorControl: prev.sensorControl.map((status) => ({
            ...status,
            color: getPackBMSEnvironmentSensorColor({
              buttonName: status.name,
              se1001: se1001
            }),
          })),
          sensorTemperature: prev.sensorTemperature?.map((status, index) => ({
            ...status,
            color: getPackBMSEnvironmentSensorColor({
              buttonName: status.name,
              se1001: se1001
            }),
            title: getSensorTemperatureTitle({
              buttonName: status.name,
              index: index,
              se1001: se1001
            }),
          })),
          sensorOther: prev.sensorOther?.map((status, index) => ({
            ...status,
            color: getPackBMSEnvironmentSensorColor({
              buttonName: status.name,
              se1001: se1001
            }),
            title: getSensorOtherTitle({
              buttonName: status.name,
              index: index,
              se1001: se1001
            }),
          })),
        };
      });
      setFanButtons((prev) => {
        return {
          ...prev,
          fan: prev.fan.map((status, index) => ({
            ...status,
            color: getPackBMSEnvironmentFanColor({
              buttonName: status.name,
              se1002: se1002
            }),
            title: getFanTitle({
              buttonName: status.name,
              index: index,
              se1002: se1002
            }),
          })),
        };
      });
    }
  }, [deviceData, statusData, init, getSensorTemperatureTitle, getSensorOtherTitle, getFanTitle]);

  useEffect(() => {
    if (!deviceData || !statusData) return;
    if (!statusData.status || !statusData.sensorStatus || !statusData.fanStatus) {
      setIsInitialized(false);
      init(statusData.sensorStatus, statusData.fanStatus);
      return;
    }
    if (isInitialized) return;
    setIsInitialized(true);
  }, [deviceData, statusData, init, isInitialized]);

  return {
    isUseSensor,
    isUseFan,
    sensor,
    fan,
    se1001,
    se1002,
    sensorButtons,
    fanButtons,
    isControlButtonsLoading,
    ROLE,
    handleClickButton,
  };
}

export default useEnvironment;
