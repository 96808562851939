import { Icon } from "@mui/material";
import MDPagination from "components/MDPagination";
import React from "react";
import { PaginationStyle } from "..";

type Props = {
  currentPage: number;
  paginationStyle: PaginationStyle;
  pages: number[];
  canPreviousPage?: boolean;
  canNextPage?: boolean;
  isShow?: boolean;
  pageCount?: number;
  setCurrentPage: (page: number) => void;
};

const createPage = (page: number, pageCount: number, totalPage: number) => {
  const startPageNumber =
    Math.ceil((page + 1) / pageCount) * pageCount - pageCount;
  const pages = Array(pageCount)
    .fill(-1)
    .map((_, idx) => {
      if (idx + startPageNumber > totalPage - 1) return -1;
      return idx + startPageNumber;
    })
    .filter((item) => item !== -1);
  return pages;
};

function Pagination({
  currentPage,
  paginationStyle,
  pages,
  canNextPage = currentPage !== pages.length - 1,
  canPreviousPage = currentPage !== 0,
  isShow = true,
  pageCount = 5,
  setCurrentPage,
}: Props) {
  if (!isShow) return <></>;

  const handleClickPrevPage = () => setCurrentPage(currentPage - 1);
  const handleClickNextPage = () => setCurrentPage(currentPage + 1);
  const handleClickPage = (page: number) => setCurrentPage(page);

  const renderPagination = createPage(currentPage, pageCount, pages.length).map(
    (option) => (
      <MDPagination
        item
        key={option}
        onClick={() => handleClickPage(Number(option))}
        active={currentPage === option}
      >
        {option + 1}
      </MDPagination>
    )
  );

  return (
    <MDPagination
      variant={paginationStyle.variant ? paginationStyle.variant : "gradient"}
      color={paginationStyle.color ? paginationStyle.color : "info"}
    >
      <MDPagination
        item
        onClick={handleClickPrevPage}
        disabled={!canPreviousPage}
      >
        <Icon>chevron_left</Icon>
      </MDPagination>
      {renderPagination}
      <MDPagination item onClick={handleClickNextPage} disabled={!canNextPage}>
        <Icon>chevron_right</Icon>
      </MDPagination>
    </MDPagination>
  );
}

export default Pagination;
