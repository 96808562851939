import api from "apis/api";
import { requestPathname } from "lib/consts/api";

const getStarted = async () => {
  const response = await api.get(requestPathname.RUNTIME_IS_START);
  return response.data;
};

const getLock = async () => {
  const response = await api.get(requestPathname.RUNTIME_IS_LOCK);
  return response.data;
};

const getLocal = async () => {
  const response = await api.get(requestPathname.RUNTIME_IS_LOCAL);
  return response.data;
};

const getStartStopProcessing = async () => {
  const response = await api.get(requestPathname.RUNTIME_PROCESSING_START_STOP);
  return response.data;
};

const getLockUnlockProcessing = async () => {
  const response = await api.get(
    requestPathname.RUNTIME_PROCESSING_LOCK_UNLOCK
  );
  return response.data;
};

const getLocalRemoteProcessing = async () => {
  const response = await api.get(
    requestPathname.RUNTIME_PROCESSING_LOCAL_REMOTE
  );
  return response.data;
};

const command = async (command: string) => {
  const response = await api.post(requestPathname.RUNTIME_COMMAND, { command });
  return response.data;
};

const runtimeApi = {
  getStarted,
  getLock,
  getLocal,
  getStartStopProcessing,
  getLockUnlockProcessing,
  getLocalRemoteProcessing,
  command,
};

export default runtimeApi;
