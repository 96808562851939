import {
  Client,
  StompConfig,
  StompHeaders,
  StompSubscription,
} from "@stomp/stompjs";
import { useCallback, useEffect, useRef } from "react";

interface ObjectType {
  [key: string]: any;
}

let stompClient: Client;
let isConnected = false;
let isReconnected = false;
const subscriptions: {
  [key: string]: StompSubscription & { callback: Function };
} = {};

function useWebSocket(config?: StompConfig, callback?: () => void) {
  const currentSubscriptionPaths = useRef<string[]>([]);

  const connect = useCallback(() => {
    if (!stompClient) {
      stompClient = new Client({
        brokerURL: `ws://${
          window.location.host === "localhost" ||
          window.location.host === "127.0.0.1" ||
          process.env.NODE_ENV === "development"
            ? "localhost:8080"
            : window.location.host
        }/ws`,
        reconnectDelay: 1000,
        ...config,
      });
      stompClient.activate();
    }

    stompClient.onConnect = (frame) => {
      isConnected = true;
      if (isReconnected) {
        const subscriptionList = Object.entries(subscriptions);

        subscriptionList.forEach((subscribeItem) => {
          const path = subscribeItem[0];
          const callback = subscribeItem[1].callback;
          stompClient.subscribe(path, (message) => {
            const body = JSON.parse(message.body);
            callback(body);
          });
        });
        isReconnected = false;
        return;
      }
      callback && callback();
    };

    stompClient.onWebSocketClose = (closeEvent) => {
      if (closeEvent.code !== 1000) {
        isReconnected = true;
      }
      isConnected = false;
    };
  }, [callback, config]);

  const send = useCallback(
    (path: string, body: ObjectType, headers: StompHeaders) => {
      stompClient.publish({
        destination: path,
        headers,
        body: JSON.stringify(body),
      });
    },
    []
  );

  const subscribe = useCallback(
    <T>(path: string, callback: (msg: T) => void) => {
      if (!stompClient) return;
      if (subscriptions[path]) return;

      const subscription = stompClient.subscribe(path, (message) => {
        const body: T = JSON.parse(message.body);
        callback(body);
      });
      subscriptions[path] = { ...subscription, callback };
      currentSubscriptionPaths.current.push(path);
    },
    []
  );

  const unsubscribe = useCallback((path: string) => {
    if (!subscriptions[path]) return;
    subscriptions[path].unsubscribe();
    delete subscriptions[path];
  }, []);

  const disconnect = useCallback(() => {
    stompClient.deactivate().then();
  }, []);

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    const subscriptionPaths = currentSubscriptionPaths.current;

    return () => {
      for (let i = 0; i < subscriptionPaths.length; i++) {
        unsubscribe(subscriptionPaths[i]);
      }
    };
  }, [unsubscribe]);

  return {
    send,
    subscribe,
    unsubscribe,
    subscriptions,
    disconnect,
    isConnected,
  };
}

export default useWebSocket;
