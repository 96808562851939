import { Divider } from "@mui/material";
import MDBox from "components/MDBox";
import EventLogRoot from "layouts/components/eventLog/eventLogRoot";
import DataTable from "../dataTable";
import useEventLog from "./hooks/useEventLog";
import EventLogHeader from "./components/header";
import UtilButtons from "./components/utilButtons";
import {ForwardedRef, forwardRef, ReactElement} from "react";
import SliderPaper from "../sliderPaper";

type Props = {};

const TABLE_ENTRIES = {
  defaultValue: 10,
  entries: ["10", "20", "50", "100", "500", "1000"],
  isEntriesUseAll: true,
};
const TABLE_EXCLUDE_SORT_LIST = ["status", "description", "type"];

function EventLog(_: Props, ref: ForwardedRef<HTMLDivElement>): ReactElement {
  const {
    openEventLog,
    darkMode,
    tableEventLogList,
    formData,
    csvData,
    csvRef,
    setPage,
    createCsv,
    handleCloseEventLog,
    handleChangeDate,
    handleClickDownloadCSV,
  } = useEventLog();

  return (
    <SliderPaper isShowDimmer={openEventLog}>
      <EventLogRoot variant="permanent" ownerState={{ openEventLog }} ref={ref}>
        {openEventLog && <EventLogHeader
          darkMode={darkMode}
          handleClickCloseButton={handleCloseEventLog}
        />}
        {openEventLog && <Divider />}
        {openEventLog && <MDBox pt={1} pb={2} px={2} overflow="auto">
          <UtilButtons
            startDateTime={formData.startDateTime}
            endDateTime={formData.endDateTime}
            csvData={csvData}
            csvRef={csvRef}
            page={formData.page}
            handleChangeDate={handleChangeDate}
            handleClickDownloadCSV={handleClickDownloadCSV}
          />
          <DataTable
            table={tableEventLogList}
            entriesPerPage={TABLE_ENTRIES}
            currentPage={formData.page}
            setPage={setPage}
            setCSVPageItems={createCsv}
            isSorted={true}
            excludeSortColumns={TABLE_EXCLUDE_SORT_LIST}
            isVirtual={false}
          />
        </MDBox>}
      </EventLogRoot>
    </SliderPaper>
  );
}

export default forwardRef(EventLog);
