import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTextField from "components/MDTextField";
import React, { ChangeEvent, MutableRefObject } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { TableRow } from "../../hooks/useEventLogTableData";

type Props = {
  startDateTime: string;
  endDateTime: string;
  csvData: TableRow[];
  csvRef: MutableRefObject<
    CSVLink &
      HTMLAnchorElement & {
        link: HTMLAnchorElement;
      }
  >;
  page: number;
  handleChangeDate: (e: ChangeEvent<HTMLInputElement>) => void;
  handleClickDownloadCSV: () => void;
};

function UtilButtons({
  startDateTime,
  endDateTime,
  csvData,
  csvRef,
  page,
  handleChangeDate,
  handleClickDownloadCSV,
}: Props) {
  const { t } = useTranslation("Translation");
  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={1}
    >
      <MDBox gap={2} mr={2} display="flex">
        <MDTextField
          id="datetime-local"
          type="datetime-local"
          name="startDateTime"
          label={t("Start date time")}
          value={startDateTime}
          onChange={handleChangeDate}
        />
        <MDTextField
          id="datetime-local"
          type="datetime-local"
          name="endDateTime"
          label={t("End date time")}
          value={endDateTime}
          onChange={handleChangeDate}
        />
      </MDBox>
      <MDButton
        color="info"
        onClick={handleClickDownloadCSV}
        disabled={!csvData.length}
      >
        {t("Extract CSV")}
        <CSVLink
          data={csvData}
          filename={`SEController ${startDateTime
            .split("T")
            .join(" ")} ${endDateTime.split("T").join(" ")} ${page + 1}page`}
          ref={csvRef}
        />
      </MDButton>
    </MDBox>
  );
}

export default UtilButtons;
