import React, {useState, useEffect, forwardRef, ForwardedRef, ReactElement} from "react";

// react-router components
import { useLocation } from "react-router-dom";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";

// Components
import Breadcrumbs from "layouts/components/breadcrumbs";
import CommonControls from "layouts/components/navbars/dashboardNavbar/commonControls";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "layouts/components/navbars/dashboardNavbar/styles";

import CurrentSessionTime from "layouts/components/time/session";
import { useRecoilState, useSetRecoilState } from "recoil";
import { uiConfigState } from "store/uiConfig";
import { systemConfigState } from "store/systemConfig";
import isEqual from "lodash.isequal";
import throttle from "lodash.throttle";

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar(
  { absolute = false, light = false, isMini = false }: Props,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const [navbarType, setNavbarType] = useState<
    "fixed" | "absolute" | "relative" | "static" | "sticky"
  >();
  const [uiConfig, setUIConfig] = useRecoilState(uiConfigState);
  const setSystemConfig = useSetRecoilState(systemConfigState);
  const { miniSidenav, transparentHeader, fixedNavbar, darkMode } = uiConfig;
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentHeader() {
      if ((fixedNavbar && window.scrollY === 0) || !fixedNavbar) {
        setUIConfig((prev) => {
          const nextValue = {
            ...prev,
            transparentHeader: true,
          };
          const isChanged = !isEqual(prev, nextValue);
          if (isChanged) return nextValue;
          return prev;
        });
        return;
      }

      setUIConfig((prev) => {
        const nextValue = {
          ...prev,
          transparentHeader: false,
        };
        const isChanged = !isEqual(prev, nextValue);
        if (isChanged) return nextValue;
        return prev;
      });
    }

    const throttledHandleTransparentHeader = throttle(
      handleTransparentHeader,
      150
    );
    window.addEventListener("scroll", throttledHandleTransparentHeader);
    handleTransparentHeader();

    return () =>
      window.removeEventListener("scroll", throttledHandleTransparentHeader);
  }, [fixedNavbar, setUIConfig]);

  const handleMiniSidenav = () =>
    setUIConfig((prev) => ({
      ...prev,
      miniSidenav: !prev.miniSidenav,
    }));
  const handleConfiguratorOpen = () => {
    setSystemConfig((prev) => ({
      ...prev,
      openConfigurator: !prev.openConfigurator,
    }));
  };

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentHeader && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentHeader, absolute, light, darkMode })
      }
    >
      <Toolbar sx={navbarContainer} >
        <MDBox
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <MDBox pr={1} pl={1}>
            <IconButton
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu" : "menu_open"}
              </Icon>
            </IconButton>
          </MDBox>
          <MDBox pr={1}>
            <Breadcrumbs
              icon="home"
              title={route[route.length - 1]}
              route={route}
              light={light}
            />
          </MDBox>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox>
              <CurrentSessionTime />
            </MDBox>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu" : "menu_open"}
                </Icon>
              </IconButton>

              <MDBox ref={ref}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={handleConfiguratorOpen}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <CommonControls />
    </AppBar>
  );
}

export default forwardRef(DashboardNavbar);
