import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DefaultCard from "layouts/components/cards/defaultCard";
import ControlButtons from "layouts/components/controlButtons";
import StatusButtons from "layouts/components/statusButtons";
import CardProgress from "layouts/components/cardProgress";
import CardChart from "layouts/components/cardChart";
import Pack from "./components/pack";
import useDashboard from "./hooks/useDashboard";
import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import DefaultLineChart from "components/MDChart/Line";
import FirmwareUpdate from "./components/firwareUpdate";

function SystemBMS(): ReactElement {
  const {
    buttons,
    kwChartData,
    progressList,
    chartList,
    systemBMS,
    isControlButtonsLoading,
    isFirmwareUpdateButtonsLoading,
    isUsePackBMS,
    ROLE,
    handleClickButton,
  } = useDashboard();
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} xl={8}>
          <DefaultCard
            icon={"show_chart"}
            title={t("System power")}
          >
            <DefaultLineChart
              title={`${
                kwChartData.data.length
                  ? kwChartData.data[kwChartData.data.length - 1]
                  : 0
              } kW`}
              chart={{
                labels: kwChartData.label,
                datasets: [
                  {
                    label: "",
                    color: "warning",
                    data: kwChartData.data,
                  },
                ],
              }}
              chartOptions={{
                yMax: 300,
                yMin: 0,
              }}
            />
          </DefaultCard>
        </Grid>
        <Grid item xs={12} xl={4}>
          {progressList.map(
            ({ title, percentValue, viewValue, color, unit }, progressIndex) => {
              return (
                  <MDBox key={progressIndex} pb={(progressIndex !== (progressList.length - 1)) ? 1 : 0}>
                    <CardProgress
                        title={`${title}(${unit})`}
                        value={percentValue}
                        label={`${viewValue}${unit}`}
                        color={color}
                    />
                  </MDBox>
              );
            }
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={1}>
        {chartList.map(
          ({ title, data, unit, description, yMin, yMax }, chartIndex) => {
            return (
              <CardChart
                key={chartIndex}
                xl={3}
                title={`${title}(${unit})`}
                chartDescription={description}
                chartData={data}
                yMin={yMin}
                yMax={yMax}
              />
            );
          }
        )}
      </Grid>
      <MDBox mb={1}>
        <DefaultCard icon="dns" title={t("Nameplate")}>
          <MDBox mx={2} mb={1}>
            <StatusButtons buttons={buttons.namePlate} />
          </MDBox>
        </DefaultCard>
      </MDBox>
      <MDBox mb={1}>
        <DefaultCard icon="build" title={t("Control")}>
          <MDBox mx={2} mb={1}>
            <ControlButtons
              buttons={buttons.control}
              isLoading={isControlButtonsLoading}
              onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "CONTROL") : null}
            />
          </MDBox>
        </DefaultCard>
      </MDBox>
      <MDBox mb={1}>
        <DefaultCard icon="memory" title={t("Status")}>
          <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
            <StatusButtons buttons={buttons.heartbeat} />
          </MDBox>
          <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
            <StatusButtons buttons={buttons.status} />
          </MDBox>
          <MDBox mb={1} mx={2}>
            <StatusButtons buttons={buttons.statusDetail} />
          </MDBox>
        </DefaultCard>
      </MDBox>
      <MDBox mb={1}>
      {isUsePackBMS && (systemBMS.status?.packBMSStatusList.length > 0) && (
        <DefaultCard icon="air" title={`${t("Pack BMS")} ${t("Status")}`}>
          <MDBox
            mx={2}
            my={1}
            display="block"
            flexWrap="wrap"
          >
            {systemBMS.status?.packBMSStatusList.map(
              (packBMSStatus, packBMSIndex) => {
                return (
                  <Pack
                    key={packBMSIndex}
                    packData={systemBMS.device?.packBMSDataList[packBMSIndex]}
                    packStatus={packBMSStatus}
                    moduleIsoSpiStatusList={
                      packBMSStatus.moduleIsoSpiStatusList
                    }
                    packIndex={packBMSIndex}
                    isStart={true}
                  />
                );
              }
            )}
          </MDBox>
        </DefaultCard>
      )}
      </MDBox>
      {ROLE.ADMIN && (
      <DefaultCard icon="browser_updated" title={`${t("Pack BMS")} ${t("Firmware update")}`}>
        <MDBox mx={2} mt={1} mb={1} display="flex" justifyContent="center" alignItems="center">
          <ControlButtons
            buttons={buttons.firmwareUpdate}
            isLoading={isFirmwareUpdateButtonsLoading}
            onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "FIRMWARE_UPDATE") : null}
          />
        </MDBox>
        <MDBox mx={2} mt={1} mb={1}>
          <FirmwareUpdate/>
        </MDBox>
      </DefaultCard>)}
    </MDBox>
  );
}

export default SystemBMS;
