import {ReactElement, useCallback, useEffect} from "react";
import authenticationApi from "apis/authentication/api";
import storage from "lib/utils/storage";
import {storageKey} from "lib/consts/storageKey";
import {useRecoilState} from "recoil";
import {userState} from "store/user";
import {routePathname} from "lib/consts/routePathname";
import {useNavigate} from "react-router-dom";

function SignOut(): ReactElement {
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();

  const signOut = useCallback(() => {
    if (user) {
      storage.remove(storageKey.USER);
      setUser(null);
      authenticationApi.signOut(user).then();
      navigate(routePathname.SIGN_IN, { replace: true });
    }
  }, [user, setUser, navigate]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return null;
}

export default SignOut;
