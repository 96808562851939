import { QCType } from "lib/types/qc";

const commonPathname = "/backend";

export const requestSocketPathname = {
  DATETIME: `${commonPathname}/dateTime`,
  RUNTIME: `${commonPathname}/commandStatus`,
  MODIFY_ACCOUNT: `${commonPathname}/modifyAccount`,
  SETTING: `${commonPathname}/setting`,
  EVENT_LOG_UPDATE: `${commonPathname}/eventLogUpdate`,
  ACCOUNTS: `${commonPathname}/accounts`,
  SYSTEM_BMS_DATA: `${commonPathname}/data`,
  SYSTEM_BMS_STATUS: `${commonPathname}/status`,
  SYSTEM_BMS_TARGET_PACK_DATA: (index: number) =>
    `${commonPathname}/packBMS${index}/data`,
  SYSTEM_BMS_TARGET_PACK_STATUS: (index: number) =>
    `${commonPathname}/packBMS${index}/status`,
  LOG: `${commonPathname}/log`,
  QC_DATA: (qcType: QCType) => `${commonPathname}/qc/${qcType.toLocaleLowerCase()}`,
  QC_FILE_DATA: (qcType: QCType) => `${commonPathname}/qc/${qcType.toLocaleLowerCase()}/files`
} as const;
