import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  chargeCapacityValue: number;
  energyCapacityValue: number;
  maxChargeRateValue: number;
  maxDischargeRateValue: number;
  maxSoCValue: number;
  minSoCValue: number;
  chargeCapacityOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  energyCapacityOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxChargeRateOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxDischargeRateOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxSoCOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  minSoCOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function NamePlateCard({
  title,
  chargeCapacityValue,
  energyCapacityValue,
  maxChargeRateValue,
  maxDischargeRateValue,
  maxSoCValue,
  minSoCValue,
  chargeCapacityOnChange,
  energyCapacityOnChange,
  maxChargeRateOnChange,
  maxDischargeRateOnChange,
  maxSoCOnChange,
  minSoCOnChange,
}: Props) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="bolt" title={title}>
      <MDBox ml={10} mb={1}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Charge capacity")} (Ah)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Charge capacity")}
              name="chargeCapacity"
              value={chargeCapacityValue}
              onChange={chargeCapacityOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Max charge rate")} (W)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Max charge rate")}
              name="maxChargeRate"
              value={maxChargeRateValue}
              onChange={maxChargeRateOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Max SoC")} (%)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Max SoC")}
              name="maxSoC"
              value={maxSoCValue}
              onChange={maxSoCOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} />
        </Grid>
      </MDBox>
      <MDBox mb={1} />
      <MDBox ml={10} mb={1}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Energy capacity")} (Wh)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Energy capacity")}
              name="energyCapacity"
              value={energyCapacityValue}
              onChange={energyCapacityOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Max discharge rate")} (W)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Max discharge rate")}
              name="maxDischargeRate"
              value={maxDischargeRateValue}
              onChange={maxDischargeRateOnChange}
              fullWidth
            />
          </Grid>
          <Grid item sm={1} />
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Min SoC")} (%)
            </MDTypography>
          </Grid>
          <Grid item sm={2}>
            <MDInput
              type="number"
              label={t("Min SoC")}
              name="minSoC"
              value={minSoCValue}
              onChange={minSoCOnChange}
              fullWidth
            />
            <Grid item sm={1} />
          </Grid>
        </Grid>
      </MDBox>
    </DefaultCard>
  );
}

export default NamePlateCard;
