import pxToRem from "assets/theme/functions/pxToRem";
import MDSelect from "components/MDSelect";
import React from "react";
import { BalancingSettingRange } from "apis/devices/packBMS/overview/api";
import useBalancingSelect from "../../hooks/useBalancingSelect";
import { Grid, SelectChangeEvent } from "@mui/material";
import packBMS from "lib/consts/pack";

type Props = {
  formValue: BalancingSettingRange;
  onChange: ({ target: { name, value } }: SelectChangeEvent<number>) => void;
  isLoading?: boolean;
};

const SelectSX = { width: pxToRem(342) };

function ControlSelects({ formValue, onChange, isLoading = false }: Props) {
  const { balancingSelectItems } = useBalancingSelect();
  const formValueList = Object.values(formValue);

  return (
    <Grid container gap={1}>
      {balancingSelectItems.map(
        ({ title, name, options, defaultOptions }, selectIndex) => {
          const isInitialStatus = (formValueList[selectIndex] === -1 || formValueList[selectIndex] === packBMS.balancingCriteria.NONE);
          return (
            <MDSelect
              key={selectIndex}
              label={title}
              name={name}
              value={formValueList[selectIndex]}
              disabled={isInitialStatus || isLoading}
              onChange={onChange}
              options={isInitialStatus ? defaultOptions : options}
              sx={SelectSX}
            />
          );
        }
      )}
    </Grid>
  );
}

export default ControlSelects;
