import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "App";
import "./lib/languages/index";
import "./lib/styles/digital.css";

import { registerables, Chart as ChartJS } from "chart.js";
import { RecoilRoot } from "recoil";

ChartJS.register(...registerables);

ReactDOM.render(
  <RecoilRoot>
    <HashRouter>
      <App />
    </HashRouter>
  </RecoilRoot>,
  document.getElementById("root")
);
