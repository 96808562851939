import { recoilKey } from "lib/consts/recoilKey";
import { storageKey } from "lib/consts/storageKey";
import storage from "lib/utils/storage";
import { atom, AtomEffect } from "recoil";
import {Role} from "lib/types/account";

export type StorageAccount = {
  id: number;
  username: string;
  roles: Role[];
};

const storageUserEffect =
  (key: string): AtomEffect<StorageAccount> =>
  ({ setSelf, onSet }) => {
    const savedStorageValue = storage.get<StorageAccount | null>(key, null);

    if (savedStorageValue) {
      setSelf(savedStorageValue);
    }

    onSet((newValue, oldValue, isReset) => {
      isReset
        ? storage.remove(key)
        : storage.set(key, newValue);
    });
  };


export const userState = atom<StorageAccount | null>({
  key: recoilKey.USER,
  default: null,
  effects: [storageUserEffect(storageKey.USER)]
});
