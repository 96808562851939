import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import useApiRequest from "lib/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import { ApiSuccessErrorMessage } from "lib/types/language";
import {useRecoilState} from "recoil";
import { userState } from "store/user";
import accountApi from "apis/configuration/account/api";
import {Account, Role} from "lib/types/account";

type CurrentAccount = Omit<Account, "roles"> & { roles: Role[] };

const INIT_CHANGE_PASSWORD_VALUE = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

function useChangePassword() {
  const [user] = useRecoilState(userState);
  const [currentAccount, setCurrentAccount] = useState<CurrentAccount>(null);
  const [changePasswordValue, setChangePasswordValue] = useState(INIT_CHANGE_PASSWORD_VALUE);
  const [currentPasswordCheck, setCurrentPasswordCheck] = useState<boolean>(false);
  const [newPasswordCheck, setNewPasswordCheck] = useState<boolean>(false);
  const [confirmNewPasswordCheck, setConfirmNewPasswordCheck] = useState<boolean>(false);
  const { request, isLoading } = useApiRequest();
  const { openModal } = useContext(ModalContext);
  const disabledSaveButton = useMemo(
    () => Boolean(!currentAccount?.username
      || !changePasswordValue.currentPassword
      || !changePasswordValue.newPassword
      || !changePasswordValue.confirmNewPassword
      || !currentPasswordCheck
      || !confirmNewPasswordCheck),
    [currentAccount?.username,
      changePasswordValue.currentPassword,
      changePasswordValue.newPassword,
      changePasswordValue.confirmNewPassword,
      currentPasswordCheck,
      confirmNewPasswordCheck]
  );
  const { t } = useTranslation("Translation");

  const getAccount = useCallback(async () => {
    const { id, username, password, roles } = await accountApi.get(user.id);
    setCurrentAccount({
      id,
      username,
      password,
      roles: roles.map((role) => role.name),
    });
  }, [user.id]);

  const checkCurrentPassword = useCallback(async (password: string) => {
    const passwordCheck = await accountApi.checkPassword(
      currentAccount?.id,
      currentAccount?.username,
      password,
      currentAccount?.roles
    );
    setCurrentPasswordCheck(passwordCheck);
  }, [
    currentAccount?.id,
    currentAccount?.username,
    currentAccount?.roles
  ]);

  useEffect(() => {
    if (user) {
      getAccount().then();
    }
  }, [user, getAccount]);

  const handleClickSaveButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      request({
        targetApi: () =>
          accountApi.modify(
            currentAccount.id,
            currentAccount.username,
            changePasswordValue.newPassword,
            currentAccount.roles
          ),
        onSuccess: (res) => {
          const response = res as { message: string };
          openModal(
            <DefaultModal
              status="success"
              title={t(response.message as keyof ApiSuccessErrorMessage)}
            />
          );
        },
        onError: (err) => {
          openModal(
            <DefaultModal
              status="error"
              title={t(
                err?.response.data.message as keyof ApiSuccessErrorMessage
              )}
            />
          );
        },
      });
    }
  }, [currentAccount?.id,
    currentAccount?.username,
    changePasswordValue.newPassword,
    currentAccount?.roles,
    openModal,
    request,
    t
  ]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === "currentPassword") {
      checkCurrentPassword(value).then();
    }
    if (name === "newPassword") {
      if (value !== "") {
        setNewPasswordCheck(true);
      } else {
        setNewPasswordCheck(false);
      }
    }
    if (name === "confirmNewPassword") {
      if (value === changePasswordValue.newPassword) {
        setConfirmNewPasswordCheck(true);
      } else {
        setConfirmNewPasswordCheck(false);
      }
    }

    setChangePasswordValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return {
    currentAccount,
    changePasswordValue,
    isLoading,
    disabledSaveButton,
    handleClickSaveButton,
    handleChangeInput,
    currentPasswordCheck,
    newPasswordCheck,
    confirmNewPasswordCheck,
  };
}

export default useChangePassword;
