import React, { PropsWithChildren } from 'react'
import Dimmer from '../dimmer'

type Props = {
  isShowDimmer: boolean;
}

function SliderPaper({ isShowDimmer, children }: PropsWithChildren<Props>) {
  return (
    <>
       <Dimmer isShow={isShowDimmer} />
       {children}
    </>
  )
}

export default SliderPaper