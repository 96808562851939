import logApi from "apis/etc/log/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import { requestSocketPathname } from "lib/consts/socket";
import useApiRequest from "lib/hooks/useApiRequest";
import useWebSocket from "lib/hooks/useWebSocket";
import { LogFileList } from "lib/types/log";
import { getDate, getDateTime } from "lib/utils/common";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useLogTableData from "./useLogTableData";

function useLog() {
  const { subscribe, isConnected } = useWebSocket();
  const { logTableData } = useLogTableData();
  const { t } = useTranslation("Translation");
  const [page, setPage] = useState(0);
  const [tableLogFileList, setTableLogLogList] = useState(logTableData);
  const [formData, setFormData] = useState({
    startDateTime: getDate(-1, { hour: 0, minute: 0 }),
    endDateTime: getDate(0, { hour: getDateTime().getHours(), minute: 0 }),
  });
  const [logFileList, setLogFileList] = useState<LogFileList>();
  const { request: logFileListRequest } = useApiRequest<LogFileList>();
  const { request: createLogFileRequest, isLoading: isCreateLoading } =
    useApiRequest();
  const { openModal } = useContext(ModalContext);
  const isLogFileList = logFileList?.logFileList.length > 0;

  const logFileListRequestOnSuccess = useCallback((response: LogFileList) => {
    setLogFileList(response);
  }, []);

  const initialApiRequest = useCallback(() => {
    logFileListRequest({
      targetApi: () => logApi.getLog(),
      onSuccess: (response) =>
        logFileListRequestOnSuccess(response as LogFileList),
    });
  }, [logFileListRequest, logFileListRequestOnSuccess]);

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;
    subscribe(requestSocketPathname.LOG, (body: LogFileList) => {
      setLogFileList(body);
    });
  }, [isConnected, subscribe]);

  const handleChangeDateTime = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const dateTime = `${value.split("T").join(" ").split(':')[0]}:00`;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: dateTime,
      };
    });
  };

  const handleClickCreateLog = () => {
    createLogFileRequest({
      targetApi: () => logApi.createLog({
        startDateTime: `${formData.startDateTime.split("T").join(" ")}:00.000`,
        endDateTime: `${formData.endDateTime.split("T").join(" ")}:00.000`,
      }),
    });
  };

  const handleClickDelete = useCallback(
    (fileFullPath: string) => logApi.deleteLog(fileFullPath),
    []
  );

  const handleClickDownload = useCallback(
    async (fileFullPath: string, fileName: string) => {
      const response = await logApi.downloadLog(fileFullPath);
      const href = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();

      // clean
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    []
  );

  useEffect(() => {
    if (logFileList) {
      const logRows = logFileList.logFileList.map((logFile, logFileIndex) => {
        return {
          no: { fileNo: logFileIndex + 1 },
          fileName: { fileName: logFile.fileName },
          status: { status: logFile.status },
          delete: {
            isProcessing: logFile.status === "PROCESSING",
            onClick: () =>
              openModal(
                <DefaultModal
                  status="warning"
                  type="confirm"
                  maxWidth={420}
                  title={`"${logFile.fileName}" ${t("Do you want to delete?")}`}
                  onSuccess={() => handleClickDelete(logFile.fileFullPath)}
                />
              ),
          },
          download: {
            isProcessing: logFile.status === "PROCESSING",
            onClick: () =>
              handleClickDownload(logFile.fileFullPath, logFile.fileName),
          },
        };
      });

      setTableLogLogList((prev) => {
        return { ...prev, rows: logRows };
      });
    }
  }, [logFileList, openModal, handleClickDelete, handleClickDownload, t]);

  return {
    tableLogFileList,
    page,
    formData,
    isCreateLoading,
    isLogFileList,
    setPage,
    handleChangeDateTime,
    handleClickCreateLog,
  };
}

export default useLog;
