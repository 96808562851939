import {ReactElement, ReactNode} from "react";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import {
  item,
  itemContent,
  itemArrow,
  itemIcon,
  itemText,
} from "layouts/components/sidenav/styles/sidenavItem";

import { ListItemIcon } from "@mui/material";
import { useRecoilValue } from "recoil";
import { uiConfigState } from "store/uiConfig";
import { Colors } from "lib/types/material";

// Declaring props types for SidenavCollapse
interface Props {
  color?: Colors;
  name: string;
  active?: boolean | string;
  nested?: boolean;
  children?: ReactNode;
  open?: boolean;
  [key: string]: any;
}

function SidenavItem({
  color,
  name,
  active,
  nested,
  children,
  open,
  icon,
  ...rest
}: Props): ReactElement {
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    useRecoilValue(uiConfigState);

  return (
    <>
      <ListItem
        {...rest}
        component="li"
        sx={(theme) =>
          item(theme, {
            active,
            color,
            transparentSidenav,
            whiteSidenav,
            darkMode,
            miniSidenav,
          })
        }
      >
        <MDBox
          sx={(theme: Theme): any =>
            itemContent(theme, {
              active,
              color,
              miniSidenav,
              name,
              open,
              nested,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          <ListItemIcon
            sx={(theme) =>
              itemIcon(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
                active,
                color,
                children,
              })
            }
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={name}
            sx={(theme) => itemText(theme, { miniSidenav })}
          />
          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, {
                  open,
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                })
              }
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Declaring default props for SidenavItem
SidenavItem.defaultProps = {
  color: "info",
  active: false,
  nested: false,
  children: false,
  open: false,
};

export default SidenavItem;
