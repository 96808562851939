import DefaultButtonCell from "layouts/components/dataTable/defaultButtonCell";
import DefaultInputCell from "layouts/components/dataTable/defaultInputCell";
import { TableData } from "layouts/pages/configuration/setting/hooks/usePackBMSTableData";
import React from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";

type ButtonCell = {
  disabled: boolean;
  onClick: (fileFullPath: string, fileName?: string) => void;
};

function useQCLogTableData() {
  const { t } = useTranslation("Translation");

  const qcLogTableData: TableData = {
    columns: [
      {
        Header: t("No."),
        accessor: "no",
        width: "10%",
        align: "center",
        Cell: ({ value }: CellProps<{}, string>) => {
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={String(value)}
            />
          );
        },
      },
      {
        Header: `${t("Pack BMS")} ${t("No.")}`,
        accessor: "packBMSNo",
        width: "10%",
        align: "center",
        Cell: ({ value }: CellProps<{}, string>) => {
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={value}
            />
          );
        },
      },
      {
        Header: `${t("Module")} ${t("No.")}`,
        accessor: "moduleNo",
        width: "10%",
        align: "center",
        Cell: ({ value }: CellProps<{}, string>) => {
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={value}
            />
          );
        },
      },
      {
        Header: t("Start date time"),
        accessor: "startDateTime",
        width: "20%",
        align: "center",
        Cell: ({ value }: CellProps<{}, string>) => {
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={value?.split("T").join(" ") || "-"}
            />
          );
        },
      },
      {
        Header: t("End date time"),
        accessor: "endDateTime",
        width: "20%",
        align: "center",
        Cell: ({ value }: CellProps<{}, string>) => {
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={value?.split("T").join(" ") || "-"}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "delete",
        width: "10%",
        align: "center",
        Cell: ({ value }: CellProps<{}, ButtonCell>) => {
          const { disabled, onClick } = value;
          return (
            <DefaultButtonCell
              use={true}
              loading={disabled}
              color="primary"
              name="Delete"
              title={t("Delete")}
              onClickHandler={onClick}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "download",
        width: "10%",
        align: "center",
        Cell: ({ value }: CellProps<{}, ButtonCell>) => {
          const { disabled, onClick } = value;
          return (
            <DefaultButtonCell
              use={true}
              loading={disabled}
              color="info"
              name="Download"
              title={t("Download")}
              onClickHandler={onClick}
            />
          );
        },
      },
    ],
    rows: [],
  };

  return {
    qcLogTableData,
  };
}

export default useQCLogTableData;
