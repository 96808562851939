import {createContext, ReactElement} from "react";

export type ModalState = {
  isModalOpen: boolean;
  openModal: (children: ReactElement) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalState>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
});