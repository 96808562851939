import { TableRow } from "@mui/material";
import React, { MutableRefObject } from "react";
import { Row } from "react-table";
import DataTableBodyCell from "../dataTableBodyCell";
import { useVirtualizer } from "@tanstack/react-virtual";

type Props = {
  virtualContainerRef: MutableRefObject<HTMLDivElement>;
  noEndBorder: boolean;
  noPagination: boolean;
  page: Row<object>[];
  isVirtual: boolean;
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
};

function TableRows({
  virtualContainerRef,
  noEndBorder,
  page,
  rows,
  isVirtual,
  noPagination,
  prepareRow,
}: Props) {
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => virtualContainerRef.current,
    count: page.length,
    estimateSize: () => 46.47,
  });
  const { getVirtualItems, getTotalSize } = rowVirtualizer;
  const virtualPaddingTop =
    getVirtualItems().length > 0 ? getVirtualItems()?.[0]?.start || 0 : 0;
  const virtualPaddingBottom =
    getVirtualItems().length > 0
      ? getTotalSize() -
        (getVirtualItems()?.[getVirtualItems().length - 1]?.end || 0)
      : 0;

  const renderRowItem = (row: Row<object>, key: number) => (
    <TableRow key={key} {...row.getRowProps()}>
      {row?.cells.map((cell, key) => (
        <DataTableBodyCell
          key={key}
          noBorder={noEndBorder && rows.length - 1 === key}
          align="center"
          {...cell.getCellProps()}
        >
          {cell.render("Cell")}
        </DataTableBodyCell>
      ))}
    </TableRow>
  );

  return (
    <>
      {isVirtual ? (
        <>
          {virtualPaddingTop > 0 && (
            <TableRow sx={{ height: `${virtualPaddingTop}px` }} />
          )}
          {getVirtualItems().map((virtualRow, key) => {
            const row = page[virtualRow.index];
            prepareRow(row);
            return renderRowItem(row, key);
          })}
          {virtualPaddingBottom > 0 && (
            <TableRow sx={{ height: `${virtualPaddingBottom}px` }} />
          )}
        </>
      ) : noPagination ? (
        rows.map((row, key) => {
          prepareRow(row);
          return renderRowItem(row, key);
        })
      ) : (
        page.map((row, key) => {
          prepareRow(row);
          return renderRowItem(row, key);
        })
      )}
    </>
  );
}

export default TableRows;
