import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { ModalContext } from "context/modal/modalContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import MemoButton from "../../memoButton";
import pxToRem from "assets/theme/functions/pxToRem";
import { Se805RepeatBlock } from "lib/types/pack";

type Props = {
  title: string;
  color:
    | "info"
    | "dark"
    | "white"
    | "black"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "error"
    | "light";
  onSuccess?: (setBalSt: number) => void;
  se805RepeatBlockList: Se805RepeatBlock[];
  selectedCellsOfModule: number;
};

function BalancingCellsModal({
  title = "",
  color,
  onSuccess = (setBalSt) => {},
  se805RepeatBlockList,
  selectedCellsOfModule,
}: Props) {
  const { t } = useTranslation("Translation");
  const { closeModal } = useContext(ModalContext);
  const [setBalSt, setSetBalSt] = useState(selectedCellsOfModule);

  const handleConfirm = () => {
    onSuccess(setBalSt);
    closeModal();
  };
  console.log(setBalSt);
  const handleSelectedBalancingCellChange = (index: number, value: boolean) => {
    if (value) {
      setSetBalSt((prev) => prev | (1 << index));
    } else {
      setSetBalSt((prev) => prev & ~(1 << index));
    }
  };

  return (
    <MDBox>
      <MDTypography
        sx={{ display: "flex", alignItems: "center" }}
        color={color}
      >
        {<Icon sx={{ marginRight: 1 }}>{"settings_input_component"}</Icon>}
        {title}
      </MDTypography>
      <Grid container gap={1} mt={1}>
        {se805RepeatBlockList.map((cell, cellIndex) => {
          return (
            <MemoButton
              key={cellIndex}
              title={`${t("Cell")} #${String(cellIndex + 1).padStart(2, "0")}`}
              color={
                color !== "light" && color !== "error"
                  ? (setBalSt & (1 << cellIndex)) !== 0
                    ? "warning"
                    : color
                  : color
              }
              onClick={() =>
                handleSelectedBalancingCellChange(
                  cellIndex,
                  (setBalSt & (1 << cellIndex)) !== 0 ? false : true
                )
              }
              sx={{ width: pxToRem(120) }}
            />
          );
        })}
      </Grid>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} xl={6}>
          <MDButton fullWidth color="error" size="large" onClick={closeModal}>
            {t("Cancel")}
          </MDButton>
        </Grid>
        <Grid item xs={12} xl={6}>
          <MDButton
            autoFocus
            fullWidth
            color="info"
            size="large"
            onClick={handleConfirm}
          >
            {t("Control")}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default BalancingCellsModal;
