import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { ControlType } from "lib/types/api";

const getData = async (packBMSNo: number) => {
  const response = await api.get(
    requestPathname.SYSTEM_BMS_PACK_DATA(packBMSNo)
  );
  return response.data;
};

const getStatus = async (packBMSNo: number) => {
  const response = await api.get(
    requestPathname.SYSTEM_BMS_PACK_STATUS(packBMSNo)
  );
  return response.data;
};

const sensorControl = async (
  packBMSNo: number,
  data: {
    type: ControlType;
    value: number;
  }
) => {
  const response = await api.post(
    requestPathname.SYSTEM_BMS_SENSOR_CONTROL(packBMSNo),
    data
  );
  return response.data;
};

const fanControl = async (
  packBMSNo: number,
  fanNo: number,
  data: {
    type: ControlType;
    value: number;
  }
) => {
  const response = await api.post(
    requestPathname.SYSTEM_BMS_FAN_CONTROL(packBMSNo, fanNo),
    data
  );
  return response.data;
};

const environmentApi = {
  getStatus,
  getData,
  sensorControl,
  fanControl,
};

export default environmentApi;
