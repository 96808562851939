import {useState, useEffect, ForwardedRef, forwardRef, ReactElement} from "react";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { Theme } from "@mui/material/styles";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "layouts/components/configurator/configuratorRoot";

import pxToRem from "assets/theme/functions/pxToRem";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { uiConfigState } from "store/uiConfig";
import { systemConfigState } from "store/systemConfig";
import throttle from "lodash.throttle";
import { Colors } from "lib/types/material";
import SliderPaper from "../sliderPaper";
import { INNER_WIDTH_LIMIT_BOUNDARY } from "lib/consts/common";

type Props = {};

function Configurator(
  _: Props,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const { t } = useTranslation("Translation");
  const [uiConfig, setUIConfig] = useRecoilState(uiConfigState);
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const {
    miniSidenav,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = uiConfig;
  const { openConfigurator } = systemConfig;

  const [disabled, setDisabled] = useState<boolean>(false);
  const sidenavColors: Colors[] = [
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > INNER_WIDTH_LIMIT_BOUNDARY
        ? setDisabled(false)
        : setDisabled(true);
    }

    const throttledHandleDisabled = throttle(handleDisabled, 300);
    window.addEventListener("resize", throttledHandleDisabled);
    handleDisabled();
    return () => {
      window.removeEventListener("resize", throttledHandleDisabled);
    };
  }, []);

  const handleCloseConfigurator = () =>
    setSystemConfig((prev) => ({
      ...prev,
      openConfigurator: false,
    }));
  const handleSidenavColor = (color: Colors) => {
    setUIConfig((prev) => {
      return {
        ...prev,
        sidenavColor: color,
      };
    });
  };
  const handleTransparentSidenav = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        transparentSidenav: true,
        whiteSidenav: false,
      };
    });
  };
  const handleWhiteSidenav = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        transparentSidenav: false,
        whiteSidenav: true,
      };
    });
  };
  const handleDarkSidenav = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        transparentSidenav: false,
        whiteSidenav: false,
      };
    });
  };
  const handleMiniSidenav = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        miniSidenav: !prev.miniSidenav,
      };
    });
  };
  const handleFixedNavbar = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        fixedNavbar: !prev.fixedNavbar,
      };
    });
  };
  const handleDarkMode = () => {
    setUIConfig((prev) => {
      return {
        ...prev,
        darkMode: !prev.darkMode,
      };
    });
  };

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }: Theme | any) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }: Theme | any) => ({
    height: pxToRem(39),
    background: darkMode
      ? white.main
      : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode
        ? white.main
        : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  return (
    <SliderPaper isShowDimmer={openConfigurator}>
      <ConfiguratorRoot
        variant="permanent"
        ownerState={{ openConfigurator }}
        ref={ref}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={2}
        >
          <MDBox>
            <MDTypography variant="h5">{t("UI configurator")}</MDTypography>
            <MDTypography variant="body2" color="text">
              {t("See our UI configuration options.")}
            </MDTypography>
          </MDBox>

          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: darkMode ? white.main : dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={handleCloseConfigurator}
          >
            close
          </Icon>
        </MDBox>

        <Divider />

        <MDBox pt={0.5} pb={2} px={2}>
          <MDBox>
            <MDTypography variant="h6">{t("Sidenav colors")}</MDTypography>
            <MDBox mb={0.5}>
              {sidenavColors.map((color) => (
                <IconButton
                  key={color}
                  sx={({
                    borders: { borderWidth },
                    palette: { white, dark, background },
                    transitions,
                  }: Theme | any) => ({
                    width: pxToRem(28),
                    height: pxToRem(28),
                    padding: 0,
                    border: `${borderWidth[1]} solid ${
                      darkMode ? background.sidenav : white.main
                    }`,
                    borderColor: () => {
                      let borderColorValue =
                        sidenavColor === color && dark.main;

                      if (darkMode && sidenavColor === color) {
                        borderColorValue = white.main;
                      }

                      return borderColorValue;
                    },
                    transition: transitions.create("border-color", {
                      easing: transitions.easing.sharp,
                      duration: transitions.duration.shorter,
                    }),
                    backgroundImage: ({
                      functions: { linearGradient },
                      palette: { gradients },
                    }) =>
                      linearGradient(
                        gradients[color].main,
                        gradients[color].state
                      ),

                    "&:not(:last-child)": {
                      mr: 1,
                    },

                    "&:hover, &:focus, &:active": {
                      borderColor: darkMode ? white.main : dark.main,
                    },
                  })}
                  onClick={() => handleSidenavColor(color)}
                />
              ))}
            </MDBox>
          </MDBox>

          <MDBox mt={2} lineHeight={1}>
            <MDTypography variant="h6">
              {t("Sidenav type")}
            </MDTypography>
            <MDTypography variant="button" color="text">
              {t("Choose between different sidenav types.")}
            </MDTypography>

            <MDBox
              sx={{
                display: "flex",
                mt: 2,
                mr: 1,
              }}
            >
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleDarkSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  !transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                {t("Dark")}
              </MDButton>
              <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
                <MDButton
                  color="dark"
                  variant="gradient"
                  onClick={handleTransparentSidenav}
                  disabled={disabled}
                  fullWidth
                  sx={
                    transparentSidenav && !whiteSidenav
                      ? sidenavTypeActiveButtonStyles
                      : sidenavTypeButtonsStyles
                  }
                >
                  {t("Transparent")}
                </MDButton>
              </MDBox>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleWhiteSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  whiteSidenav && !transparentSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                {t("White")}
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            lineHeight={1}
          >
            <MDTypography variant="h6">{t("Header fixed")}</MDTypography>

            <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
          </MDBox>
          <Divider />
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <MDTypography variant="h6">{t("Sidenav mini")}</MDTypography>

            <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
          </MDBox>
          <Divider />
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            lineHeight={1}
          >
            <MDTypography variant="h6">{t("Light / Dark")}</MDTypography>

            <Switch checked={darkMode} onChange={handleDarkMode} />
          </MDBox>
          <Divider />
        </MDBox>
      </ConfiguratorRoot>
    </SliderPaper>
  );
}

export default forwardRef(Configurator);
