import React from "react";
import { useTranslation } from "react-i18next";

import DefaultCard from "layouts/components/cards/defaultCard";
import DataTable from "layouts/components/dataTable";
import MDBox from "components/MDBox";

import ControlButtons from "../module/components/controlButton";

import useQC from "../module/hooks/useQC";

function QCCell() {
  const { t } = useTranslation("Translation");
  const {
    isUsePackBMS,
    isActiveSystemBMSButtons,
    isQCControlLoading,
    isQCFileLoading,
    qcData,
    statusData,
    tableLogData,
    page,
    setPage,
    handleClickButton,
  } = useQC({ qcType: "CELL" });

  return (
    <MDBox display="flex" flexDirection="column" gap={1}>
      <DefaultCard title={t("Cell")} icon="air">
        {isUsePackBMS && (
          <ControlButtons
            isActive={isActiveSystemBMSButtons}
            isCell={true}
            packBMSStatus={statusData?.packBMSStatusList}
            packBMSList={qcData?.qcPackBMSList}
            onClick={isActiveSystemBMSButtons ? handleClickButton : () => {}}
            isLoading={isQCControlLoading}
          />
        )}
      </DefaultCard>
      <DefaultCard title={t("Cell log")} icon="create">
        {!isQCFileLoading && (
          <DataTable
            table={tableLogData}
            entriesPerPage={false}
            showTotalEntries={false}
            isSorted={true}
            currentPage={page}
            setPage={setPage}
          />
        )}
      </DefaultCard>
    </MDBox>
  );
}

export default QCCell;
