export const VOLTAGE_SALT = 1.6;
export const AMP_SALT = 300;
export const KW_SALT = 0.001;

export const CELL_VOLTAGE_MIN = 0;
export const CELL_VOLTAGE_MAX = 1.6;
export const CELL_TEMPERATURE_MIN = -40;
export const CELL_TEMPERATURE_MAX = 125;

// setting
export const PACK_BMS_COUNT_MAX = 18;

//Balancing Reservation
export const BALANCING_RESERVATION_COUNT_MAX = 10;

// interval time
export const INTERVAL_TIME = 1000;

// style
export const INNER_WIDTH_LIMIT_BOUNDARY = 1200;
