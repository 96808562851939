import React, {useCallback, useEffect, useState} from "react";

// react-router components
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// themes
import theme from "assets/theme";

// Dark Mode themes
import themeDark from "assets/theme-dark";

// routes
import routes, {CustomRoute} from "routes";

import settingApi from "apis/configuration/setting/api";
import accountApi from "apis/configuration/account/api";

import CommonLayout from "layouts/components/layoutContainers/commonLayout";
import ModalProvider from "context/modal/ModalProvider";
import PopoverProvider from "context/popover/PopoverProvider";
import {useTranslation} from "react-i18next";
import isEqual from "lodash.isequal";
import {routePathname} from "lib/consts/routePathname";
import useWebSocket from "lib/hooks/useWebSocket";
import {requestSocketPathname} from "lib/consts/socket";
import {Setting} from "lib/types/setting";
import {useRecoilState} from "recoil";
import {uiConfigState} from "store/uiConfig";
import {systemConfigState} from "store/systemConfig";
import {userState} from "store/user";
import withInterceptor from "lib/hoc/interceptor";
import useInterval from "./lib/hooks/useInterval";
import {INTERVAL_TIME} from "./lib/consts/common";
import {getCurrentUIConfig} from "./lib/types/storage";
import authenticationApi from "./apis/authentication/api";
import {setDateTime} from "./lib/utils/common";

function App() {
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const [user, setUser] = useRecoilState(userState);
  const [uiConfig, setUIConfig] = useRecoilState(uiConfigState);
  const { darkMode } = uiConfig;
  const { setting } = systemConfig;
  const { subscribe, isConnected } = useWebSocket();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("Translation");
  const [isInitialized, setIsInitialized] = useState(false);

  const getSystemConfig = useCallback(async () => {
    if (!setting) {
      const setting = await settingApi.get();
      setSystemConfig((prev) => ({
        ...prev,
        setting,
      }));
    }
  }, [setting, setSystemConfig]);

  const getAlreadySignIn = useCallback(async () => {
    if (!user) {
      const signInAccount = await authenticationApi.getAlreadySignIn();
      if (signInAccount) {
        setUser({
          id: signInAccount.id,
          username: signInAccount.username,
          roles: signInAccount.roles,
        });
      }
    }
    setIsInitialized(true);
  }, [user, setUser]);

  useEffect(() => {
    if (!isInitialized) {
      getSystemConfig().then();
      getAlreadySignIn().then();
    }
  }, [user, setting, isInitialized, getSystemConfig, getAlreadySignIn]);

  useEffect(() => {
    if (!isConnected) return;
    subscribe(requestSocketPathname.DATETIME, (body: Date) => {
      setDateTime(body);
    });
    subscribe(requestSocketPathname.MODIFY_ACCOUNT, async (body: number) => {
      if (!user || body !== user.id) return;
      const { id, username, roles } = await accountApi.get(body);
      const userRoles = [...user.roles];
      if (isEqual(roles.map((role) => role.name).sort(), userRoles.sort()))
        return;
      setUser({
        id,
        username,
        roles: roles.map((role) => role.name),
      });
    });
    subscribe(requestSocketPathname.SETTING, (body: Setting) => {
      setSystemConfig((prev) => ({
        ...prev,
        setting: body,
      }));
    });
  }, [subscribe, isConnected, user, setting, setSystemConfig, setUser]);

  const getRouteUrlList = useCallback((allRoutes: CustomRoute[]): any => {
    return allRoutes
    .reduce((prev: any, route: CustomRoute) => {
      if (route.collapse) return [...prev, getRouteUrlList(route.collapse)];
      if (route.route) return [...prev, route.route];
      return prev;
    }, [])
    .flat(Infinity);
  }, []);

  const getRoutes = useCallback((allRoutes: CustomRoute[]): any => {
    return allRoutes.reduce((prev: any, route: CustomRoute) => {
      if (route.collapse) return [...prev, getRoutes(route.collapse)];
      if (route.route) {
        return [
          ...prev,
          <Route
            path={route.route}
            element={route.component}
            key={route.key}
          />,
        ];
      }
      return prev;
    }, []);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (isInitialized) {
      if (!user) {
        navigate(routePathname.SIGN_IN, { replace: true });
      } else {
        if (setting && !getRouteUrlList(routes(user, setting, t)).includes(pathname)) {
          navigate(routePathname.DASHBOARD, { replace: true });
        }
      }
    }
  }, [isInitialized, pathname, navigate, setting, user, t, getRouteUrlList]);

  useInterval(() => {
      if (getCurrentUIConfig() === undefined) {
        if (uiConfig !== null) {
          setUIConfig(uiConfig);
        }
      } else {
        if (getCurrentUIConfig() !== uiConfig) {
          setUIConfig(getCurrentUIConfig());
        }
      }
    },
    INTERVAL_TIME,
    true
  );

  return isInitialized && getRouteUrlList(routes(user, setting, t)).includes(pathname) && (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <ModalProvider>
          <PopoverProvider>
            <Routes>
              <Route
                  element={
                    <CommonLayout isSignInPage={pathname === routePathname.SIGN_IN} />
                  }
              >
                {getRoutes(routes(user, setting, t))}
              </Route>
            </Routes>
          </PopoverProvider>
        </ModalProvider>
      </ThemeProvider>
  );
}

export default withInterceptor(App);
