import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import MDButton from "components/MDButton";
import { Colors } from "lib/types/material";
import isEqual from "lodash.isequal";
import React, { memo, MouseEvent } from "react";

type Props = {
  title: string;
  color?: Colors;
  disabled?: boolean;
  name?: string;
  sx?: SxProps<Theme>; 
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

function MemoButton({
  title,
  color = "light",
  disabled = false,
  name = "",
  sx,
  onClick
}: Props) {
  return (
    <MDButton
      name={name}
      size="large"
      color={color}
      onClick={onClick}
      disabled={disabled}
      sx={sx}
    >
      {title}
    </MDButton>
  );
}

export default memo(MemoButton, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
