import accountApi from "apis/configuration/account/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import { requestSocketPathname } from "lib/consts/socket";
import useApiRequest from "lib/hooks/useApiRequest";
import useWebSocket from "lib/hooks/useWebSocket";
import { Account, Role } from "lib/types/account";
import { ApiSuccessErrorMessage } from "lib/types/language";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAccountTableData from "./useAccountTableData";

type SelectAccount = Omit<Account, "roles"> & { roles: Role[] };

const initialAccount: SelectAccount = {
  id: 0,
  username: "",
  password: "",
  roles: [],
};

const warningMessageMap = {
  Create: "Do you want to create?",
  Modify: "Do you want to modify?",
  Delete: "Do you want to delete?",
} as const;

function useAccount() {
  const { subscribe, isConnected } = useWebSocket();
  const [accounts, setAccounts] = useState<SelectAccount[]>(null);
  const { dataTableData } = useAccountTableData();
  const [accountTableData, setAccountTableData] = useState(dataTableData);
  const [createAccount, setCreateAccount] =
    useState<SelectAccount>(initialAccount);
  const { request, isLoading } = useApiRequest();
  const { request: accountsRequest, isLoading: accountsLoading } =
    useApiRequest();
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation("Translation");

  const getAccounts = useCallback(() => {
    accountsRequest({
      targetApi: () => accountApi.getAll(),
      onSuccess: (response) => {
        const accounts = (response as Account[]).map((account) => {
          return {
            ...account,
            password: "",
            roles: account.roles.map((role) => role.name),
          };
        });
        setAccounts(accounts);
      },
    });
  }, [accountsRequest]);

  useEffect(() => {
    getAccounts();

    if (!isConnected) return;
    subscribe(requestSocketPathname.ACCOUNTS, (body: Account[]) => {
      const accounts = body.map((account) => {
        return {
          ...account,
          password: "",
          roles: account.roles.map((role) => role.name),
        };
      });
      setAccounts(accounts);
    });
  }, [subscribe, isConnected, getAccounts]);

  const buttonCellClickHandler = useCallback(
    (index: number, name: string) => {
      const targetAccount = accounts[index];

      switch (name) {
        case "Create":
          request({
            targetApi: () =>
              accountApi.create(
                createAccount.username,
                createAccount.password,
                createAccount.roles
              ),
            onSuccess: (res) => {
              const response = res as { message: string };
              openModal(
                <DefaultModal
                  status="success"
                  title={t(response.message as keyof ApiSuccessErrorMessage)}
                />
              );
              setCreateAccount(initialAccount);
            },
            onError: (err) => {
              openModal(
                <DefaultModal
                  status="error"
                  title={t(
                    err?.response.data.message as keyof ApiSuccessErrorMessage
                  )}
                />
              );
            },
          });
          break;
        case "Modify":
          request({
            targetApi: () =>
              accountApi.modify(
                targetAccount.id,
                targetAccount.username,
                targetAccount.password,
                targetAccount.roles
              ),
            onSuccess: (res) => {
              const response = res as { message: string };
              openModal(
                <DefaultModal
                  status="success"
                  title={t(response.message as keyof ApiSuccessErrorMessage)}
                />
              );
            },
            onError: (err) => {
              openModal(
                <DefaultModal
                  status="error"
                  title={t(
                    err?.response.data.message as keyof ApiSuccessErrorMessage
                  )}
                />
              );
            },
          });
          break;
        case "Delete":
          request({
            targetApi: () => accountApi.del(targetAccount.id),
            onSuccess: (res) => {
              const response = res as { message: string };
              openModal(
                <DefaultModal
                  status="success"
                  title={t(response.message as keyof ApiSuccessErrorMessage)}
                />
              );
            },
            onError: (err) => {
              openModal(
                <DefaultModal
                  status="error"
                  title={t(
                    err?.response.data.message as keyof ApiSuccessErrorMessage
                  )}
                />
              );
            },
          });
          break;
        default:
          break;
      }
    },
    [createAccount, accounts, request, openModal, t]
  );

  const handleCheckModal = useCallback(
    (index: number, name: keyof typeof warningMessageMap) => {
      const isCrate = name === "Create";
      const targetRow = isCrate ? createAccount : accounts[index];
      if (!targetRow.username) {
        openModal(
          <DefaultModal status="error" title={t("Please input ID.")} />
        );
        return;
      }
      if (isCrate && !targetRow.password) {
        openModal(
          <DefaultModal status="error" title={t("Please input password.")} />
        );
        return;
      }

      openModal(
        <DefaultModal
          type="confirm"
          status="warning"
          title={`${t("ID")} "${targetRow.username}" ${t(
            warningMessageMap[name]
          )}`}
          onSuccess={() => buttonCellClickHandler(index, name)}
        />
      );
    },
    [openModal, t, buttonCellClickHandler, accounts, createAccount]
  );

  const createInputCellChangeHandler = useCallback(
    (index: number, value: string, name: string) => {
      if (index === -1) {
        switch (name) {
          case "Username":
            setCreateAccount((prev) => {
              return { ...prev, username: value };
            });
            break;
          case "Password":
            setCreateAccount((prev) => {
              return { ...prev, password: value };
            });
            break;
          default:
            break;
        }
      }
    },
    []
  );

  const createRolesChangeHandler = useCallback(
    (rowIndex: number, role: Role, checked: boolean) => {
      if (rowIndex === -1) {
        setCreateAccount((prev) => {
          const roles = checked
            ? [...prev.roles, role]
            : prev.roles.filter((prevRole) => prevRole !== role);
          return {
            ...prev,
            roles,
          };
        });
      }
    },
    []
  );

  const modifyInputCellChangeHandler = useCallback(
    (rowIndex: number, value: string, name: string) => {
      if (name === "Password") {
        const prev = accounts.map((account, accountIndex) => {
          return rowIndex === accountIndex
            ? { ...account, password: value }
            : account;
        });
        setAccounts(prev);
      }
    },
    [accounts]
  );

  const modifyRolesChangeHandler = useCallback(
    (rowIndex: number, role: Role, checked: boolean) => {
      const prev = accounts.map((account, accountIndex) => {
        const roles = checked
          ? [...account.roles, role]
          : account.roles.filter((accountRole) => accountRole !== role);
        return rowIndex === accountIndex
          ? {
              ...account,
              roles,
            }
          : account;
      });
      setAccounts(prev);
    },
    [accounts]
  );

  useEffect(() => {
    if (accounts) {
      const accountRows = [
        ...accounts.map((account, index) => {
          return {
            username: [true, false, index, account.username, null],
            password: [
              true,
              true,
              index,
              account.password,
              modifyInputCellChangeHandler,
            ],
            roles: [true, index, account.roles, modifyRolesChangeHandler],
            modify: [
              true,
              index,
              accountsLoading || isLoading,
              handleCheckModal,
            ],
            create: [false, index, accountsLoading || isLoading, null],
            delete: [
              true,
              index,
              accountsLoading || isLoading,
              handleCheckModal,
            ],
          };
        }),
        {
          username: [false, false, -1, "", null],
          password: [false, false, -1, "", null],
          roles: [false, -1, "", null],
          modify: [false, -1, accountsLoading || isLoading, null],
          create: [false, -1, accountsLoading || isLoading, null],
          delete: [false, -1, accountsLoading || isLoading, null],
        },
        {
          username: [
            true,
            true,
            -1,
            createAccount.username,
            createInputCellChangeHandler,
          ],
          password: [
            true,
            true,
            -1,
            createAccount.password,
            createInputCellChangeHandler,
          ],
          roles: [true, -1, createAccount.roles, createRolesChangeHandler],
          modify: [false, -1, accountsLoading || isLoading, null],
          create: [true, -1, accountsLoading || isLoading, handleCheckModal],
          delete: [false, -1, accountsLoading || isLoading, null],
        },
      ];

      setAccountTableData((prev) => {
        return { ...prev, rows: accountRows };
      });
    }
  }, [
    accounts,
    createAccount,
    isLoading,
    accountsLoading,
    createInputCellChangeHandler,
    createRolesChangeHandler,
    modifyInputCellChangeHandler,
    modifyRolesChangeHandler,
    buttonCellClickHandler,
    handleCheckModal,
  ]);

  return {
    accountTableData,
  };
}

export default useAccount;
