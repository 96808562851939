import { Popover } from "@mui/material";
import authenticationApi from "apis/authentication/api";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import useApiRequest from "lib/hooks/useApiRequest";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { uiConfigState } from "store/uiConfig";
import { userState } from "store/user";
import { PopoverType } from "context/popover/popoverContext";

type Props = {
  AnchorElement: Element | null;
  popoverType?: PopoverType;
  text?: string;
  onClose: () => void;
  onSuccess?: (input?: string) => void;
};

function CustomPopover({
  AnchorElement,
  popoverType,
  text,
  onClose,
  onSuccess = () => {},
}: Props) {
  const { t } = useTranslation("Translation");
  const [input, setInput] = useState("");
  const open = Boolean(AnchorElement);
  const id = open ? "popover" : undefined;
  const { request, isLoading, isError } = useApiRequest<boolean>();
  const { darkMode } = useRecoilValue(uiConfigState);
  const user = useRecoilValue(userState);
  const inputType =
    popoverType === undefined || popoverType === "PASSWORD"
      ? "password"
      : "number";
  const inputPlaceholder =
    popoverType === undefined || popoverType === "PASSWORD"
      ? t("Password")
      : t("Value");
  const buttonColor =
    popoverType === undefined || popoverType === "PASSWORD" ? "info" : "error";
  const buttonText =
    popoverType === undefined || popoverType === "PASSWORD"
      ? t("OK")
      : t("Control");

  const handleCheck = () => {
    if (popoverType === undefined || popoverType === "PASSWORD") {
      request({
        targetApi: () =>
          authenticationApi.checkAuth({
            username: user.username,
            password: input,
          }),
        onSuccess: () => handleConfirm(input),
      });
    } else {
      if (popoverType === "SET_FAN_SPEED") {
        if ((input !== "") && (/^\d+$/.test(input))) {
          handleConfirm(input);
        }
      }
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleConfirm = (input?: string) => {
    handleClose();
    onSuccess(input);
  };

  const handleClose = () => {
    setInput("");
    onClose();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleCheck();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={AnchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <MDBox
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgColor={darkMode ? "dark" : "white"}
        borderRadius="10px"
      >
        <form onSubmit={handleSubmit}>
          <MDBox display="flex" alignItems="center">
            <MDBox mr={2}>
              <MDInput
                autoFocus
                type={inputType}
                placeholder={inputPlaceholder}
                value={input}
                onChange={handleChangeInput}
                error={isError}
              />
            </MDBox>
            <MDButton
              color={buttonColor}
              onClick={handleCheck}
              disabled={isLoading}
            >
              {buttonText}
            </MDButton>
          </MDBox>
        </form>
      </MDBox>
    </Popover>
  );
}

export default CustomPopover;
