import DefaultButtonCell from "layouts/components/dataTable/defaultButtonCell";
import DefaultInputCell from "layouts/components/dataTable/defaultInputCell";
import { LogFileStatus } from "lib/types/log";
import React from "react";
import { useTranslation } from "react-i18next";

type TableColumnNo = { fileNo: number };
type TableColumnFileName = { fileName: string };
type TableColumnStatus = { status: LogFileStatus };
type TableColumnButtons = { isProcessing: boolean; onClick: () => void };
type TableRow = {
  no: TableColumnNo;
  fileName: TableColumnFileName;
  status: TableColumnStatus;
  delete: TableColumnButtons;
  download: TableColumnButtons;
};

function useLogTableData() {
  const { t } = useTranslation("Translation");

  const logTableData = {
    columns: [
      {
        Header: `${t("No.")}`,
        accessor: "no",
        width: "15%",
        align: "center",
        Cell: ({ value }: { value: TableColumnNo }) => {
          const { fileNo } = value;
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={String(fileNo)}
            />
          );
        },
      },
      {
        Header: `${t("File name")}`,
        accessor: "fileName",
        width: "30%",
        align: "center",
        Cell: ({ value }: { value: TableColumnFileName }) => {
          const { fileName } = value;
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="text"
              value={fileName}
            />
          );
        },
      },
      {
        Header: `${t("Status")}`,
        accessor: "status",
        width: "30%",
        align: "center",
        Cell: ({ value }: { value: TableColumnStatus }) => {
          const { status } = value;
          return (
            <DefaultInputCell
              use={true}
              input={false}
              type="status"
              value={t(status)}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "delete",
        width: "10%",
        align: "center",
        Cell: ({ value }: { value: TableColumnButtons }) => {
          const { isProcessing, onClick } = value;
          return (
            <DefaultButtonCell
              use={true}
              loading={isProcessing}
              color="primary"
              name="Delete"
              title={t("Delete")}
              onClickHandler={onClick}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "download",
        width: "10%",
        align: "center",
        Cell: ({ value }: { value: TableColumnButtons }) => {
          const { isProcessing, onClick } = value;
          return (
            <DefaultButtonCell
              use={true}
              loading={isProcessing}
              color="info"
              name="Download"
              title={t("Download")}
              onClickHandler={onClick}
            />
          );
        },
      },
    ],
    rows: [] as TableRow[],
  };

  return {
    logTableData,
  };
}

export default useLogTableData;
