import {ReactElement, ReactNode} from "react";

import MDBox from "components/MDBox";

import { useRecoilValue } from "recoil";
import { uiConfigState } from "store/uiConfig";

function DashboardLayout({ children }: { children: ReactNode }): ReactElement {
  const { miniSidenav } = useRecoilValue(uiConfigState);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        position: "relative",
        minHeight: '100vh',

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </MDBox>
  );
}

export default DashboardLayout;
