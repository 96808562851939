import { TFunction } from "i18next";
import {
  AMP_SALT,
  CELL_TEMPERATURE_MAX,
  CELL_TEMPERATURE_MIN,
  CELL_VOLTAGE_MAX,
  CELL_VOLTAGE_MIN,
  VOLTAGE_SALT,
} from "lib/consts/common";
import packBMS from "lib/consts/pack";
import { Colors } from "lib/types/material";
import {BalancingSetting, Se1001, Se1002, Se804FixedBlock} from "lib/types/pack";
import { changeDecimalPoint, getProgressValue, sunssf } from "./common";

export const getPackBMSStatusColor = ({
  buttonName,
  se804FixedBlock,
  balancingSetting,
} : {
  buttonName: string;
  se804FixedBlock: Se804FixedBlock;
  balancingSetting: BalancingSetting;
}): Colors => {
  switch (buttonName) {
    case "write_enable_button":
      if (se804FixedBlock.st & packBMS.se804St.STRING_ENABLED) {
        return "success";
      } else {
        return "error";
      }
    case "write_contactor_connect_button":
      if (se804FixedBlock.st & packBMS.se804St.CONTACTOR_STATUS) {
        return "success";
      } else {
        return "error";
      }
    case "write_balancing_button":
      if (se804FixedBlock.ctlBal === packBMS.se804SetBal.ENABLE) {
        return "success";
      } else if (se804FixedBlock.ctlBal === packBMS.se804SetBal.DISABLE) {
        return "error";
      } else {
        return "error";
      }
    case "write_normal_button":
      if (balancingSetting.mode === packBMS.balancingMode.NORMAL) {
        return "success";
      } else {
        return "error";
      }
    case "write_force_button":
      if (balancingSetting.mode === packBMS.balancingMode.FORCE) {
        return "success";
      } else {
        return "error";
      }
    case "write_even_odd_button":
      if (balancingSetting.mode === packBMS.balancingMode.ODD_EVEN) {
        return "success";
      } else {
        return "error";
      }
    case "write_select_button":
      if (balancingSetting.mode === packBMS.balancingMode.SELECT) {
        return "success";
      } else {
        return "error";
      }
    case "write_charge_snapshot_button":
      if (balancingSetting.mode === packBMS.balancingMode.CHARGE_SNAPSHOT) {
        return "success";
      } else {
        return "error";
      }
    case "write_discharge_snapshot_button":
      if (balancingSetting.mode === packBMS.balancingMode.DISCHARGE_SNAPSHOT) {
        return "success";
      } else {
        return "error";
      }
    case "write_charge_button":
      if (balancingSetting.condition & packBMS.balancingCondition.CHARGE) {
        return "success";
      } else {
        return "error";
      }
    case "write_discharge_button":
      if (balancingSetting.condition & packBMS.balancingCondition.DISCHARGE) {
        return "success";
      } else {
        return "error";
      }
    case "write_idle_button":
      if (balancingSetting.condition & packBMS.balancingCondition.IDLE) {
        return "success";
      } else {
        return "error";
      }
    case "version_button":
      return "info";
    case "state_button":
      if (
        se804FixedBlock.st & packBMS.se804St.STRING_ENABLED &&
        se804FixedBlock.st & packBMS.se804St.CONTACTOR_STATUS
      ) {
        return "success";
      } else if (
        se804FixedBlock.evt1 & packBMS.se804St.STRING_ENABLED &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.COMMUNICATION_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_TEMP_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_TEMP_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_CHARGE_CURRENT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_DISCHARGE_CURRENT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_VOLT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_VOLT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_SOC_MIN_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_SOC_MAX_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.TEMPERATURE_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.CONTACTOR_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.FAN_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.GROUND_FAULT) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OPEN_DOOR_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OTHER_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.CONFIGURATION_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_CELL_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_CELL_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CELL_VOLT_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_VOLT_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_MODULE_TEMP_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_MODULE_TEMP_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_TEMP_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_CURRENT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_24V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_5V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_3_3V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CURRENT_SENSOR_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.TEMPERATURE_SENSOR_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.EMERGENCY_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OPEN_WIRE_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.RTC_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FIRE_OUTBREAK) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FUSE_OPEN)
      ) {
        return "warning";
      } else if (
        !(se804FixedBlock.evt1 & packBMS.se804St.STRING_ENABLED) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.COMMUNICATION_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_TEMP_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_TEMP_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_CHARGE_CURRENT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_DISCHARGE_CURRENT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_VOLT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_VOLT_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_SOC_MIN_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_SOC_MAX_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.TEMPERATURE_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.CONTACTOR_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.FAN_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.GROUND_FAULT) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OPEN_DOOR_ERROR) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.OTHER_ALARM) &&
        !(se804FixedBlock.evt1 & packBMS.se804Evt1.CONFIGURATION_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_CELL_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_CELL_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CELL_VOLT_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_VOLT_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_MODULE_TEMP_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_MODULE_TEMP_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_TEMP_IMBALANCE_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_CURRENT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_24V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_5V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_3_3V_VOLT_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CURRENT_SENSOR_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.TEMPERATURE_SENSOR_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.EMERGENCY_ALARM) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OPEN_WIRE_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.RTC_ERROR) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FIRE_OUTBREAK) &&
        !(se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FUSE_OPEN)
      ) {
        return "info";
      } else {
        return "error";
      }
    case "emergency_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.EMERGENCY_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "charge_button":
      if (se804FixedBlock.st & packBMS.se804St.CONTACTOR_STATUS) {
        const current = se804FixedBlock.a * sunssf(se804FixedBlock.a_SF);
        if (current < -1) {
          return "success";
        } else if (current > 1) {
          return "error";
        } else {
          return "error";
        }
      }
      return "error";
    case "discharge_button":
      if (se804FixedBlock.st & packBMS.se804St.CONTACTOR_STATUS) {
        const current = se804FixedBlock.a * sunssf(se804FixedBlock.a_SF);
        if (current < -1) {
          return "error";
        } else if (current > 1) {
          return "success";
        } else {
          return "error";
        }
      }
      return "error";
    case "pre_charge_button":
      if (se804FixedBlock.st & packBMS.se804St.PRE_CHARGE_STATUS) {
        return "success";
      } else {
        return "error";
      }
    case "causes_of_connection_failure_button":
      if (se804FixedBlock.conFail === packBMS.se804ConFail.NO_FAILURE) {
        return "success";
      } else {
        return "error";
      }
    case "plus_contactor_state_button":
      if (se804FixedBlock.conSt & packBMS.se804ConSt.CONTACTOR_0) {
        return "success";
      } else {
        return "error";
      }
    case "minus_contactor_state_button":
      if (se804FixedBlock.conSt & packBMS.se804ConSt.CONTACTOR_1) {
        return "success";
      } else {
        return "error";
      }
    case "pre_charge_contactor_state_button":
      if (se804FixedBlock.conSt & packBMS.se804ConSt.CONTACTOR_2) {
        return "success";
      } else {
        return "error";
      }
    case "pack_iso_spi_communication_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.COMMUNICATION_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_24v_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_24V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_5v_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_5V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_3_3v_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_INTERNAL_3_3V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_contactor_error_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.CONTACTOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_ground_fault_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.GROUND_FAULT) {
        return "error";
      } else {
        return "success";
      }
    case "pack_current_sensor_error_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CURRENT_SENSOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_temperature_sensor_error_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.TEMPERATURE_SENSOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_current_mismatch_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_CURRENT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_voltage_mismatch_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MISMATCH_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_over_voltage_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_VOLT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_voltage_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_VOLT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_temperature_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_TEMP_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_temperature_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_TEMP_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_imbalance_temperature_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.TEMPERATURE_IMBALANCE_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.TEMPERATURE_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_charge_current_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_CHARGE_CURRENT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_CHARGE_CURRENT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_discharge_current_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_DISCHARGE_CURRENT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_DISCHARGE_CURRENT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_soc_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_SOC_MAX_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.OVER_SOC_MAX_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_soc_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_SOC_MIN_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.UNDER_SOC_MIN_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_imbalance_voltage_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_VOLT_IMBALANCE_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_VOLT_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_over_temperature_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_MODULE_TEMP_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_MODULE_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_under_temperature_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_MODULE_TEMP_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_MODULE_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_imbalance_temperature_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_TEMP_IMBALANCE_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.MODULE_TEMP_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_over_voltage_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_CELL_VOLT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OVER_CELL_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_under_voltage_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_CELL_VOLT_ALARM) {
        return "error";
      } else if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.UNDER_CELL_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_imbalance_voltage_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.CELL_VOLT_IMBALANCE_ALARM) {
        return "error";
      } else if (se804FixedBlock.evt1 & packBMS.se804Evt1.VOLTAGE_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "open_wire_error_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.OPEN_WIRE_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "rtc_error_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.RTC_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "fire_outbreak_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FIRE_OUTBREAK) {
        return "error";
      } else {
        return "success";
      }
    case "fuse_open_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804EvtVnd1.FUSE_OPEN) {
        return "error";
      } else {
        return "success";
      }
    case "open_door_button":
      if (se804FixedBlock.evtVnd1 & packBMS.se804Evt1.OPEN_DOOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "fan_button":
      if (se804FixedBlock.evt1 & packBMS.se804Evt1.FAN_ERROR) {
        return "error";
      } else {
        return "success";
      }
    default:
      return "light";
  }
};

export const getPackVoltageMax = ({
  moduleLength,
  cellLength,
}: {
  moduleLength: number;
  cellLength: number;
}) => {
  return VOLTAGE_SALT * moduleLength * cellLength;
};

export const getPackAmpMax = () => AMP_SALT;

export const getPackProgressItems = ({
  se804FixedBlock,
  moduleLength,
  cellLength,
  t,
}: {
  se804FixedBlock: Se804FixedBlock;
  moduleLength: number;
  cellLength: number;
  t: TFunction<"Translation", undefined, "Translation">;
}) => {
  return [
    getProgressValue({
      value: se804FixedBlock?.soc,
      sf: se804FixedBlock?.soc_SF,
      title: `${t("SoC")}`,
      color: "info",
      unit: "%",
    }),
    getProgressValue({
      value: se804FixedBlock?.a,
      sf: se804FixedBlock?.a_SF,
      max: getPackAmpMax(),
      title: `${t("Current")}`,
      color: "error",
      unit: "A",
    }),
    getProgressValue({
      value: se804FixedBlock?.dcLinkV,
      sf: se804FixedBlock?.v_SF,
      max: getPackVoltageMax({
        moduleLength,
        cellLength,
      }),
      title: `${t("DC link voltage")}`,
      color: "warning",
      unit: "V",
    }),
    getProgressValue({
      value: se804FixedBlock?.v,
      sf: se804FixedBlock?.v_SF,
      max: getPackVoltageMax({
        moduleLength,
        cellLength,
      }),
      title: `${t("String link voltage")}`,
      color: "success",
      unit: "V",
    }),
  ];
};

export const getPackChartItems = ({
  se804FixedBlock,
  t
} : {
  se804FixedBlock: Se804FixedBlock;
  t: TFunction<"Translation", undefined, "Translation" >;
}) => {
  return [
    {
      title: `${t("Cell voltage")}`,
      data: [
        changeDecimalPoint(
          se804FixedBlock?.cellVMin,
          se804FixedBlock?.cellV_SF
        ),
        changeDecimalPoint(
          se804FixedBlock?.cellVAvg,
          se804FixedBlock?.cellV_SF
        ),
        changeDecimalPoint(
          se804FixedBlock?.cellVMax,
          se804FixedBlock?.cellV_SF
        ),
      ],
      unit: "V",
      description: `${
        se804FixedBlock
          ? t("Min") +
          `: M ` + String((se804FixedBlock.cellVMinMod >> 8) + 1).padStart(2, "0") +
          ` - C ` + String((se804FixedBlock.cellVMinMod & 0x00ff) + 1).padStart(2, "0") 
          + `\n` +
          t("Max") +
          `: M ` + String((se804FixedBlock.cellVMaxMod >> 8) + 1).padStart(2, "0") + 
          ` - C ` + String((se804FixedBlock.cellVMaxMod & 0x00ff) + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: CELL_VOLTAGE_MIN,
      yMax: CELL_VOLTAGE_MAX,
    },
    {
      title: `${t("Module temperature")}`,
      data: [
        changeDecimalPoint(
          se804FixedBlock?.modTmpMin,
          se804FixedBlock?.modTmp_SF
        ),
        changeDecimalPoint(
          se804FixedBlock?.modTmpAvg,
          se804FixedBlock?.modTmp_SF
        ),
        changeDecimalPoint(
          se804FixedBlock?.modTmpMax,
          se804FixedBlock?.modTmp_SF
        ),
      ],
      unit: "ºC",
      description: `${
        se804FixedBlock
          ? t("Min") +
          `: M ` + String((se804FixedBlock.modTmpMinMod >> 8) + 1).padStart(2, "0") +
          ` - T ` + String(Math.floor((se804FixedBlock.modTmpMinMod & 0x00ff) / 2) + 1).padStart(2, "0") 
          + `\n` +
          t("Max") +
          `: M ` + String((se804FixedBlock.modTmpMaxMod >> 8) + 1).padStart(2, "0") +
          ` - T ` + String(Math.floor((se804FixedBlock.modTmpMaxMod & 0x00ff) / 2) + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: CELL_TEMPERATURE_MIN,
      yMax: CELL_TEMPERATURE_MAX,
    },
  ];
};

export const getPackBMSEnvironmentSensorColor = ({
  buttonName,
  se1001
} : {
  buttonName: string;
  se1001: Se1001;
}): Colors => {
  switch (buttonName) {
    case "write_warning_light_button":
      if (se1001.ctlWarnLight === packBMS.se1001SetWarnLight.ON)
        return "warning";
      if (se1001.ctlWarnLight === packBMS.se1001SetWarnLight.OFF)
        return "error";
      return "light";
    case "temperature_1_button":
    case "temperature_2_button":
    case "temperature_3_button":
    case "temperature_4_button":
    case "temperature_5_button":
    case "temperature_6_button":
    case "temperature_7_button":
    case "temperature_8_button":
    case "hydrogen1_button":
    case "hydrogen2_button":
    case "humidity_button":
      return "info";
    case "fire_outbreak_button":
      if (se1001.evt1 & packBMS.se1001Evt1.FIRE_OUTBREAK)
        return "error";
      return "success";
    case "open_door_button":
      if (se1001.evt1 & packBMS.se1001Evt1.OPEN_DOOR)
        return "error";
      return "success";
    default:
      return "light";
  }
};

export const getPackBMSEnvironmentFanColor = ({
  buttonName,
  se1002
} : {
  buttonName: string;
  se1002: Se1002;
}): Colors => {
  switch (buttonName) {
    case "fan_1_button":
    case "fan_2_button":
    case "fan_3_button":
    case "fan_4_button":
    case "fan_5_button":
    case "fan_6_button":
    case "fan_7_button":
    case "fan_8_button":
    case "fan_9_button":
    case "fan_10_button":
    case "fan_11_button":
    case "fan_12_button":
    case "fan_13_button":
    case "fan_14_button":
    case "fan_15_button":
    case "fan_16_button":
    case "fan_17_button":
    case "fan_18_button":
    case "fan_19_button":
    case "fan_20_button":
    case "fan_21_button":
    case "fan_22_button":
    case "fan_23_button":
    case "fan_24_button":
      return "info";
    default:
      return "light";
  }
};