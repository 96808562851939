import React, {ReactElement} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DataTable from "layouts/components/dataTable";
import MDButton from "components/MDButton";
import EthernetCard from "./components/ethernetCard";
import PeriodCard from "./components/periodCard";
import TableCard from "./components/tableCard";
import useSetting from "./hooks/useSetting";
import { PACK_BMS_COUNT_MAX } from "lib/consts/common";
import { useTranslation } from "react-i18next";
import pxToRem from "assets/theme/functions/pxToRem";
import NamePlateCard from "./components/namePlateCard";
import WebHookCard from "./components/webHookCard";
import DeviceNameCard from "./components/deviceNameCard";
import LogBackupCard from "./components/logBackupCard";

function Setting(): ReactElement {
  const {
    formData,
    isLoading,
    isModified,
    tableData,
    handleSelectIp,
    handleSelectNumberOfConnections,
    handleChangeInputNumber,
    handleChangeCheckEnable,
    handleChangeLogBackupInputNumber,
    handleChangeLogBackupText,
    handleChangeCapacityInputNumber,
    handleChangeChargeDischargeRateInputNumber,
    handleChangeSoCInputNumber,
    handleChangeText,
    handleClickButtonSave,
  } = useSetting();
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      <MDBox mb={1}>
        <DeviceNameCard
          title={t("Device Name")}
          name="deviceName"
          value={formData.deviceName}
          onChange={handleChangeText}
        />
      </MDBox>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} xl={6}>
          <EthernetCard
            title={`${t("Ethernet")} #1`}
            ipName="firstIp"
            ipValue={formData.firstIp}
            ipOnChange={handleSelectIp}
            ipOptions={formData.localIpList}
            portName="firstPort"
            portValue={formData.firstPort}
            portOnChange={handleChangeInputNumber}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <EthernetCard
            title={`${t("Ethernet")} #2`}
            ipName="secondIp"
            ipValue={formData.secondIp}
            ipOnChange={handleSelectIp}
            ipOptions={formData.localIpList}
            portName="secondPort"
            portValue={formData.secondPort}
            portOnChange={handleChangeInputNumber}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} xl={6}>
          <PeriodCard
            title={t("Event")}
            name="eventStorePeriod"
            value={formData.eventStorePeriod}
            onChange={handleChangeInputNumber}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <PeriodCard
            title={t("Log")}
            name="logStorePeriod"
            value={formData.logStorePeriod}
            onChange={handleChangeInputNumber}
          />
        </Grid>
      </Grid>
      <MDBox mb={1}>
        <LogBackupCard
          title={t("Log Backup")}
          isCheck={formData.useLogBackup}
          ipValue={formData.logBackupSetting.ip}
          portValue={formData.logBackupSetting.port}
          userNameValue={formData.logBackupSetting.userName}
          passwordValue={formData.logBackupSetting.password}
          dirPathValue={formData.logBackupSetting.dirPath}
          onChangeCheck={handleChangeCheckEnable}
          onChangeNumber={handleChangeLogBackupInputNumber}
          onChangeText={handleChangeLogBackupText}
        />
      </MDBox>
      <MDBox mb={1}>
        <NamePlateCard
            title={t("Nameplate")}
            chargeCapacityValue={formData.namePlateSetting.chargeCapacity}
            energyCapacityValue={formData.namePlateSetting.energyCapacity}
            maxChargeRateValue={formData.namePlateSetting.maxChargeRate}
            maxDischargeRateValue={formData.namePlateSetting.maxDischargeRate}
            maxSoCValue={formData.namePlateSetting.maxSoC}
            minSoCValue={formData.namePlateSetting.minSoC}
            chargeCapacityOnChange={handleChangeCapacityInputNumber}
            energyCapacityOnChange={handleChangeCapacityInputNumber}
            maxChargeRateOnChange={handleChangeChargeDischargeRateInputNumber}
            maxDischargeRateOnChange={
              handleChangeChargeDischargeRateInputNumber
            }
            maxSoCOnChange={handleChangeSoCInputNumber}
            minSoCOnChange={handleChangeSoCInputNumber}
        />
      </MDBox>
      <MDBox mb={1}>
        <WebHookCard
          title={t("WebHook")}
          isCheck={formData.useWebHook}
          onChangeCheck={handleChangeCheckEnable}
          urlValue={formData.webHookUrl}
          urlOnChange={handleChangeText}
        />
      </MDBox>
      <MDBox mb={1}>
        <TableCard
            title={t("Pack BMS")}
            checkName="usePackBMS"
            isCheck={formData.usePackBMS}
            onChangeCheck={handleChangeCheckEnable}
            selectName="numberOfPackBMS"
            selectValue={formData.numberOfPackBMS}
            selectOnChange={handleSelectNumberOfConnections}
            selectOptions={Array.from({ length: PACK_BMS_COUNT_MAX }).map(
                (_: unknown, index: number) => {
                  return (index + 1).toString();
                }
            )}
        >
          {formData.usePackBMS && (
              <MDBox mx={2} mb={1}>
                <DataTable
                    table={tableData}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    noPagination={true}
                />
              </MDBox>
          )}
        </TableCard>
      </MDBox>
      <MDButton
        disabled={isLoading || !isModified}
        color="info"
        name="Modify"
        onClick={handleClickButtonSave}
        fullWidth
        sx={{ fontSize: pxToRem(18), padding: pxToRem(12) }}
      >
        {t("Save")}
      </MDButton>
    </MDBox>
  );
}

export default Setting;
