import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DefaultCard from "layouts/components/cards/defaultCard";
import Module from "./components/module";
import ControlButtons from "layouts/components/controlButtons";
import StatusButtons from "layouts/components/statusButtons";
import CardProgress from "layouts/components/cardProgress";
import CardChart from "layouts/components/cardChart";
import useOverview from "./hooks/useOverview";
import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import MDButton from "components/MDButton";
import ControlSelects from "./components/controlSelects";
import MDTypography from "components/MDTypography";
import MDSelect from "components/MDSelect";
import { BALANCING_RESERVATION_COUNT_MAX } from "lib/consts/common";
import DataTable from "layouts/components/dataTable";
import MDCheckBox from "components/MDCheckBox";
import MDInput from "../../../../../components/MDInput";

type Props = {
  packBMSNo: number;
};

const GridSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed !important" : "default",
});

function Overview({ packBMSNo }: Props): ReactElement {
  const {
    BMS,
    se805List,
    balancing,
    buttons,
    progressList,
    chartList,
    ROLE,
    DISABLED_MODULES,
    isControlButtonsLoading,
    balancingSettingRangeFormData,
    balancingSettingNoBalancingFormData,
    balancingSettingReservationFormData,
    balancingReservationTableData,
    isDisabledBalancingSelectSetting,
    handleSelectBalancing,
    handleChangeNoBalancingCheckEnable,
    handleChangeNoBalancingInputNumber,
    handleChangeReservationCheckEnable,
    handleSelectNumberOfReservations,
    handleClickButton,
  } = useOverview({ packBMSNo });
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      <Grid container spacing={1} mb={1}>
        <Grid item xs={12} xl={4}>
          {progressList.map(
            ({ title, percentValue, viewValue, color, unit }, progressIndex) => {
              return (
                <MDBox key={progressIndex} pb={(progressIndex !== (progressList.length - 1)) ? 1 : 0}>
                  <CardProgress
                    title={`${title}(${unit})`}
                    value={percentValue}
                    label={`${viewValue}${unit}`}
                    color={color}
                  />
                </MDBox>
              );
            }
          )}
        </Grid>
        {chartList.map(
          ({ title, unit, data, description, yMin, yMax }, chartIndex) => {
            return (
              <CardChart
                key={chartIndex}
                title={`${title}(${unit})`}
                chartDescription={description}
                chartData={data}
                yMin={yMin}
                yMax={yMax}
              />
            );
          }
        )}
      </Grid>
      <MDBox mb={1}>
        <DefaultCard icon="build" title={t("Control")}>
          <MDBox mx={2} mb={1}>
            <ControlButtons
              buttons={buttons.control}
              onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "CONTROL") : null}
              isLoading={isControlButtonsLoading}
            />
          </MDBox>
        </DefaultCard>
      </MDBox>
      {(ROLE.ADMIN || ROLE.QC) && (
        <MDBox mb={1}>
          <DefaultCard icon="build" title={t("Balancing")}>
            <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
              <ControlButtons
                buttons={buttons.balancingMode}
                onClick={(e) =>(ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "BALANCING_MODE") : null}
                isLoading={isControlButtonsLoading}
              />
            </MDBox>
            <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
              <ControlButtons
                buttons={buttons.balancingCondition}
                onClick={(e) =>(ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "BALANCING_CONDITION") : null}
                isLoading={isControlButtonsLoading}
              />
            </MDBox>
            <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
              <ControlSelects
                formValue={balancingSettingRangeFormData}
                onChange={handleSelectBalancing}
                isLoading={isControlButtonsLoading}
              />
            </MDBox>
            <MDBox
              pb={2}
              mb={1}
              mx={2}
              gap={1}
              borderBottom="1px dotted gray"
            >
              <MDBox mb={1} mx={6}>
                <Grid container alignItems="center">
                  <Grid item sm={1}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {t("No balancing")}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} textAlign="center">
                    <MDCheckBox
                      name="useNoBalancing"
                      checked={
                        balancingSettingNoBalancingFormData.useNoBalancing
                      }
                      onChange={handleChangeNoBalancingCheckEnable}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {t("Min current")}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={3} sx={GridSX(!balancingSettingNoBalancingFormData.useNoBalancing)}>
                    <MDInput
                      type="number"
                      label={t("Min current")}
                      name="noBalancingMinCurrent"
                      value={balancingSettingNoBalancingFormData.noBalancingMinCurrent}
                      onChange={handleChangeNoBalancingInputNumber}
                      disabled={!balancingSettingNoBalancingFormData.useNoBalancing}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {t("Max current")}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={3} sx={GridSX(!balancingSettingNoBalancingFormData.useNoBalancing)}>
                    <MDInput
                      type="number"
                      label={t("Max current")}
                      name="noBalancingMaxCurrent"
                      value={balancingSettingNoBalancingFormData.noBalancingMaxCurrent}
                      onChange={handleChangeNoBalancingInputNumber}
                      disabled={!balancingSettingNoBalancingFormData.useNoBalancing}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox
              pb={2}
              mb={1}
              mx={2}
              gap={1}
              borderBottom="1px dotted gray"
            >
              <MDBox mb={1} mx={6}>
                <Grid container alignItems="center">
                  <Grid item sm={1}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {t("Balancing reservation")}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={1} textAlign="center">
                    <MDCheckBox
                      name="useReservation"
                      checked={
                        balancingSettingReservationFormData.useReservation
                      }
                      onChange={handleChangeReservationCheckEnable}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      textTransform="capitalize"
                      textAlign="center"
                    >
                      {t("Number of reservation")}
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    sm={8}
                    sx={GridSX(
                      !balancingSettingReservationFormData.useReservation
                    )}
                  >
                    <MDSelect
                      label={t("Number of reservation")}
                      name="numberOfReservation"
                      fullWidth
                      value={
                        balancingSettingReservationFormData.numberOfReservation
                      }
                      disabled={
                        !balancingSettingReservationFormData.useReservation
                      }
                      onChange={handleSelectNumberOfReservations}
                      options={Array.from({
                        length: BALANCING_RESERVATION_COUNT_MAX,
                      }).map((_, index) => {
                        return { name: index + 1, value: index + 1 };
                      })}
                    />
                  </Grid>
                  {balancingSettingReservationFormData.useReservation && (
                    <DataTable
                      table={balancingReservationTableData}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noPagination={true}
                    />
                  )}
                </Grid>
              </MDBox>
            </MDBox>
            <MDBox pb={2} mb={1} mx={2}>
              <MDButton
                color={isDisabledBalancingSelectSetting ? "light" : "info"}
                fullWidth
                onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "BALANCING_SETTING") : null}
                disabled={
                  isDisabledBalancingSelectSetting || isControlButtonsLoading
                }
              >
                {t("Balancing setting")}
              </MDButton>
            </MDBox>
          </DefaultCard>
        </MDBox>
      )}
      <MDBox mb={1}>
        <DefaultCard icon="memory" title={t("Status")}>
          <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
            <StatusButtons buttons={buttons.status} />
          </MDBox>
          <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
            <StatusButtons buttons={buttons.contactorStatus} />
          </MDBox>
          <MDBox mb={1} mx={2}>
            <StatusButtons buttons={buttons.statusDetail} />
          </MDBox>
        </DefaultCard>
      </MDBox>
      {BMS.device && (se805List.length > 0) && (
        <DefaultCard icon="air" title={`${t("Cell voltage")} / ${t("Module temperature")}`}>
          <MDBox mb={1}>
            {BMS.device
                ? se805List.map((se805, index) => {
                  return (
                      <Module
                          key={index}
                          packBMSIndex={packBMSNo}
                          moduleIndex={index}
                          se805={se805}
                          balancing={balancing}
                          color={
                            BMS.status?.moduleIsoSpiStatusList[index]
                                ? "success"
                                : "error"
                          }
                      />
                  );
                })
                : DISABLED_MODULES.map((se805, index) => {
                  return (
                      <Module
                          key={index}
                          packBMSIndex={packBMSNo}
                          moduleIndex={index}
                          se805={se805}
                          balancing={balancing}
                          color="light"
                      />
                  );
                })}
          </MDBox>
        </DefaultCard>
      )}
    </MDBox>
  );
}

export default Overview;
