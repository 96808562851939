import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import AuthenticationApiLayout from "layouts/pages/authentication/sign-in/components";
import useSignIn from "./hooks/useSignIn";
import {ReactElement} from "react";

function SignIn(): ReactElement {
  const {
    formValue,
    isLoading,
    disabledSubmitButton,
    signIn,
    handleChangeInput,
  } = useSignIn();
  const { t } = useTranslation("Translation");

  return (
    <AuthenticationApiLayout
      title={t("System BMS")}
      description={t("Sign in to stay connected.")}
    >
      <MDBox component="form" role="form" onSubmit={signIn}>
        <MDBox mb={1} color="white">
          <MDInput
            type="text"
            name="username"
            label={t("ID")}
            value={formValue.username}
            fullWidth
            onChange={handleChangeInput}
            disabled={isLoading}
            signIn
          />
        </MDBox>
        <MDBox mb={1}>
          <MDInput
            type="password"
            name="password"
            label={t("Password")}
            value={formValue.password}
            fullWidth
            onChange={handleChangeInput}
            disabled={isLoading}
            signIn
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            type="submit"
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            disabled={disabledSubmitButton || isLoading}
          >
            {t("Sign in")}
          </MDButton>
        </MDBox>
      </MDBox>
    </AuthenticationApiLayout>
  );
}

export default SignIn;
