import React, {
  ChangeEvent,
  FocusEvent, ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

// Components
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";
import MDTextField from "components/MDTextField";

// Declaring props types for DefaultInputCell
interface Props {
  use?: boolean;
  index?: number;
  id?: string;
  type?: string;
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  onChangeHandler?: any;
  disabled?: boolean;
}

const BoxSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed" : "default",
  minHeight: pxToRem(34),
  maxHeight: pxToRem(34),
});

function DefaultTextFieldCell({
  use,
  index,
  id,
  type,
  label,
  value,
  name,
  placeholder,
  onChangeHandler,
  disabled,
}: Props): ReactElement {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChangeTextFieldValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      onChangeHandler(index, value, name);
    },
    [index, name, onChangeHandler]
  );

  const handleBlurTextFieldValue = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (value === inputValue) return;
      const { name } = e.target;
      onChangeHandler(index, inputValue, name);
    },
    [index, value, inputValue, onChangeHandler]
  );

  return use ? (
    <MDBox display="flex" alignItems="center" sx={BoxSX(disabled)}>
      <MDTextField
        id={id}
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={handleChangeTextFieldValue}
        onBlur={handleBlurTextFieldValue}
        disabled={disabled}
      />
    </MDBox>
  ) : (
    <MDBox display="flex" alignItems="center"></MDBox>
  );
}

// Declaring default props for DefaultInputCell
DefaultTextFieldCell.defaultProps = {
  use: false,
  index: -1,
  id: "",
  type: "text",
  label: "",
  value: "",
  onChangeHandler: null,
};

export default DefaultTextFieldCell;
