import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import {StorageAccount} from "store/user";

const signIn = async (username: string, password: string) => {
  const response = await api.post(requestPathname.SIGN_IN, {
    username,
    password,
  });
  return response.data;
};

const signOut = async (storageAccount: StorageAccount) => {
  const response = await api.post(requestPathname.SIGN_OUT, storageAccount);
  return response.data;
};

const refreshToken = async () => {
  const response = await api.post(requestPathname.REFRESH_TOKEN);
  return response.data;
};

const checkAuth = async (data: { username: string, password: string}) => {
  const response = await api.post(requestPathname.CHECK_AUTH, data);
  return response.data;
};

const getAlreadySignIn = async () => {
  const response = await api.post<StorageAccount>(requestPathname.GET_ALREADY_SIGN_IN);
  return response.data;
};

const getSessionTime = async (storageAccount: StorageAccount) => {
  const response = await api.post(requestPathname.GET_SESSION_TIME, storageAccount);
  return response.data;
};

const initSessionTime = async (storageAccount: StorageAccount) => {
  const response = await api.post(requestPathname.INIT_SESSION_TIME, storageAccount);
  return response.data;
};

const authenticationApi = {
  signIn,
  signOut,
  refreshToken,
  checkAuth,
  getAlreadySignIn,
  getSessionTime,
  initSessionTime,
};

export default authenticationApi;
