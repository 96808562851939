import React, {
  ChangeEvent,
  FormEvent, useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import authenticationApi from "apis/authentication/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import { storageKey } from "lib/consts/storageKey";
import useApiRequest from "lib/hooks/useApiRequest";
import {StorageAccount} from "store/user";
import storage from "lib/utils/storage";
import { useTranslation } from "react-i18next";
import { ApiSuccessErrorMessage } from "lib/types/language";
import {useRecoilState} from "recoil";
import { userState } from "store/user";
import useInterval from "lib/hooks/useInterval";
import {getCurrentUser} from "lib/types/storage";
import {INTERVAL_TIME} from "lib/consts/common";

const INIT_FORM_VALUE = {
  username: "",
  password: "",
};

function useSignIn() {
  const [user, setUser] = useRecoilState(userState);
  const [formValue, setFormValue] = useState(INIT_FORM_VALUE);
  const { request, isLoading } = useApiRequest();
  const { openModal } = useContext(ModalContext);
  const disabledSubmitButton = useMemo(
    () => Boolean(!formValue.username || !formValue.password),
    [formValue.username, formValue.password]
  );
  const { t } = useTranslation("Translation");

  const signIn = (e: FormEvent) => {
    e.preventDefault();
    request({
      targetApi: () =>
        authenticationApi.signIn(formValue.username, formValue.password),
      onSuccess: (response) => {
        const responseData = response as StorageAccount;
        storage.set(storageKey.USER, responseData);
        setUser(responseData);
      },
      onError: (err) => {
        openModal(
          <DefaultModal
            status="error"
            title={t(
              err?.response.data.message as keyof ApiSuccessErrorMessage
            )}
          />
        );
      },
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormValue((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const getAlreadySignIn = useCallback(async () => {
    if (!user) {
      const signInAccount = await authenticationApi.getAlreadySignIn();
      if (signInAccount) {
        setUser({
          id: signInAccount.id,
          username: signInAccount.username,
          roles: signInAccount.roles,
        });
      }
    }
  }, [user, setUser]);

  useInterval(() => {
      if (getCurrentUser() === undefined) {
        if (user === null) {
          getAlreadySignIn().then();
        } else {
          setUser(null);
        }
      } else {
        if (getCurrentUser() !== user) {
          setUser(getCurrentUser());
        }
      }
    },
    INTERVAL_TIME,
    true
  );

  return {
    formValue,
    isLoading,
    disabledSubmitButton,
    signIn,
    handleChangeInput,
  };
}

export default useSignIn;
