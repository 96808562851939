import React, {ReactElement} from "react";

// Components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Declaring props types for DefaultButtonCell
interface Props {
  title: string;
  use?: boolean;
  index?: number;
  loading?: boolean;
  name?: string;
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  onClickHandler?: any;
}

function DefaultButtonCell({
  title,
  use,
  index,
  loading,
  name,
  color,
  onClickHandler,
}: Props): ReactElement {
  return use ? (
    <MDBox display="flex" alignItems="center">
      <MDButton
        variant="gradient"
        color={color}
        size="medium"
        fullWidth
        disabled={loading}
        onClick={(e) => onClickHandler(index, name, e)}
        name={name}
      >
        {title}
      </MDButton>
    </MDBox>
  ) : (
    <MDBox display="flex" alignItems="center"></MDBox>
  );
}

// Declaring default props for DefaultButtonCell
DefaultButtonCell.defaultProps = {
  use: false,
  index: -1,
  loading: false,
  name: "",
  color: "dark",
  onClickHandler: null,
};

export default DefaultButtonCell;
