import dashboardApi from "apis/dashboard/api";
import { ControlType } from "lib/types/api";
import PopoverContext from "context/popover/popoverContext";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import useApiRequest from "lib/hooks/useApiRequest";
import useSystemButtons, {
  SystemButton,
  SystemButtons,
} from "lib/hooks/useSystemButtons";
import {
  Se802,
  SystemBMSData,
  SystemBMSStatus,
} from "lib/types/dashboard";
import { changeDecimalPoint, getUserRole } from "lib/utils/common";
import {
  getDashboardChartItems,
  getDashboardProgressItems,
  getDashboardStatusColor,
  getKwChartData,
} from "lib/utils/dashboard";
import React, {
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useContext,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import useWebSocket from "lib/hooks/useWebSocket";
import { requestSocketPathname } from "lib/consts/socket";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { userState } from "store/user";
import useInterval from "lib/hooks/useInterval";
import { INTERVAL_TIME } from "lib/consts/common";

type SystemBMSInitData = {
  device: SystemBMSData;
  status: SystemBMSStatus;
};

type KwChartInitData = {
  label: string[];
  data: (number | string)[];
};

type Buttons = {
  namePlate: SystemButton[];
  control: SystemButton[];
  heartbeat: SystemButton[];
  status: SystemButton[];
  statusDetail: SystemButton[];
  firmwareUpdate: SystemButton[];
};

type ControlModeType =
  | "CONTROL"
  | "FIRMWARE_UPDATE";

const SYSTEM_BMS_INIT_DATA: SystemBMSInitData = {
  device: null,
  status: null,
};

const inverterStatusMap = {
  0: "-",
  1: "STOPPED",
  2: "STANDBY",
  3: "STARTED",
};

const KW_CHART_MAX_DATA_LENGTH = 180;

const KW_CHART_INIT_DATA: KwChartInitData = {
  label: [],
  data: [],
};

const INIT_BUTTONS = (
  systemButtons: SystemButtons
) => ({
  namePlate: systemButtons.dashboardNamePlate,
  control: systemButtons.dashboardControl,
  heartbeat: systemButtons.dashboardHeartbeat,
  status: systemButtons.dashboardStatus,
  statusDetail: systemButtons.statusDetail,
  firmwareUpdate: systemButtons.firmwareUpdate,
});

function useDashboard() {
  const user = useRecoilValue(userState);
  const { setting, runtime } = useRecoilValue(systemConfigState);
  const { subscribe, isConnected } = useWebSocket();
  const { systemButtons } = useSystemButtons();
  const ROLE = getUserRole(user);
  const isRemote = !runtime.isLocal || runtime.isLocalRemoteProcessing;
  const isUsePackBMS = useMemo(() => setting.usePackBMS, [setting.usePackBMS]);
  const [systemBMS, setSystemBMS] = useState<SystemBMSInitData>(SYSTEM_BMS_INIT_DATA);
  const [buttons, setButtons] = useState<Buttons>(INIT_BUTTONS(systemButtons));
  const [kwChartData, setKwChartData] = useState(KW_CHART_INIT_DATA);
  const [deviceData, setDeviceData] = useState<SystemBMSData>();
  const [statusData, setStatusData] = useState<SystemBMSStatus>();
  const { request: deviceRequest } = useApiRequest<SystemBMSData>();
  const { request: statusRequest } = useApiRequest<SystemBMSStatus>();
  const { request: controlRequest, isLoading: isControlButtonsLoading } =
    useApiRequest();
  const { request: firmwareUpdateRequest, isLoading: isFirmwareUpdateButtonsLoading } =
    useApiRequest();
  const { openPopover } = useContext(PopoverContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation("Translation");
  const se802 = systemBMS.device?.se802;
  const se803FixedBlock = systemBMS.device?.se803.fixedBlock;
  const packBMSDataList = systemBMS.device?.packBMSDataList;
  const progressList = useMemo(
    () => getDashboardProgressItems(se802, se803FixedBlock, packBMSDataList, t),
    [se802, se803FixedBlock, packBMSDataList, t]
  );
  const chartList = useMemo(
    () =>
      getDashboardChartItems(
        se802,
        se803FixedBlock,
        packBMSDataList,
        t
      ),
    [se802, se803FixedBlock, packBMSDataList, t]
  );

  const init = useCallback(() => {
    setSystemBMS(SYSTEM_BMS_INIT_DATA);
    setButtons(INIT_BUTTONS(systemButtons));
    setKwChartData(KW_CHART_INIT_DATA);
    closeModal();
  }, [systemButtons, closeModal]);

  const deviceRequestOnSuccess = useCallback(
    (response: SystemBMSData) => {
      setDeviceData(response);
    },
    []
  );

  const statusRequestOnSuccess = useCallback(
    (response: SystemBMSStatus) => {
      setStatusData(response);
    },
    []
  );

  const initialApiRequest = useCallback(() => {
    deviceRequest({
      targetApi: () => dashboardApi.getData(),
      onSuccess: (response) =>
        deviceRequestOnSuccess(response as SystemBMSData),
    });
    statusRequest({
      targetApi: () => dashboardApi.getStatus(),
      onSuccess: (response) =>
        statusRequestOnSuccess(response as SystemBMSStatus),
    });
  }, [
    deviceRequest,
    deviceRequestOnSuccess,
    statusRequest,
    statusRequestOnSuccess,
  ]);

  const getNamePlateTitle = useCallback(
    ({
      buttonName,
      index,
      se802,
    }: {
      buttonName: string;
      index: number;
      se802: Se802;
    }) => {
      const targetButton = systemButtons.dashboardNamePlate[index];

      switch (buttonName) {
        case "charge_capacity_button":
          const chargeCapacity = se802.ahRtg;
          return `${targetButton.title} (${changeDecimalPoint(
            chargeCapacity,
            se802.ahRtg_SF
          )} Ah)`;
        case "energy_capacity_button":
          const energyCapacity = se802.whRtg;
          return `${targetButton.title} (${changeDecimalPoint(
            energyCapacity * 0.001,
            se802.whRtg_SF
          )} kWh)`;
        case "max_charge_rate_button":
          const maxChargeRate = se802.wchaRteMax;
          return `${targetButton.title} (${changeDecimalPoint(
            maxChargeRate * 0.001,
            se802.wchaDisChaMax_SF
          )} kW)`;
        case "max_discharge_rate_button":
          const maxDischargeRate = se802.wdisChaRteMax;
          return `${targetButton.title} (${changeDecimalPoint(
            maxDischargeRate * 0.001,
            se802.wchaDisChaMax_SF
          )} kW)`;
        case "max_soc_button":
          const maxSoC = se802.socMax;
          return `${targetButton.title} (${changeDecimalPoint(
            maxSoC,
            se802.soc_SF
          )} %)`;
        case "min_soc_button":
          const minSoC = se802.socMin;
          return `${targetButton.title} (${changeDecimalPoint(
            minSoC,
            se802.soc_SF
          )} %)`;
        case "max_reserve_soc_button":
          const maxRsvSoC = se802.socRsvMax;
          return `${targetButton.title} (${changeDecimalPoint(
            maxRsvSoC,
            se802.soc_SF
          )} %)`;
        case "min_reserve_soc_button":
          const minRsvSoC = se802.socRsvMin;
          return `${targetButton.title} (${changeDecimalPoint(
            minRsvSoC,
            se802.soc_SF
          )} %)`;
        default:
          return "-";
      }
    },
    [systemButtons]
  );

  const getHeartbeatTitle = useCallback(
    ({
      buttonName,
      index,
      se802,
    }: {
      buttonName: string;
      index: number;
      se802: Se802;
    }) => {
      const targetButton = systemButtons.dashboardHeartbeat[index];

      switch (buttonName) {
        case "heartbeat_button":
          return `${targetButton.title} (${se802.hb})`;
        case "pms_heartbeat_button":
          return `${targetButton.title} (${se802.ctrlHb})`;
        case "inverter_status_button":
          return `${targetButton.title} (${
            inverterStatusMap[
              se802.ctlInvState as keyof typeof inverterStatusMap
            ]
          })`;
      }
    },
    [systemButtons]
  );

  const handleClickControlButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      const { name } = e.target;
      const { color } = buttons.control.find((button) => button.name === name);
      const isEnabled = color === "success";
      const isOn = color === "warning";

      const getTargetApi = (type: ControlType, value: number) => () =>
        controlRequest({
          targetApi: () => dashboardApi.control({ type, value }),
        });

      const requestMap = {
        write_alarm_reset_button: getTargetApi("ALM_RST", 1),
        write_contactor_connect_button: getTargetApi(
          "SET_OP",
          isEnabled ? 2 : 1
        ),
        write_balancing_button: getTargetApi("SET_BAL", isEnabled ? 2 : 1),
        write_warning_light_button: getTargetApi("SET_WARN_LIGHT", isOn ? 1 : 2),
      };

      requestMap[name as keyof typeof requestMap]();
    }
  },[buttons.control, controlRequest]);

  const handleClickFirmwareUpdateButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      const { name } = e.target;

      const getTargetApi = (type: ControlType, value: number) => () =>
        firmwareUpdateRequest({
          targetApi: () => dashboardApi.control({ type, value }),
        });

      const requestMap = {
        firmware_update_button: getTargetApi("UPDATE_FIRMWARE", 1),
      };

      requestMap[name as keyof typeof requestMap]();
    }
  }, [firmwareUpdateRequest]);

  const handleOpenModal = useCallback((e: MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
    if (e.target instanceof HTMLButtonElement) {
      const { innerText } = e.target;
      if (isRemote) {
        openModal(
          <DefaultModal status="error" title={t("Unable to control due to remote condition.")} />
        );
        return;
      }

      const controlModeMap = {
        CONTROL: {
          modalTitle: `"${innerText}" ${t("Do you want to control?")}`,
          onSuccess: () => handleClickControlButton(e),
        },
        FIRMWARE_UPDATE: {
          modalTitle: `"${innerText}" ${t("Do you want to firmware update?")}`,
          onSuccess: () => handleClickFirmwareUpdateButton(e),
        },
      };

      openModal(
        <DefaultModal
          type="confirm"
          status="warning"
          title={controlModeMap[type].modalTitle}
          onSuccess={controlModeMap[type].onSuccess}
        />
      );
    }
  }, [handleClickControlButton, handleClickFirmwareUpdateButton, isRemote, openModal, t]);

  const handleClickButton = useCallback((e: React.MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
    openPopover({
      event: e,
      onSuccess: () => {
        handleOpenModal(e, type);
      },
    });
  }, [handleOpenModal, openPopover]);

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;

    subscribe(
      requestSocketPathname.SYSTEM_BMS_DATA,
      (body: SystemBMSData) => {
        setDeviceData(body);
      }
    );
    subscribe(
      requestSocketPathname.SYSTEM_BMS_STATUS,
      (body: SystemBMSStatus) => {
        setStatusData(body);
      }
    );
  }, [subscribe, isConnected]);

  useEffect(() => {
    if (deviceData && statusData) {
      if (!statusData.status) {
        init();
        return;
      }
      setSystemBMS({
        device: deviceData,
        status: statusData,
      });
      setButtons((prev) => {
        return {
          ...prev,
          namePlate: prev.namePlate.map((status, index) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
            }),
            title: getNamePlateTitle({
              buttonName: status.name,
              index: index,
              se802: deviceData.se802,
            }),
          })),
          control: prev.control.map((status) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
              systemBMSStatus: statusData,
            }),
          })),
          heartbeat: prev.heartbeat.map((status, index) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
              systemBMSStatus: statusData,
            }),
            title: getHeartbeatTitle({
              buttonName: status.name,
              index: index,
              se802: deviceData.se802,
            }),
          })),
          status: prev.status.map((status) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
              systemBMSStatus: statusData,
            }),
          })),
          statusDetail: prev.statusDetail.map((status) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
              systemBMSStatus: statusData,
            }),
          })),
          firmwareUpdate: prev.firmwareUpdate.map((status) => ({
            ...status,
            color: getDashboardStatusColor({
              buttonName: status.name,
              se802: deviceData.se802,
              systemBMSStatus: statusData,
            }),
          })),
        };
      });
    }
  }, [
    deviceData,
    statusData,
    systemButtons.dashboardHeartbeat,
    init,
    getNamePlateTitle,
    getHeartbeatTitle,
  ]);

  useInterval(
    () => {
      if (!deviceData || !statusData || !statusData?.status) return;
      setKwChartData((prev) => {
        const { label, data } = getKwChartData(deviceData.se802);
        if (label.length >= KW_CHART_MAX_DATA_LENGTH) {
          const copyLabel = [...prev.label];
          const copyData = [...prev.data];
          copyLabel.shift();
          copyData.shift();
          copyLabel.push(label);
          copyData.push(data);

          return {
            ...prev,
            label: copyLabel,
            data: copyData,
          };
        }
        return {
          ...prev,
          label: [...prev.label, label],
          data: [...prev.data, data],
        };
      });
    },
    INTERVAL_TIME,
    true
  );

  return {
    buttons,
    kwChartData,
    progressList,
    chartList,
    systemBMS,
    isControlButtonsLoading,
    isFirmwareUpdateButtonsLoading,
    isUsePackBMS,
    ROLE,
    handleClickButton,
  };
}

export default useDashboard;
