import instance from "apis/api";
import authenticationApi from "apis/authentication/api";
import { requestPathname } from "lib/consts/api";
import { routePathname } from "lib/consts/routePathname";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function withInterceptor(WrappedComponent: React.ComponentType) {
  return () => {
    const navigate = useNavigate();

    useEffect(() => {
      instance.interceptors.response.use(
        (res) => {
          return res;
        },
        async (err) => {
          const originalConfig = err.config;

          if (
            originalConfig.url !== requestPathname.SIGN_IN &&
            originalConfig.url !== requestPathname.SETTING &&
            originalConfig.url !== requestPathname.CHECK_AUTH &&
            err.response
          ) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;

              try {
                await authenticationApi.refreshToken();
                return instance(originalConfig);
              } catch (_error) {
                navigate(routePathname.SIGN_OUT, { replace: true });
                return Promise.reject(_error);
              }
            }
          }
          return Promise.reject(err);
        }
      );
    }, [navigate]);

    return <WrappedComponent/>;
  };
}

export default withInterceptor;
