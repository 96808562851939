// @mui material components
import TextField from "@mui/material/TextField";
import { styled, Theme } from "@mui/material/styles";
import {useRecoilState} from "recoil";
import {uiConfigState} from "../../store/uiConfig";

export default styled(TextField)(
  ({ theme, ownerState }: { theme?: Theme; ownerState: any }) => {
    const { palette, functions } = theme;
    const { error, success, disabled, signIn } = ownerState;

    const { error: colorError, success: colorSuccess, gradients } = palette;
    const { pxToRem } = functions;
    const [uiConfig] = useRecoilState(uiConfigState);
    const {
      darkMode,
      sidenavColor,
    } = uiConfig;
    const customStyles = () => ({
      colorScheme: darkMode ? "dark" : "light",
      "& .MuiInputLabel-root": {
        lineHeight: pxToRem(16),
        top: pxToRem(-5),
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        lineHeight: 1.5,
        top: 0,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: gradients[sidenavColor].main,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: gradients[sidenavColor].main,
        color: gradients[sidenavColor].main,
      },
    });

    // styles for the input with error={true}
    const errorStyles = () => ({
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44335' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44335' stroke='none'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,

      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline, &:after": {
          borderColor: colorError.main,
        },
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: gradients[sidenavColor].main,
      },
    });

    // styles for the input with success={true}
    const successStyles = () => ({
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%234CAF50' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: `right ${pxToRem(12)} center`,
      backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,

      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline, &:after": {
          borderColor: colorSuccess.main,
        },
      },

      "& .MuiInputLabel-root.Mui-focused": {
        color: gradients[sidenavColor].main,
      },
    });

    // styles for the input with error={true}
    const signInStyles = () => ({
      "& .MuiInputLabel-root": {
        lineHeight: pxToRem(16),
        top: pxToRem(-5),
        color: "white",
      },
      "& .MuiInputLabel-root.MuiInputLabel-shrink": {
        lineHeight: 1.5,
        top: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        color: "white",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        color: "white",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: "white",
      },
      "& input:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        "-webkit-transition": "background-color 9999s ease-out",
        "-webkit-box-shadow": "0 0 0px 1000px transparent inset !important",
        "-webkit-text-fill-color": "white",
        "&:hover": {
          "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
        },
        "&:focus": {
          "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
        },
        "&:active": {
          "-webkit-box-shadow": "0 0 0px 1000px transparent inset",
        },
      },
    });

    return {
      opacity: disabled ? 0.45 : 1,
      pointerEvents: disabled ? "none" : "auto",
      ...customStyles(),
      ...(error && errorStyles()),
      ...(success && successStyles()),
      ...(signIn && signInStyles()),
    };
  }
);
