import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DefaultCard from "layouts/components/cards/defaultCard";
import DataTable from "layouts/components/dataTable";
import useLog from "./hooks/useLog";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
import {ReactElement} from "react";
import MDTextField from "components/MDTextField";

function Log(): ReactElement {
  const {
    tableLogFileList,
    page,
    formData,
    isCreateLoading,
    isLogFileList,
    setPage,
    handleChangeDateTime,
    handleClickCreateLog,
  } = useLog();
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      <DefaultCard title={t("Log")} icon="create">
        <MDBox gap={2} display="flex" alignItems="center" mx={2} my={4}>
          <MDBox display="flex" alignItems="center" width={220} gap={1}>
            <MDTextField
              id="datetime-local"
              type="datetime-local"
              name="startDateTime"
              label={t("Start date time")}
              value={formData.startDateTime}
              onChange={handleChangeDateTime}
            />
          </MDBox>
          <MDTypography>~</MDTypography>
          <MDBox display="flex" alignItems="center" width={220} gap={1}>
            <MDTextField
              id="datetime-local"
              type="datetime-local"
              name="endDateTime"
              label={t("End date time")}
              value={formData.endDateTime}
              onChange={handleChangeDateTime}
            />
          </MDBox>
          <MDButton
            variant="gradient"
            color="primary"
            size="medium"
            disabled={
              isCreateLoading ||
              !formData.startDateTime ||
              !formData.endDateTime
            }
            onClick={handleClickCreateLog}
          >
            {t("Create")}
          </MDButton>
        </MDBox>
        <MDBox>
          {isLogFileList && (
            <DataTable
              table={tableLogFileList}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={true}
              currentPage={page}
              setPage={setPage}
            />
          )}
        </MDBox>
      </DefaultCard>
    </MDBox>
  );
}

export default Log;
