import typography from "assets/theme/base/typography";
import { Scriptable, ScriptableScaleContext } from "chart.js";
import { Align, Anchor } from "chartjs-plugin-datalabels/types/options";
import { Mode, ScaleLimits } from "chartjs-plugin-zoom/types/options";
import { useRef } from "react";

type ConfigParams = {
  labels: any;
  datasets: any;
  yMin: number;
  yMax: number;
  dataLabelsRotation: boolean;
};

function Config({
  labels,
  datasets,
  yMin,
  yMax,
  dataLabelsRotation,
}: ConfigParams) {
  const currentOptions = useRef({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: {
            dataset: { data: { [x: string]: any } };
            dataIndex: number;
          }) => {
            return context.dataset.data[context.dataIndex];
          },
        },
      },
      datalabels: {
        align: dataLabelsRotation ? ("left" as Align) : ("center" as Align),
        offset: dataLabelsRotation ? -13 : 4,
        anchor: "center" as Anchor,
        rotation: dataLabelsRotation ? 270 : 0,
        color: "white",
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "y" as Mode,
        },
        limits: {
          y: {
            min: "original",
            max: "original",
          } as ScaleLimits,
        },
      },
    },
    scales: {
      y: {
        suggestedMin: yMin,
        suggestedMax: yMax,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
          color: "#c1c4ce5c",
        },
        ticks: {
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 11,
            family: typography.fontFamily,
            style: "normal" as Scriptable<
              "inherit" | "initial" | "normal" | "italic" | "oblique",
              ScriptableScaleContext
            >,
            lineHeight: 2,
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          display: true,
          color: "#9ca2b7",
          padding: 10,
          font: {
            size: 11,
            family: typography.fontFamily,
            style: "normal" as Scriptable<
              "inherit" | "initial" | "normal" | "italic" | "oblique",
              ScriptableScaleContext
            >,
            lineHeight: 2,
          },
        },
      },
    },
  });
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: currentOptions.current,
  };
}

export default Config;
