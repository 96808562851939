import MDModal from "components/MDModal";
import { ModalContext } from "context/modal/modalContext";
import React, {PropsWithChildren, ReactElement, useState} from "react";

type Props = {};

function ModalProvider({ children }: PropsWithChildren<Props>) {
  const [modalContents, setModalContents] = useState<ReactElement>(null);
  const isModalOpen = Boolean(modalContents);

  const openModal = (modalEl: ReactElement) => setModalContents(modalEl);
  const closeModal = () => setModalContents(null);

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      <MDModal open={isModalOpen} onClose={closeModal}>
        {modalContents}
      </MDModal>
    </ModalContext.Provider>
  );
}

export default ModalProvider;
