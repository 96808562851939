import logApi from "apis/etc/log/api";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { requestSocketPathname } from "lib/consts/socket";
import useApiRequest from "lib/hooks/useApiRequest";
import useDebounce from "lib/hooks/useDebounce";
import useWebSocket from "lib/hooks/useWebSocket";
import { EventLogItem } from "lib/types/log";
import { getDate } from "lib/utils/common";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from "react";
import { CSVLink } from "react-csv";
import { useRecoilValue, useRecoilState } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { uiConfigState } from "store/uiConfig";
import useEventLogTableData, { TableRow } from "./useEventLogTableData";
import {userState} from "store/user";

function useEventLog() {
  const [user] = useRecoilState(userState);
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const { darkMode } = useRecoilValue(uiConfigState);
  const { openEventLog } = systemConfig;
  const { subscribe, isConnected } = useWebSocket();
  const { eventLogTableData } = useEventLogTableData();
  const [tableEventLogList, setTableEventLogList] = useState(eventLogTableData);
  const { request: logEventRequest, data: eventLogList } =
    useApiRequest<EventLogItem[]>();
  const [formData, setFormData] = useState({
    startDateTime: getDate(-1, { hour: 0, minute: 0 }),
    endDateTime: getDate(1, { hour: 0, minute: 0 }),
    typeFilter: "",
    descriptionFilter: "",
    statusFilter: "",
    page: 0,
  });
  const [csvData, setCsvData] = useState<TableRow[]>([]);
  const typeFilter = useDebounce(formData.typeFilter, 300);
  const descriptionFilter = useDebounce(formData.descriptionFilter, 300);
  const statusFilter = useDebounce(formData.statusFilter, 300);
  const csvRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);
  const lng =
    navigator.language === "ko" || navigator.language === "ko-KR" ? "ko" : "en";

  const getEventLog = useCallback(() => {
    if (user) {
      const second = "00";
      const startDateTime = `${formData.startDateTime
      .split("T")
      .join(" ")}:${second}.000`;
      const endDateTime = `${formData.endDateTime
      .split("T")
      .join(" ")}:${second}.000`;

      logEventRequest({
        targetApi: () => logApi.getLogEvent({startDateTime, endDateTime}),
      });
    }
  }, [user, formData.startDateTime, formData.endDateTime, logEventRequest]);

  useEffect(() => {
    getEventLog();

    if (!isConnected) return;
    subscribe(requestSocketPathname.EVENT_LOG_UPDATE, (body: boolean) => {
      if (body) {
        getEventLog();
      }
    });
  }, [subscribe, isConnected, getEventLog]);

  const setPage = useCallback((page: number) => {
    setFormData((prev) => {
      return {
        ...prev,
        page,
      };
    });
  }, []);

  const handleChangeFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setPage(0);
      setFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    [setPage]
  );

  useEffect(() => {
    if (eventLogList) {
      const eventLogColumns = eventLogTableData.columns.map((column) => {
        if (column.accessor === "date") return column;
        return {
          ...column,
          Header: (
            <>
              {column.Header}
              <MDBox>
                <MDInput
                  name={`${column.accessor}Filter`}
                  onChange={handleChangeFilter}
                />
              </MDBox>
            </>
          ),
        };
      });

      const eventLogRows = eventLogList
        .filter((eventLog) => {
          if (!typeFilter && !descriptionFilter && !statusFilter)
            return eventLog;
          return lng === "ko"
            ? eventLog.type.ko.toLowerCase().includes(typeFilter.toLowerCase())
              && eventLog.description.ko.toLowerCase().includes(descriptionFilter.toLowerCase())
              && eventLog.value.ko.toLowerCase().includes(statusFilter.toLowerCase())
            : eventLog.type.en.toLowerCase().includes(typeFilter.toLowerCase())
              && eventLog.description.en.toLowerCase().includes(descriptionFilter.toLowerCase())
              && eventLog.value.en.toLowerCase().includes(statusFilter.toLowerCase());
        })
        .map(({ type, dateTime, description, value, color }) => {
          return lng === "ko"
            ? {
                date: dateTime,
                type: { data: type.ko },
                description: { data: description.ko },
                status: { data: value.ko, color },
              }
            : {
                date: dateTime,
                type: { data: type.en },
                description: { data: description.en },
                status: { data: value.en, color },
              };
        });

      setTableEventLogList({ columns: eventLogColumns, rows: eventLogRows });
    }
  }, [
    eventLogTableData,
    eventLogList,
    typeFilter,
    descriptionFilter,
    statusFilter,
    handleChangeFilter,
    lng,
  ]);

  const handleCloseEventLog = () =>
    setSystemConfig((prev) => ({
      ...prev,
      openEventLog: false,
    }));

  const handleChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setPage(0);
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const createCsv = useCallback((pageItems: Record<string, any>[]) => {
    const csvData = pageItems.map((row) => {
      return {
        date: row.date.split("T").join(" "),
        type: row.type.data,
        description: row.description.data,
        status: row.status.data,
      };
    });
    setCsvData(csvData);
  }, []);

  const handleClickDownloadCSV = () => {
    csvRef.current.link.click();
  };

  return {
    openEventLog,
    darkMode,
    tableEventLogList,
    formData,
    csvData,
    csvRef,
    setPage,
    createCsv,
    handleCloseEventLog,
    handleChangeDate,
    handleClickDownloadCSV,
  };
}

export default useEventLog;
