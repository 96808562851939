import { Grid } from "@mui/material";
import DefaultCard from "layouts/components/cards/defaultCard";
import {
  CELL_TEMPERATURE_MAX,
  CELL_TEMPERATURE_MIN,
  CELL_VOLTAGE_MAX,
  CELL_VOLTAGE_MIN,
} from "lib/consts/common";
import { Se805 } from "lib/types/pack";
import isEqual from "lodash.isequal";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import MemoBarChart from "../memoBarChart";
import { useRecoilValue } from "recoil";
import { uiConfigState } from "store/uiConfig";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

type Props = {
  module: Se805;
  moduleIndex: number;
  moduleStatus: boolean;
  cellVMinCell: number;
  cellVMin: number;
  cellVMaxCell: number;
  cellVMax: number;
  cellVAvg: number;
  cellVColors: ("warning" | "dark" | "secondary")[];
  cellV: number[];
  cellVSF: number;
  cellTmpMinCell: number;
  cellTmpMin: number;
  cellTmpMaxCell: number;
  cellTmpMax: number;
  cellTmpAvg: number;
  cellTmpColors: ("error" | "primary")[];
  cellTmp: number[];
  tmpSF: number;
};

function ModuleCard({
  module,
  moduleIndex,
  moduleStatus,
  cellVMinCell,
  cellVMin,
  cellVMaxCell,
  cellVMax,
  cellVAvg,
  cellVColors,
  cellV,
  cellVSF,
  cellTmpMinCell,
  cellTmpMin,
  cellTmpMaxCell,
  cellTmpMax,
  cellTmpAvg,
  cellTmpColors,
  cellTmp,
  tmpSF,
}: Props) {
  const { t } = useTranslation("Translation");
  const { darkMode } = useRecoilValue(uiConfigState);
  return (
    <Grid key={moduleIndex} item xs={12} xl={6}>
      <DefaultCard
        icon="settings_input_component"
        title={`${t("Module")} #${String(moduleIndex + 1).padStart(2, "0")}`}
        titleColor={moduleStatus ? (darkMode ? "white" : "black") : "error"}
      >
        <Grid container alignItems="center">
          <Grid item xs={12} xl={8}>
            <MDBox pb={1} align="center">
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
              >
                {`${
                  "[" +
                  t("Min") +
                  "] " +
                  (moduleStatus
                    ? "C" +
                      String(cellVMinCell + 1).padStart(2, "0") +
                      " : " +
                      cellVMin.toFixed(Math.abs(cellVSF)) +
                      " V"
                    : "-")
                }`}
              </MDTypography>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
              >
                {`${
                  "[" +
                  t("Max") +
                  "] " +
                  (moduleStatus
                    ? "C" +
                      String(cellVMaxCell + 1).padStart(2, "0") +
                      " : " +
                      cellVMax.toFixed(Math.abs(cellVSF)) +
                      " V"
                    : "-")
                }`}
              </MDTypography>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {`${
                  "[" +
                  t("Avg") +
                  "] " +
                  (moduleStatus
                    ? "          " + cellVAvg.toFixed(Math.abs(cellVSF)) + " V"
                    : "-")
                }`}
              </MDTypography>
            </MDBox>
            <MemoBarChart
              description={`${t("Cell voltage")} (V)`}
              chart={{
                labels: Array.from({
                  length: module.repeatBlockList.length,
                }).map((_, index) => String(index + 1)),
                datasets: [
                  {
                    label: "",
                    color: cellVColors,
                    data: cellV,
                  },
                ],
                yMin: CELL_VOLTAGE_MIN,
                yMax: CELL_VOLTAGE_MAX,
                dataLabelsRotation: true,
              }}
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <MDBox pb={1} align="center">
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
                style={{ whiteSpace: "pre-wrap"}}
              >
                {`${
                  "[" +
                  t("Min") +
                  "] " +
                  (moduleStatus
                    ? "T" +
                      String(Math.floor(cellTmpMinCell / 2) + 1).padStart(2, "0") +
                      ((cellTmpMin < 0 && (cellTmpMin.toFixed(Math.abs(tmpSF)).length < 6)) ? " :    " :
                      (cellTmpMin < 0 && (cellTmpMin.toFixed(Math.abs(tmpSF)).length < 7)) ? " :  " :
                      (cellTmpMin.toFixed(Math.abs(tmpSF)).length < 5) ? " :      " :
                      (cellTmpMin.toFixed(Math.abs(tmpSF)).length < 6) ? " :    " : " : ") +
                      cellTmpMin.toFixed(Math.abs(tmpSF)) +
                      " ºC"
                    : "-")
                }`}
              </MDTypography>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
                style={{ whiteSpace: "pre-wrap"}}
              >
                {`${
                  "[" +
                  t("Max") +
                  "] " +
                  (moduleStatus
                    ? "T" +
                      String(Math.floor(cellTmpMaxCell / 2) + 1).padStart(2, "0") +
                      ((cellTmpMax < 0 && (cellTmpMax.toFixed(Math.abs(tmpSF)).length < 6)) ? " :    " :
                      (cellTmpMax < 0 && (cellTmpMax.toFixed(Math.abs(tmpSF)).length < 7)) ? " :  " :
                      (cellTmpMax.toFixed(Math.abs(tmpSF)).length < 5) ? " :      " :
                      (cellTmpMax.toFixed(Math.abs(tmpSF)).length < 6) ? " :    " : " : ") +
                      cellTmpMax.toFixed(Math.abs(tmpSF)) +
                      " ºC"
                    : "-")
                }`}
              </MDTypography>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                color={moduleStatus ? (darkMode ? "white" : "black") : "error"}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {`${
                  "[" +
                  t("Avg") +
                  "] " +
                  (moduleStatus
                    ? ((cellTmpAvg < 0 && (cellTmpAvg.toFixed(Math.abs(tmpSF)).length < 6)) ? "             " :
                      (cellTmpAvg < 0 && (cellTmpAvg.toFixed(Math.abs(tmpSF)).length < 7)) ? "           " :
                      (cellTmpAvg.toFixed(Math.abs(tmpSF)).length < 5) ? "               " :
                      (cellTmpAvg.toFixed(Math.abs(tmpSF)).length < 6) ? "            " : "          ") +
                    cellTmpAvg.toFixed(Math.abs(tmpSF)) + " ºC"
                    : "-")
                }`}
              </MDTypography>
            </MDBox>
            <MemoBarChart
              description={`${t("Module temperature")} (ºC)`}
              chart={{
                labels: Array.from({
                  length: module.repeatBlockList.length / 2,
                }).map((_, index) => String(index + 1)),
                datasets: [
                  {
                    label: "",
                    color: cellTmpColors,
                    data: cellTmp,
                  },
                ],
                yMin: CELL_TEMPERATURE_MIN,
                yMax: CELL_TEMPERATURE_MAX,
                dataLabelsRotation: true,
              }}
            />
          </Grid>
        </Grid>
      </DefaultCard>
    </Grid>
  );
}

export default memo(ModuleCard, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
