import overviewApi, {
  BalancingSettingRange,
  BalancingSettingNoBalancing,
  BalancingSettingReservation,
} from "apis/devices/packBMS/overview/api";
import { ControlType } from "lib/types/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import useApiRequest from "lib/hooks/useApiRequest";
import useSystemButtons, {
  SystemButton,
  SystemButtons,
} from "lib/hooks/useSystemButtons";
import {
  Se1,
  Se805,
  Se805FixedBlock,
  PackBMSData,
  PackBMSStatus, 
  Se804FixedBlock,
} from "lib/types/pack";
import { getDate, getDateTime, getUserRole } from "lib/utils/common";
import {
  getPackBMSStatusColor,
  getPackChartItems,
  getPackProgressItems,
} from "lib/utils/pack";
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
  ChangeEvent,
} from "react";
import { useTranslation } from "react-i18next";
import useWebSocket from "lib/hooks/useWebSocket";
import { requestSocketPathname } from "lib/consts/socket";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { userState } from "store/user";
import isEqual from "lodash.isequal";
import { SelectChangeEvent } from "@mui/material";
import useReservationTableData, { TableData } from "./useReservationTableData";
import PopoverContext from "context/popover/popoverContext";
import packBMS from "lib/consts/pack";

type Props = {
  packBMSNo: number;
};

type BMS = {
  device: PackBMSData;
  status: PackBMSStatus;
};

type Buttons = {
  control: SystemButton[];
  balancingMode?: SystemButton[];
  balancingCondition?: SystemButton[];
  balancingRange?: SystemButton[];
  status: SystemButton[];
  contactorStatus: SystemButton[];
  statusDetail: SystemButton[];
};

type ControlModeType =
  | "CONTROL"
  | "BALANCING_MODE"
  | "BALANCING_CONDITION"
  | "BALANCING_SETTING";

const BMS_INIT_DATA: BMS = {
  device: null,
  status: null,
};

const MODULE_FIXED_BLOCK_INIT_DATA: Se805FixedBlock = {
  cellTmpAvg: 0,
  cellTmpMax: 0,
  cellTmpMaxCell: 0,
  cellTmpMin: 0,
  cellTmpMinCell: 0,
  cellVAvg: 0,
  cellVMax: 0,
  cellVMaxCell: 0,
  cellVMin: 0,
  cellVMinCell: 0,
  cellV_SF: 0,
  dod: 0,
  dod_SF: 0,
  length: 0,
  modIdx: 0,
  modelID: 0,
  ncell: 0,
  ncellBal: 0,
  ncyc: 0,
  sn: "",
  soc: 0,
  soc_SF: 0,
  soh: 0,
  soh_SF: 0,
  strIdx: 0,
  tmp_SF: 0,
  v: 0,
  v_SF: 0,
};

const INIT_BUTTONS = (systemButtons: SystemButtons) => ({
  control: systemButtons.packControl,
  balancingMode: systemButtons.packBalancingMode,
  balancingCondition: systemButtons.packBalancingCondition,
  status: systemButtons.packStatus,
  contactorStatus: systemButtons.packContactorStatus,
  statusDetail: systemButtons.statusDetail,
});

const INIT_SELECTS: BalancingSettingRange = {
  criteria: packBMS.balancingCriteria.NONE,
  threshold: -1,
  tolerance: -1,
  activePeriod: -1,
  inactivePeriod: -1,
  ratio: -1,
};

const INIT_NO_BALANCING: BalancingSettingNoBalancing = {
  useNoBalancing: false,
  noBalancingMinCurrent: -25,
  noBalancingMaxCurrent: -15,
};

const INIT_RESERVATION: BalancingSettingReservation = {
  useReservation: false,
  numberOfReservation: 1,
  balancingReservationList: [],
};

function useOverview({ packBMSNo }: Props) {
  const user = useRecoilValue(userState);
  const { runtime } = useRecoilValue(systemConfigState);
  const { subscribe, isConnected } = useWebSocket();
  const { systemButtons } = useSystemButtons();
  const [BMS, setBMS] = useState<BMS>(BMS_INIT_DATA);
  const [buttons, setButtons] = useState<Buttons>(
    INIT_BUTTONS(systemButtons)
  );
  const [balancingSettingRangeFormData, setBalancingSettingRangeFormData] =
    useState<BalancingSettingRange>(INIT_SELECTS);
  const [
    balancingSettingNoBalancingFormData,
    setBalancingSettingNoBalancingFormData,
  ] = useState<BalancingSettingNoBalancing>(INIT_NO_BALANCING);
  const [
    balancingSettingReservationFormData,
    setBalancingSettingReservationFormData,
  ] = useState<BalancingSettingReservation>(INIT_RESERVATION);
  const { reservationTableData } = useReservationTableData();
  const [balancingReservationTableData, setBalancingReservationTableData] =
    useState<TableData>(reservationTableData);
  const [isInitialized, setIsInitialized] = useState(false);
  const [deviceData, setDeviceData] = useState<PackBMSData>();
  const [statusData, setStatusData] = useState<PackBMSStatus>();
  const { request: statusRequest } = useApiRequest<PackBMSStatus>();
  const { request: deviceRequest } = useApiRequest<PackBMSData>();
  const { request: controlRequest, isLoading: isControlButtonsLoading } =
    useApiRequest();
  const { openPopover } = useContext(PopoverContext);
  const { openModal, closeModal } = useContext(ModalContext);
  const { t } = useTranslation("Translation");
  const se804FixedBlock = BMS.device?.se804.fixedBlock;
  const se804RepeatBlockList = BMS.device?.se804.repeatBlockList;
  const se805List = BMS.device?.se805List;
  const balancing = BMS.device?.balancing;
  const progressList = useMemo(
    () =>
      getPackProgressItems({
        se804FixedBlock,
        moduleLength: se804FixedBlock?.nmod,
        cellLength: se805List ? Math.max(...se805List.map(({ fixedBlock }) => {
          return fixedBlock.ncell;
        })) : 0,
        t,
      }),
    [se804FixedBlock, se805List, t]
  );

  const chartList = useMemo(
    () => getPackChartItems({
      se804FixedBlock: se804FixedBlock,
      t: t
    }),
    [se804FixedBlock, t]
  );
  const DISABLED_MODULES: Se805[] = useMemo(() => {
    return Array.from({
      length: se804FixedBlock?.nmod,
    }).map(() => {
      return {
        fixedBlock: MODULE_FIXED_BLOCK_INIT_DATA,
        repeatBlockList: Array.from({
          length: se805List ? Math.max(...se805List.map(({ fixedBlock }) => {
            return fixedBlock.ncell;
          })) : 0,
        }).map(() => {
          return { cellV: 0, cellTmp: 0, cellSt: 0 };
        }),
      };
    });
  }, [se804FixedBlock?.nmod, se805List]);
  const ROLE = getUserRole(user);
  const isRemote = !runtime.isLocal || runtime.isLocalRemoteProcessing;
  const isDisabledBalancingSelectSetting = isEqual(
    INIT_SELECTS,
    balancingSettingRangeFormData
  );

  const init = useCallback(() => {
    setBMS(BMS_INIT_DATA);
    setButtons(INIT_BUTTONS(systemButtons));
    setBalancingSettingRangeFormData(INIT_SELECTS);
    setBalancingSettingNoBalancingFormData(INIT_NO_BALANCING);
    setBalancingSettingReservationFormData(INIT_RESERVATION);
    closeModal();
  }, [systemButtons, closeModal]);

  const deviceRequestOnSuccess = useCallback(
    (response: PackBMSData) => {
      setDeviceData(response);
    },
    []
  );

  const statusRequestOnSuccess = useCallback(
    (response: PackBMSStatus) => {
      setStatusData(response);
    },
    []
  );

  const initialApiRequest = useCallback(() => {
    deviceRequest({
      targetApi: () => overviewApi.getData(packBMSNo),
      onSuccess: (response) => deviceRequestOnSuccess(response as PackBMSData),
    });
    statusRequest({
      targetApi: () => overviewApi.getStatus(packBMSNo),
      onSuccess: (response) =>
        statusRequestOnSuccess(response as PackBMSStatus),
    });
  }, [
    packBMSNo,
    deviceRequest,
    statusRequest,
    deviceRequestOnSuccess,
    statusRequestOnSuccess,
  ]);

  const getPackBMSStatusTitle = useCallback(
    ({
       buttonName,
       index,
       se1,
     }: {
      buttonName: string;
      index: number;
      se1: Se1;
    }) => {
      const targetButton = systemButtons.packStatus[index];

      switch (buttonName) {
        case "version_button":
          return `${targetButton.title} (${se1.vr})`;
        default:
          return `${targetButton.title}`;
      }
    },
    [systemButtons]
  );

  const getPackBMSContactorStatusTitle = useCallback(
    ({
       buttonName,
       index,
       se804FixedBlock,
     }: {
      buttonName: string;
      index: number;
      se804FixedBlock: Se804FixedBlock;
    }) => {
      const targetButton = systemButtons.packContactorStatus[index];

      switch (buttonName) {
        case "causes_of_connection_failure_button":
          if (se804FixedBlock.conFail === packBMS.se804ConFail.NO_FAILURE) {
            return `${targetButton.title} (${t("No failure")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.BUTTON_PUSHED) {
            return `${targetButton.title} (${t("Emergency")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.STR_GROUND_FAULT) {
            return `${targetButton.title} (${t("Pack ground fault")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.OUTSIDE_VOLTAGE_RANGE) {
            return `${targetButton.title} (${t("Outside Voltage Range fault")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.STRING_NOT_ENABLED) {
            return `${targetButton.title} (${t("Pack disable")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.FUSE_OPEN) {
            return `${targetButton.title} (${t("Fuse open")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.CONTACTOR_FAILURE) {
            return `${targetButton.title} (${t("Pack contactor error")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.PRE_CHARGE_FAILURE) {
            return `${targetButton.title} (${t("Pre-charge failure")})`;
          } else if (se804FixedBlock.conFail === packBMS.se804ConFail.STRING_FAULT) {
            return `${targetButton.title} (${t("Pack fault")})`;
          } else {
              return `${targetButton.title} (${t("No failure")})`;
          }
        default:
          return `${targetButton.title}`;
      }
    },
    [systemButtons, t]
  );

  const handleClickControlButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        const { name } = e.target;
        const { color } = buttons.control.find(
          (button) => button.name === name
        );
        const isEnabled = color === "success";

        const getTargetApi = (type: ControlType, value: number) => () =>
          controlRequest({
            targetApi: () => overviewApi.control(packBMSNo, { type, value }),
          });

        const requestMap = {
          write_enable_button: getTargetApi(
            "SET_ENA",
            isEnabled ? 2 : 1
          ),
          write_contactor_connect_button: getTargetApi(
            "SET_CON",
            isEnabled ? 2 : 1
          ),
          write_balancing_button: getTargetApi("SET_BAL", isEnabled ? 2 : 1),
        };

        requestMap[name as keyof typeof requestMap]();
      }
    },
    [buttons.control, controlRequest, packBMSNo]
  );

  const handleClickBalancingModeButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        const { name } = e.target;

        const getTargetApi = (type: ControlType, value: number) => () =>
          controlRequest({
            targetApi: () => overviewApi.control(packBMSNo, { type, value }),
          });

        const requestMap = {
          write_normal_button: getTargetApi("SET_BAL_MODE", 0),
          write_force_button: getTargetApi("SET_BAL_MODE", 1),
          write_even_odd_button: getTargetApi("SET_BAL_MODE", 2),
          write_select_button: getTargetApi("SET_BAL_MODE", 3),
          write_charge_snapshot_button: getTargetApi("SET_BAL_MODE", 4),
          write_discharge_snapshot_button: getTargetApi("SET_BAL_MODE", 5),
        };

        requestMap[name as keyof typeof requestMap]();
      }
    },
    [controlRequest, packBMSNo]
  );

  const handleClickBalancingConditionButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        const { name } = e.target;
        const chargeCondition =
          buttons.balancingCondition.find(
            (button) => button.name === "write_charge_button"
          ).color === "success"
            ? 0x00000001
            : 0x00000000;
        const dischargeCondition =
          buttons.balancingCondition.find(
            (button) => button.name === "write_discharge_button"
          ).color === "success"
            ? 0x00000002
            : 0x00000000;
        const idleCondition =
          buttons.balancingCondition.find(
            (button) => button.name === "write_idle_button"
          ).color === "success"
            ? 0x00000004
            : 0x00000000;
        const condition = chargeCondition | dischargeCondition | idleCondition;

        const getTargetApi = (type: ControlType, value: number) => () =>
          controlRequest({
            targetApi: () => overviewApi.control(packBMSNo, { type, value }),
          });

        const requestMap = {
          write_charge_button: getTargetApi(
            "SET_BAL_CONDITION",
            condition & 0x00000001
              ? condition & ~0x00000001
              : condition | 0x00000001
          ),
          write_discharge_button: getTargetApi(
            "SET_BAL_CONDITION",
            condition & 0x00000002
              ? condition & ~0x00000002
              : condition | 0x00000002
          ),
          write_idle_button: getTargetApi(
            "SET_BAL_CONDITION",
            condition & 0x00000004
              ? condition & ~0x00000004
              : condition | 0x00000004
          ),
        };

        requestMap[name as keyof typeof requestMap]();
      }
    },
    [buttons.balancingCondition, controlRequest, packBMSNo]
  );


  const handleClickSetBalancingSettingButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.target instanceof HTMLButtonElement) {
        controlRequest({
          targetApi: () =>
            overviewApi.control(packBMSNo, {
              type: "SET_BAL_SETTING",
              value: 0,
              balancingSetting: {
                criteria: balancingSettingRangeFormData.criteria,
                threshold: balancingSettingRangeFormData.threshold,
                tolerance: balancingSettingRangeFormData.tolerance,
                activePeriod: balancingSettingRangeFormData.activePeriod,
                inactivePeriod: balancingSettingRangeFormData.inactivePeriod,
                ratio: balancingSettingRangeFormData.ratio,
                useNoBalancing: balancingSettingNoBalancingFormData.useNoBalancing,
                noBalancingMinCurrent: balancingSettingNoBalancingFormData.noBalancingMinCurrent,
                noBalancingMaxCurrent: balancingSettingNoBalancingFormData.noBalancingMaxCurrent,
                useReservation:
                balancingSettingReservationFormData.useReservation,
                numberOfReservation:
                balancingSettingReservationFormData.numberOfReservation,
                balancingReservationList:
                balancingSettingReservationFormData.balancingReservationList,
              },
            }),
        });
      }
    },
    [
      balancingSettingRangeFormData.criteria,
      balancingSettingRangeFormData.threshold,
      balancingSettingRangeFormData.tolerance,
      balancingSettingRangeFormData.activePeriod,
      balancingSettingRangeFormData.inactivePeriod,
      balancingSettingRangeFormData.ratio,
      balancingSettingNoBalancingFormData.useNoBalancing,
      balancingSettingNoBalancingFormData.noBalancingMinCurrent,
      balancingSettingNoBalancingFormData.noBalancingMaxCurrent,
      balancingSettingReservationFormData.useReservation,
      balancingSettingReservationFormData.numberOfReservation,
      balancingSettingReservationFormData.balancingReservationList,
      controlRequest,
      packBMSNo,
    ]
  );

  const handleSelectBalancing = useCallback(
    ({ target: { name, value } }: SelectChangeEvent<number>) => {
      setBalancingSettingRangeFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    },
    []
  );

  const handleChangeNoBalancingCheckEnable = useCallback(
    ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
      setBalancingSettingNoBalancingFormData((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    },
    []
  );

  const handleChangeNoBalancingInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setBalancingSettingNoBalancingFormData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      return;
    },
    []
  );

  const handleChangeReservationCheckEnable = useCallback(
    ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
      setBalancingSettingReservationFormData((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    },
    []
  );

  const setBalancingSettingReservationTableList = useCallback(
    (value: number) => {
      const balancingReservationList = Array.from({ length: value }).map(
        (_, reservationIndex) => {
          const now = getDateTime();
          const startDateTime = getDate(0, {
            hour: now.getHours(),
            minute: now.getMinutes(),
          });
          const endDateTime = getDate(1, {
            hour: now.getHours(),
            minute: now.getMinutes(),
          });
          if (
            balancingSettingReservationFormData.numberOfReservation >
            reservationIndex
          ) {
            return balancingSettingReservationFormData.balancingReservationList[
              reservationIndex
            ];
          }
          return {
            startDateTime: `${startDateTime.split("T").join(" ")}:00.000`,
            endDateTime: `${endDateTime.split("T").join(" ")}:00.000`,
          };
        }
      );
      setBalancingSettingReservationFormData((prev) => {
        return {
          ...prev,
          balancingReservationList: balancingReservationList,
        };
      });
    },
    [
      balancingSettingReservationFormData.numberOfReservation,
      balancingSettingReservationFormData.balancingReservationList,
    ]
  );

  const handleSelectNumberOfReservations = useCallback(
    ({ target: { name, value } }: SelectChangeEvent<number>) => {
      const isNumberOfReservation = name === "numberOfReservation";
      isNumberOfReservation &&
        setBalancingSettingReservationTableList(Number(value));
      setBalancingSettingReservationFormData((prev) => {
        return {
          ...prev,
          [name]: Number(value),
        };
      });
    },
    [setBalancingSettingReservationTableList]
  );

  const handleChangeTableDateTime = useCallback(
    (index: number, value: string, name: string) => {
      const dateTime = `${value.split("T").join(" ")}:00.000`;
      const balancingReservationList =
        balancingSettingReservationFormData.balancingReservationList.map(
          (listItem, listIndex) => {
            if (index === listIndex) {
              return {
                ...listItem,
                [name]: dateTime,
              };
            }
            return listItem;
          }
        );
      setBalancingSettingReservationFormData((prev) => {
        return {
          ...prev,
          balancingReservationList: balancingReservationList,
        };
      });
    },
    [balancingSettingReservationFormData]
  );


  const handleOpenModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
      if (e.target instanceof HTMLButtonElement) {
        const { innerText } = e.target;
        if (isRemote) {
          openModal(
            <DefaultModal
              status="error"
              title={t("Unable to control due to remote condition.")}
            />
          );
          return;
        }

        const controlModeMap = {
          CONTROL: {
            modalTitle: `"${innerText}" ${t("Do you want to control?")}`,
            onSuccess: () => handleClickControlButton(e),
          },
          BALANCING_MODE: {
            modalTitle: `"${innerText}" ${t("Do you want to balancing mode control?")}`,
            onSuccess: () => handleClickBalancingModeButton(e),
          },
          BALANCING_CONDITION: {
            modalTitle: `"${innerText}" ${t("Do you want to balancing condition control?")}`,
            onSuccess: () => handleClickBalancingConditionButton(e),
          },
          BALANCING_SETTING: {
            modalTitle: `${t("Do you want to balancing setting control?")}`,
            onSuccess: () => handleClickSetBalancingSettingButton(e),
          },
        };

        openModal(
          <DefaultModal
            type="confirm"
            status="warning"
            title={controlModeMap[type].modalTitle}
            onSuccess={controlModeMap[type].onSuccess}
          />
        );
      }
    },
    [
      handleClickControlButton,
      handleClickBalancingModeButton,
      handleClickBalancingConditionButton,
      handleClickSetBalancingSettingButton,
      isRemote,
      t,
      openModal,
    ]
  );

  const handleClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, type: ControlModeType) => {
      openPopover({
        event: e,
        onSuccess: () => {
          handleOpenModal(e, type);
        },
      });
    },
    [handleOpenModal, openPopover]
  );

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;

    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_DATA(packBMSNo),
      (body: PackBMSData) => {
        setDeviceData(body);
      }
    );
    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_STATUS(packBMSNo),
      (body: PackBMSStatus) => {
        setStatusData(body);
      }
    );
  }, [subscribe, isConnected, packBMSNo]);

  useEffect(() => {
    if (deviceData && statusData) {
      if (!statusData.status) {
        init();
        return;
      }
      const se804FixedBlock = deviceData.se804.fixedBlock;
      const balancingSetting = deviceData.balancing?.balancingSetting;
      setBMS((prev) => {
        return {
          ...prev,
          device: deviceData,
        };
      });
      setButtons((prev) => {
        return {
          ...prev,
          control: prev.control.map((status) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
          })),
          balancingMode: prev.balancingMode?.map((status) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
          })),
          balancingCondition: prev.balancingCondition?.map((status) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
          })),
          status: prev.status.map((status, index) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
            title: getPackBMSStatusTitle({
              buttonName: status.name,
              index: index,
              se1: deviceData.se1,
            }),
          })),
          contactorStatus: prev.contactorStatus.map((status, index) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
            title: getPackBMSContactorStatusTitle({
              buttonName: status.name,
              index: index,
              se804FixedBlock: deviceData.se804.fixedBlock,
            }),
          })),
          statusDetail: prev.statusDetail.map((status) => ({
            ...status,
            color: getPackBMSStatusColor({
              buttonName: status.name,
              se804FixedBlock: se804FixedBlock,
              balancingSetting: balancingSetting
            }),
          })),
        };
      });
    }

    setBMS((prev) => {
      return {
        ...prev,
        status: statusData,
      };
    });
  }, [deviceData, statusData, init, getPackBMSStatusTitle, getPackBMSContactorStatusTitle]);


  useEffect(() => {
    if (!deviceData || !statusData) return;
    if (!statusData.status) {
      setIsInitialized(false);
      init();
      return;
    }
    if (isInitialized) return;
    const balancingSetting = deviceData.balancing?.balancingSetting;
    setBalancingSettingRangeFormData({
      criteria: balancingSetting?.criteria,
      threshold: balancingSetting?.threshold,
      tolerance: balancingSetting?.tolerance,
      activePeriod: balancingSetting?.activePeriod,
      inactivePeriod: balancingSetting?.inactivePeriod,
      ratio: balancingSetting?.ratio,
    });
    setBalancingSettingNoBalancingFormData({
      useNoBalancing: balancingSetting?.useNoBalancing,
      noBalancingMinCurrent: balancingSetting?.noBalancingMinCurrent,
      noBalancingMaxCurrent: balancingSetting?.noBalancingMaxCurrent,
    });
    setBalancingSettingReservationFormData({
      useReservation: balancingSetting?.useReservation,
      numberOfReservation: balancingSetting?.numberOfReservation,
      balancingReservationList: balancingSetting?.balancingReservationList,
    });

    const balancingReservationDataRows =
      balancingSetting?.balancingReservationList.map(
        (reservationItem, reservationIndex) => {
          const { startDateTime, endDateTime } = reservationItem;
          return {
            no: [reservationIndex, `#${reservationIndex + 1}`],
            startDateTime: [
              reservationIndex,
              startDateTime,
              handleChangeTableDateTime,
            ],
            endDateTime: [
              reservationIndex,
              endDateTime,
              handleChangeTableDateTime,
            ],
          };
        }
      );

    setBalancingReservationTableData((prev: any) => {
      return {
        ...prev,
        rows: balancingReservationDataRows,
      };
    });
    setIsInitialized(true);
  }, [isInitialized, deviceData, statusData, init, handleChangeTableDateTime]);

  useEffect(() => {
    if (!deviceData || !statusData) return;
    const balancingReservationDataRows =
      balancingSettingReservationFormData.balancingReservationList.map(
        (reservationItem, reservationIndex) => {
          const { startDateTime, endDateTime } = reservationItem;
          return {
            no: [reservationIndex, `#${reservationIndex + 1}`],
            startDateTime: [
              reservationIndex,
              startDateTime,
              handleChangeTableDateTime,
            ],
            endDateTime: [
              reservationIndex,
              endDateTime,
              handleChangeTableDateTime,
            ],
          };
        }
      );

    setBalancingReservationTableData((prev: any) => {
      return {
        ...prev,
        rows: balancingReservationDataRows,
      };
    });
  }, [
    deviceData,
    statusData,
    init,
    handleChangeTableDateTime,
    balancingSettingReservationFormData.balancingReservationList,
  ]);

  return {
    BMS,
    se804RepeatBlockList,
    se805List,
    balancing,
    buttons,
    progressList,
    chartList,
    isControlButtonsLoading,
    ROLE,
    DISABLED_MODULES,
    balancingSettingRangeFormData,
    balancingSettingNoBalancingFormData,
    balancingSettingReservationFormData,
    balancingReservationTableData,
    isDisabledBalancingSelectSetting,
    handleSelectBalancing,
    handleChangeNoBalancingCheckEnable,
    handleChangeNoBalancingInputNumber,
    handleChangeReservationCheckEnable,
    handleSelectNumberOfReservations,
    handleClickButton,
  };
}

export default useOverview;
