import useWebSocket from "lib/hooks/useWebSocket";
import { QCData, QCFileData, QCType } from "lib/types/qc";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  MouseEvent,
} from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import useQCLogTableData from "./useQCLogTableData";
import PopoverContext from "context/popover/popoverContext";
import { ModalContext } from "context/modal/modalContext";
import { SystemBMSStatus } from "lib/types/dashboard";
import useApiRequest from "lib/hooks/useApiRequest";
import dashboardApi from "apis/dashboard/api";
import qcApi from "apis/qc/api";
import { requestSocketPathname } from "lib/consts/socket";
import { ButtonTargetPayload } from "../components/controlButton";
import DefaultModal from "layouts/components/modal/default";

type Props = {
  qcType: QCType;
};

function useQC({ qcType }: Props) {
  const { t } = useTranslation("Translation");
  const { setting } = useRecoilValue(systemConfigState);
  const { subscribe, isConnected } = useWebSocket();
  const { qcLogTableData } = useQCLogTableData();
  const { openPopover } = useContext(PopoverContext);
  const { openModal } = useContext(ModalContext);
  const [qcData, setQcData] = useState<QCData>(null);
  const [qcFileData, setQcFileData] = useState<QCFileData>(null);
  const [statusData, setStatusData] = useState<SystemBMSStatus>(null);
  const [page, setPage] = useState(0);
  const [tableLogData, setTableLogData] = useState(qcLogTableData);
  const { request: statusRequest } = useApiRequest<SystemBMSStatus>();
  const { request: qcRequest } = useApiRequest<QCData>();
  const { request: qcFileRequest, isLoading: isQCFileLoading } =
    useApiRequest<QCFileData>();
  const { request: qcControl, isLoading: isQCControlLoading } = useApiRequest();
  const { request: qcDownload, isLoading: isQCDownloadLoading } =
    useApiRequest();
  const { request: qcDelete, isLoading: isQCDeleteLoading } = useApiRequest();
  const isUsePackBMS = setting.usePackBMS;
  const isActiveSystemBMSButtons = Boolean(statusData) && Boolean(qcData);

  const init = useCallback(() => {
    setStatusData(null);
    setQcData(null);
  }, []);

  const statusRequestOnSuccess = useCallback(
    (response: SystemBMSStatus) => {
      setStatusData(response);
    },
    []
  );

  const qcDataRequestOnSuccess = useCallback(
    (response: QCData) => {
      setQcData(response);
    },
    []
  );

  const qcFileDataRequestOnSuccess = useCallback((response: QCFileData) => {
    setQcFileData(response);
  }, []);

  const initialApiRequest = useCallback(() => {
    statusRequest({
      targetApi: () => dashboardApi.getStatus(),
      onSuccess: (response) =>
        statusRequestOnSuccess(response as SystemBMSStatus),
    });
    qcRequest({
      targetApi: () => qcApi.getQCData(qcType),
      onSuccess: (response) => qcDataRequestOnSuccess(response as QCData),
    });
    qcFileRequest({
      targetApi: () => qcApi.getQCFileData(qcType),
      onSuccess: (response) =>
        qcFileDataRequestOnSuccess(response as QCFileData),
    });
  }, [
    qcType,
    statusRequest,
    statusRequestOnSuccess,
    qcRequest,
    qcDataRequestOnSuccess,
    qcFileRequest,
    qcFileDataRequestOnSuccess,
  ]);

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;
    subscribe(
      requestSocketPathname.SYSTEM_BMS_STATUS,
      (body: SystemBMSStatus) => {
        setStatusData(body);
      }
    );
    subscribe(requestSocketPathname.QC_DATA(qcType), (body: QCData) => {
      setQcData(body);
    });
    subscribe(
      requestSocketPathname.QC_FILE_DATA(qcType),
      (body: QCFileData) => {
        setQcFileData(body);
      }
    );
  }, [qcType, subscribe, isConnected]);

  useEffect(() => {
    if (!statusData) return;
    if (!statusData.status) {
      init();
      return;
    }
  }, [statusData, init]);

  const handleClickDelete = useCallback(
    (fileFullPath: string) => {
      qcDownload({
        targetApi: () => qcApi.deleteQCLog(fileFullPath),
      });
    },
    [qcDownload]
  );

  const handleClickDownload = useCallback(
    async (fileFullPath: string, fileName: string) => {
      qcDelete({
        targetApi: () => qcApi.downloadQCLog(fileFullPath),
        onSuccess: (data) => {
          const href = URL.createObjectURL(data as Blob | MediaSource);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();

          // clean
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        },
      });
    },
    [qcDelete]
  );

  const handleClickButton = useCallback(
    (e: MouseEvent<HTMLButtonElement>, target?: ButtonTargetPayload) => {
      const targetName = e.currentTarget.name;
      const buttonInnerTextName = e.currentTarget.innerText;

      openPopover({
        type: "PASSWORD",
        event: e,
        onSuccess: () => {
          openModal(
            <DefaultModal
              status="warning"
              type="confirm"
              maxWidth={420}
              title={`"${buttonInnerTextName}" ${t("Do you want to control?")}`}
              onSuccess={() => {
                const data: QCData = {
                  ...qcData,
                  qcPackBMSList: qcData.qcPackBMSList.map(
                    (packBMS, packBMSIndex) => {
                      if (
                        target.packColorList &&
                        ((targetName === "packBMS" &&
                          packBMSIndex === target.packBMSIndex) ||
                          targetName === "systemBMS")
                      ) {
                        if (targetName === "systemBMS")
                          return {
                            qcModuleList: packBMS.qcModuleList.map(
                              (module, moduleIndex) => {
                                const targetModuleIndex =
                                  qcType === "MODULE"
                                    ? moduleIndex
                                    : Math.floor(moduleIndex / 2);
                                const isWarning =
                                  target.packColorList[packBMSIndex][
                                    targetModuleIndex
                                  ] === "warning";
                                const isOff =
                                  target.packColorList[packBMSIndex][
                                    targetModuleIndex
                                  ] === "light";
                                return {
                                  start:
                                    isWarning || isOff ? false : !target.start,
                                  endDateTime: module.endDateTime,
                                  startDateTime: module.startDateTime,
                                };
                              }
                            ),
                          };

                        if (
                          targetName === "packBMS" &&
                          packBMSIndex === target.packBMSIndex
                        )
                          return {
                            qcModuleList: packBMS.qcModuleList.map(
                              (module, moduleIndex) => {
                                const targetModuleIndex =
                                  qcType === "MODULE"
                                    ? moduleIndex
                                    : Math.floor(moduleIndex / 2);
                                const isWarning =
                                  target.packColorList[target.packBMSIndex][
                                    targetModuleIndex
                                  ] === "warning";
                                const isOff =
                                  target.packColorList[target.packBMSIndex][
                                    targetModuleIndex
                                  ] === "light";
                                return {
                                  start:
                                    isWarning || isOff ? false : !target.start,
                                  endDateTime: module.endDateTime,
                                  startDateTime: module.startDateTime,
                                };
                              }
                            ),
                          };

                        return packBMS;
                      }

                      return {
                        qcModuleList: packBMS.qcModuleList.map(
                          (module, moduleIndex) => {
                            const isTargetModule =
                              qcType === "MODULE"
                                ? moduleIndex === target.moduleIndex
                                : moduleIndex === target.moduleIndex ||
                                  moduleIndex === target.moduleSecondIndex;

                            if (
                              targetName === "module" &&
                              packBMSIndex === target.packBMSIndex &&
                              isTargetModule
                            ) {
                              return {
                                start: !target.start,
                                endDateTime: module.endDateTime,
                                startDateTime: module.startDateTime,
                              };
                            }

                            return module;
                          }
                        ),
                      };
                    }
                  ),
                };

                qcControl({
                  targetApi: () =>
                    qcApi.controlQC({ qcType: qcType, payload: data }),
                });
              }}
            />
          );
        },
      });
    },
    [qcType, openPopover, qcControl, qcData, t, openModal]
  );

  useEffect(() => {
    if (!qcFileData) return;

    const tableRows = qcFileData.qcFileList.map(
      (
        {
          fileName,
          startDateTime,
          endDateTime,
          fileFullPath,
          packBMSNo,
          moduleNo,
        },
        logFileIndex
      ) => {
        return {
          no: logFileIndex + 1,
          packBMSNo,
          moduleNo,
          startDateTime,
          endDateTime,
          delete: {
            disabled: isQCDeleteLoading,
            onClick: () =>
              openModal(
                <DefaultModal
                  status="warning"
                  type="confirm"
                  maxWidth={420}
                  title={`"${fileName}" ${t("Do you want to delete?")}`}
                  onSuccess={() => handleClickDelete(fileFullPath)}
                />
              ),
          },
          download: {
            disabled: isQCDownloadLoading,
            onClick: () => handleClickDownload(fileFullPath, fileName),
          },
        };
      }
    );

    setTableLogData((prev) => {
      return {
        ...prev,
        rows: tableRows,
      };
    });
  }, [
    qcFileData,
    handleClickDelete,
    handleClickDownload,
    isQCDeleteLoading,
    isQCDownloadLoading,
    t,
    openModal,
  ]);

  return {
    isUsePackBMS,
    isActiveSystemBMSButtons,
    isQCControlLoading,
    isQCFileLoading,
    qcData,
    statusData,
    tableLogData,
    page,
    setPage,
    handleClickButton,
  };
}

export default useQC;
