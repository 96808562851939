import MDBox from "components/MDBox";
import MDProgress from "components/MDProgress";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { memo } from "react";

type Props = {
  title: string;
  value: number;
  label: string;
  icon?: string;
  color?:
    | "info"
    | "dark"
    | "white"
    | "black"
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "error"
    | "light";
};

function CardProgress({
  title,
  value,
  label,
  icon = "battery_charging_full",
  color = "info",
}: Props) {
  return (
      <DefaultCard icon={icon} title={title} sx={{ overflow: "hidden" }}>
        <MDBox pb={2} pl={2} pr={2} align="right">
          <MDProgress value={value} label={label} color={color} />
        </MDBox>
      </DefaultCard>
  );
}

export default memo(CardProgress);
