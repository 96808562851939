import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

type Props = {
  isShow: boolean;
  title: string;
};

function TotalEntries({ title, isShow }: Props) {
  if (!isShow) return <MDBox></MDBox>;
  return (
    <MDBox mb={{ xs: 3, sm: 0 }}>
      <MDTypography variant="button" color="secondary" fontWeight="regular">
        {title}
      </MDTypography>
    </MDBox>
  );
}

export default TotalEntries;
