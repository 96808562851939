import { storageKey } from "lib/consts/storageKey";

const getStorageType = (key: string) =>
  key === storageKey.USER ? sessionStorage : localStorage;

const storage = {
  get: <T>(key: string, defaultValue?: T): T => {
    const storageItem = JSON.parse(getStorageType(key).getItem(key));
    return storageItem || defaultValue || undefined;
  },
  set: <T>(key: string, value: T) => {
    getStorageType(key).setItem(key, JSON.stringify(value));
  },
  remove: (key: string) => {
    getStorageType(key).removeItem(key);
  },
};

export default storage;
