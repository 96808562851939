import Footer from "layouts/components/footer";
import Body from "layouts/components/body";
import DashboardHeaderNavbar from "layouts/components/navbars/dashboardNavbar";
import { Outlet } from "react-router-dom";
import DashboardLayout from "../dashboardLayout";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Sidenav from "layouts/components/sidenav";
import Configurator from "layouts/components/configurator";
import EventLog from "layouts/components/eventLog";
import MDBox from "components/MDBox";
import { Icon } from "@mui/material";
import routes from "routes";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { uiConfigState } from "store/uiConfig";
import { systemConfigState } from "store/systemConfig";
import { userState } from "store/user";

import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import brandMini from "assets/images/logo-mini.png";

type Props = {
  isSignInPage: boolean;
};

function CommonLayout({ isSignInPage }: Props) {
  const [uiConfig, setUIConfig] = useRecoilState(uiConfigState);
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const [user] = useRecoilState(userState);
  const {
    miniSidenav,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = uiConfig;
  const { openConfigurator, openEventLog, setting } =
    systemConfig;
  const eventLogButtonRef = useRef<HTMLDivElement>(null);
  const eventLogContainerRef = useRef<HTMLDivElement>(null);
  const uiControllerButtonRef = useRef<HTMLDivElement>(null);
  const uiControllerContainerRef = useRef<HTMLDivElement>(null);
  const [isOpenUIConfig, setIsOpenUIConfig] = useState<boolean>(openConfigurator);
  const [isOpenEventLog, setIsOpenEventLog] = useState<boolean>(openEventLog);
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { t } = useTranslation("Translation");
  const routerList = useMemo(
    () => routes(user, setting, t),
    [user, setting, t]
  );

  const handleEventLogOpen = () => {
    setSystemConfig((prev) => ({
      ...prev,
      openEventLog: !prev.openEventLog,
    }));
  };

  const handleOnMouseEnter = useCallback(() => {
    if (miniSidenav && !onMouseEnter) {
      setUIConfig((prev) => ({
        ...prev,
        miniSidenav: false,
      }));
      setOnMouseEnter(true);
    }
  }, [miniSidenav, onMouseEnter, setUIConfig]);

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = useCallback(() => {
    if (onMouseEnter) {
      setUIConfig((prev) => ({
        ...prev,
        miniSidenav: true,
      }));
      setOnMouseEnter(false);
    }
  }, [onMouseEnter, setUIConfig]);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const targetElement = e.target as Node;
      const rootElement = document.querySelector("#root");

      if (!isOpenUIConfig && !isOpenEventLog) return;
      if (
        isOpenEventLog &&
        eventLogContainerRef.current?.contains(targetElement)
      )
        return;
      if (
        isOpenUIConfig &&
        uiControllerContainerRef.current?.contains(targetElement)
      )
        return;
      if (!rootElement.contains(targetElement)) return;

      if (
        !eventLogContainerRef.current?.contains(targetElement) &&
        !eventLogButtonRef.current?.contains(targetElement)
      ) {
        setSystemConfig((prev) => ({
          ...prev,
          openEventLog: false,
        }));
      }
      if (
        !uiControllerContainerRef.current?.contains(targetElement) &&
        !uiControllerButtonRef.current?.contains(targetElement)
      ) {
        setSystemConfig((prev) => ({
          ...prev,
          openConfigurator: false,
        }));
      }
    },
    [isOpenUIConfig, isOpenEventLog, setSystemConfig]
  );

  useEffect(() => {
    setIsOpenUIConfig(openConfigurator);
    setIsOpenEventLog(openEventLog);

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick, openEventLog, openConfigurator]);

  const renderEventLogButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      top="7rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleEventLogOpen}
      ref={eventLogButtonRef}
    >
      <Icon fontSize="medium" color="inherit">
        event_note
      </Icon>
    </MDBox>
  );

  if (isSignInPage) return <Outlet />;
  return (
    <MDBox>
      <>
        <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
            }
            brandName={`${setting.deviceName}`}
            version={`V ${setting.version}`}
            brandMini={brandMini}
            routes={routerList}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
        />
        <Configurator ref={uiControllerContainerRef} />
        <EventLog ref={eventLogContainerRef} />
        {renderEventLogButton}
      </>
      <DashboardLayout>
        <DashboardHeaderNavbar ref={uiControllerButtonRef} />
        <Body>
          <Outlet />
        </Body>
        <Footer />
      </DashboardLayout>
    </MDBox>
  );
}

export default CommonLayout;
