// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Role } from "lib/types/account";
import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import MDCheckBox from "components/MDCheckBox";
// Declaring props types for IdCell
interface Props {
  use: boolean;
  index: number;
  roles: Role[];
  onChangeHandler: any;
  disabled?: boolean;
}

function RolesCell({ use, index, roles, onChangeHandler, disabled }: Props): ReactElement {
  const { t } = useTranslation("Translation");

  return use ? (
    <MDBox display="flex" alignItems="center" justifyContent="space-around">
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_MONITOR")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_MONITOR", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Monitor")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_CONTROL")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_CONTROL", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Control")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_SETTING")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_SETTING", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Setting")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_ACCOUNT")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_ACCOUNT", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Account")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_LOG")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_LOG", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Log")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center" pr={2}>
        <MDCheckBox
            checked={roles.some((role) => role === "ROLE_QC")}
            onChange={(event: { target: { checked: boolean } }) => {
              onChangeHandler(index, "ROLE_QC", event.target.checked);
            }}
            disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("QC")}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDCheckBox
          checked={roles.some((role) => role === "ROLE_ADMIN")}
          onChange={(event: { target: { checked: boolean } }) => {
            onChangeHandler(index, "ROLE_ADMIN", event.target.checked);
          }}
          disabled={disabled}
        />
        <MDBox>
          <MDTypography variant="caption" fontWeight="medium" color="text">
            {t("Admin")}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  ) : (
    <MDBox display="flex" alignItems="center"></MDBox>
  );
}

export default RolesCell;
