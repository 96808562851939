import { TableRow } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";
import { HeaderGroup } from "react-table";
import DataTableHeadCell from "../dataTableHeadCell";

type Props = {
  headerBgColor: "dark" | "white";
  headerGroups: HeaderGroup<object>[];
  isSorted: boolean;
  excludeSortColumns: string[];
  setSortedValue: (column: HeaderGroup) => false | "none" | "desc" | "asce";
};

function TableHeader({
  headerBgColor,
  headerGroups,
  isSorted,
  excludeSortColumns,
  setSortedValue,
}: Props) {
  return (
    <MDBox
      component="thead"
      position="sticky"
      zIndex={50}
      top="0"
      bgColor={headerBgColor}
    >
      {headerGroups.map((headerGroup, key) => (
        <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column, key) => {
            return (
              <DataTableHeadCell
                key={key}
                {...column.getHeaderProps(
                  isSorted &&
                    !excludeSortColumns.includes(column.id) &&
                    column.getSortByToggleProps()
                )}
                width={column.width ? column.width : "auto"}
                align="center"
                sorted={setSortedValue(column)}
              >
                {column.render("Header")}
              </DataTableHeadCell>
            );
          })}
        </TableRow>
      ))}
    </MDBox>
  );
}

export default TableHeader;
