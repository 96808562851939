import api from "apis/api";
import { ControlType } from "lib/types/api";
import { requestPathname } from "lib/consts/api";

const getData = async () => {
  const response = await api.get(requestPathname.SYSTEM_BMS_DATA);
  return response.data;
};

const getStatus = async () => {
  const response = await api.get(requestPathname.SYSTEM_BMS_STATUS);
  return response.data;
};

const control = async (data: { type: ControlType; value: number }) => {
  const response = await api.post(requestPathname.SYSTEM_BMS_CONTROL, data);
  return response.data;
};

const deleteFirmwareFile = async (fileName: string) => {
  const response = await api.post(requestPathname.SYSTEM_BMS_DELETE_FIRMWARE_FILE, {
    fileName,
  });
  return response.data;
};

const dashboardApi = {
  getData,
  getStatus,
  control,
  deleteFirmwareFile
};

export default dashboardApi;
