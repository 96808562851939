import { Autocomplete } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React from "react";

type Props = {
  isShow: boolean;
  value: string;
  entries: string[];
  title: string;
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
};

function EntriesPerPage({ isShow, value, entries, onChange, title }: Props) {
  if (!isShow) return <></>;
  return (
    <MDBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={1}
    >
      <MDBox display="flex" alignItems="center">
        <Autocomplete
          disableClearable
          value={value}
          options={entries}
          onChange={onChange}
          size="small"
          sx={{ width: "5rem", marginRight: "0.5rem" }}
          renderInput={(params) => <MDInput {...params} />}
        />
        <MDTypography variant="caption" color="secondary">
          {title}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

export default EntriesPerPage;
