import DefaultButtonCell from 'layouts/components/dataTable/defaultButtonCell';
import DefaultInputCell from 'layouts/components/dataTable/defaultInputCell';
import React from 'react'
import { useTranslation } from 'react-i18next';
import RolesCell from '../components/rolesCell';

function useAccountTableData() {
  const { t } = useTranslation("Translation");

  const dataTableData = {
    columns: [
      {
        Header: `${t("ID")}`,
        accessor: "username",
        width: "18%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, input, index, val, onChangeHandler] = value;
          return (
            <DefaultInputCell
              use={use}
              input={input}
              index={index}
              name="Username"
              type="text"
              placeholder={t("ID")}
              value={val}
              onChangeHandler={onChangeHandler}
            />
          );
        },
      },
      {
        Header: `${t("Password")}`,
        accessor: "password",
        width: "15%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, input, index, val, onChangeHandler] = value;
          return (
            <DefaultInputCell
              use={use}
              input={input}
              index={index}
              name="Password"
              type="password"
              placeholder={t("Password")}
              value={val}
              onChangeHandler={onChangeHandler}
              disabled={index === 0}
            />
          );
        },
      },
      {
        Header: `${t("Roles")}`,
        accessor: "roles",
        width: "35%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, index, roles, onChangeHandler] = value;
          return (
            <RolesCell
              use={use}
              index={index}
              roles={roles}
              onChangeHandler={onChangeHandler}
              disabled={index === 0}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "modify",
        width: "7%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, index, loading, onClickHandler] = value;
          return (
            <DefaultButtonCell
              use={use}
              index={index}
              loading={loading || index === 0}
              color="primary"
              name="Modify"
              title={t("Modify")}
              onClickHandler={onClickHandler}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "create",
        width: "7%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, index, loading, onClickHandler] = value;
          return (
            <DefaultButtonCell
              use={use}
              index={index}
              loading={loading}
              color="info"
              name="Create"
              title={t("Create")}
              onClickHandler={onClickHandler}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "delete",
        width: "7%",
        align: "center",
        Cell: ({ value }: any) => {
          const [use, index, loading, onClickHandler] = value;
          return (
            <DefaultButtonCell
              use={use}
              index={index}
              loading={loading || index === 0}
              color="error"
              name="Delete"
              title={t("Delete")}
              onClickHandler={onClickHandler}
            />
          );
        },
      },
    ],
    rows: [] as any[],
  };
  
  return {
    dataTableData
  }
}

export default useAccountTableData