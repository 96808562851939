import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React from "react";

type Props = {
  isShow: boolean;
  title: string;
};

function NoData({ title, isShow }: Props) {
  if (!isShow) return <></>;
  return (
    <MDBox
      p={2}
      width="100%"
      display="flex"
      justifyContent="center"
      borderBottom="1px solid #eee"
    >
      <MDTypography variant="button" color="secondary" fontWeight="regular">
        {title}
      </MDTypography>
    </MDBox>
  );
}

export default NoData;
