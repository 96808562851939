import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { Setting } from "lib/types/setting";

const get = async () => {
  const response = await api.get(requestPathname.SETTING);
  return response.data;
};

const modify = async (data: Setting) => {
  const response = await api.put(`${requestPathname.SETTING}/${data.id}`, data);
  return response.data;
};

const settingApi = {
  get,
  modify,
};

export default settingApi;
