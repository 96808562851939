import { SelectChangeEvent } from "@mui/material";
import settingApi from "apis/configuration/setting/api";
import { ModalContext } from "context/modal/modalContext";
import DefaultModal from "layouts/components/modal/default";
import useApiRequest from "lib/hooks/useApiRequest";
import { ApiSuccessErrorMessage } from "lib/types/language";
import { Setting } from "lib/types/setting";
import isEqual from "lodash.isequal";
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { systemConfigState } from "store/systemConfig";
import usePackBMSTableData, { TableData } from "./usePackBMSTableData";

function useSetting() {
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const {
    setting,
    runtime: { isStart, isStartStopProcessing },
  } = systemConfig;
  const { t } = useTranslation("Translation");
  const { packBMSTableData } = usePackBMSTableData();
  const [formData, setFormData] = useState<Setting>(setting);
  const [tableData, setTableData] = useState<TableData>(packBMSTableData);
  const { request, isLoading } = useApiRequest();
  const { openModal } = useContext(ModalContext);
  const isModified = !isEqual(setting, formData);
  const isRuntimeStart = isStart || isStartStopProcessing;

  const handleChangeTableInputNumber = useCallback(
    (targetObjectName: "packBMSSettingList") =>
      (index: number, value: string, name: string) => {
        const settingList = formData[targetObjectName].map(
          (listItem, listIndex) => {
            if (index === listIndex) {
              return {
                ...listItem,
                [name]: name === "ip" ? value : Number(value),
              };
            }
            return listItem;
          }
        );
        setFormData((prev) => {
          return {
            ...prev,
            [targetObjectName]: settingList,
          };
        });
      },
    [formData]
  );

  const handleChangeTableCheckEnable = useCallback(
    (targetObjectName: "packBMSSettingList") =>
      (index: number, checked: boolean, name: string) => {
        const settingList = formData[targetObjectName].map(
          (listItem, listIndex) => {
            if (index === listIndex) {
              return {
                ...listItem,
                [name]: checked,
              };
            }
            return listItem;
          }
        );
        setFormData((prev) => {
          return {
            ...prev,
            [targetObjectName]: settingList,
          };
        });
      },
    [formData]
  );

  useEffect(() => {
    const packBMSTableDataRows = formData.packBMSSettingList.map(
      (packBMSSettingItem, packBMSIndex) => {
        const { identifier, ip, port, useSensor, useFan } =
          packBMSSettingItem;
        return {
          no: [packBMSIndex, `#${identifier + 1}`],
          ip: [
            packBMSIndex,
            ip,
            handleChangeTableInputNumber("packBMSSettingList"),
          ],
          tcpPort: [
            packBMSIndex,
            port,
            handleChangeTableInputNumber("packBMSSettingList"),
          ],
          useSensor: [
            packBMSIndex,
            useSensor,
            handleChangeTableCheckEnable("packBMSSettingList"),
          ],
          useFan: [
            packBMSIndex,
            useFan,
            handleChangeTableCheckEnable("packBMSSettingList"),
          ],
        };
      }
    );

    setTableData((prev: any) => {
      return {
        ...prev,
        rows: packBMSTableDataRows,
      };
    });
  }, [formData.packBMSSettingList, handleChangeTableInputNumber, handleChangeTableCheckEnable]);

  const handleChangeCheckEnable = useCallback(
    ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => {
        return {
          ...prev,
          [name]: checked,
        };
      });
    },
    []
  );

  const setPackBMSTableList = useCallback(
    (value: number) => {
      const packBMSSettingList = Array.from({ length: value }).map(
        (_, packBMSIndex) => {
          if (formData.numberOfPackBMS > packBMSIndex) {
            return formData.packBMSSettingList[packBMSIndex];
          }
          if (
            setting.numberOfPackBMS > packBMSIndex &&
            formData.numberOfPackBMS - 1 < packBMSIndex
          ) {
            return setting.packBMSSettingList[packBMSIndex];
          }
          return {
            identifier: packBMSIndex,
            ip: `192.168.3.${100 + packBMSIndex + 1}`,
            port: 502,
            useSensor: false,
            useFan: false
          };
        }
      );
      setFormData((prev) => {
        return {
          ...prev,
          packBMSSettingList,
        };
      });
    },
    [
      formData.numberOfPackBMS,
      formData.packBMSSettingList,
      setting.numberOfPackBMS,
      setting.packBMSSettingList,
    ]
  );

  const handleSelectIp = useCallback(
    ({ target: { name, value } }: SelectChangeEvent<number>) => {
      if (typeof value === "string") {
        setFormData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    },
    []
  );

  const handleChangeInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (Number(value) <= 0) {
        setFormData((prev) => {
          return {
            ...prev,
            [name]: 1,
          };
        });
        return;
      }
      setFormData((prev) => {
        return {
          ...prev,
          [name]: Number(value),
        };
      });
    },
    []
  );

  const handleChangeLogBackupInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (Number(value) <= 0) {
        setFormData((prev) => {
          return {
            ...prev,
            logBackupSetting: {
              ...prev.logBackupSetting,
              [name]: 21,
            },
          };
        });
        return;
      }
      setFormData((prev) => {
        return {
          ...prev,
          logBackupSetting: {
            ...prev.logBackupSetting,
            [name]: Number(value),
          },
        };
      });
    },
    []
  );

  const handleChangeLogBackupText = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (typeof value === "string") {
        setFormData((prev) => {
          return {
            ...prev,
            logBackupSetting: {
              ...prev.logBackupSetting,
              [name]: value,
            },
          };
        });
      }
    },
    []
  );

  const handleChangeCapacityInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (Number(value) < 0) {
        setFormData((prev) => {
          return {
            ...prev,
            namePlateSetting: {
              ...prev.namePlateSetting,
              [name]: 0,
            },
          };
        });
        return;
      }
      setFormData((prev) => {
        return {
          ...prev,
          namePlateSetting: {
            ...prev.namePlateSetting,
            [name]: Number(value),
          },
        };
      });
    },
    []
  );

  const handleChangeChargeDischargeRateInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setFormData((prev) => {
        return {
          ...prev,
          namePlateSetting: {
            ...prev.namePlateSetting,
            [name]: Number(value) < 0 ? Number(value) * -1 : Number(value),
          },
        };
      });
      return;
    },
    []
  );

  const handleChangeSoCInputNumber = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (Number(value) < 0) {
        setFormData((prev) => {
          return {
            ...prev,
            namePlateSetting: {
              ...prev.namePlateSetting,
              [name]: 0,
            },
          };
        });
        return;
      }
      if (Number(value) > 100) {
        setFormData((prev) => {
          return {
            ...prev,
            namePlateSetting: {
              ...prev.namePlateSetting,
              [name]: 100,
            },
          };
        });
        return;
      }
      setFormData((prev) => {
        return {
          ...prev,
          namePlateSetting: {
            ...prev.namePlateSetting,
            [name]: Number(value),
          },
        };
      });
    },
    []
  );

  const handleChangeText = useCallback(
    ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      if (typeof value === "string") {
        setFormData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    },
    []
  );

  const handleSelectNumberOfConnections = useCallback(
    ({ target: { name, value } }: SelectChangeEvent<number>) => {
      const isNumberOfPackBMS = name === "numberOfPackBMS";
      isNumberOfPackBMS && setPackBMSTableList(Number(value));

      setFormData((prev) => {
        return {
          ...prev,
          [name]:
            isNumberOfPackBMS ? Number(value) : value,
        };
      });
    },
    [setPackBMSTableList]
  );

  const setRuntimeStartModal = useCallback(() => {
    openModal(
      <DefaultModal status="error" title={t("Cannot be changed during startup.")} />
    );
  }, [openModal, t]);

  const handleClickButtonSave = useCallback(() => {
    if (isRuntimeStart) {
      setRuntimeStartModal();
      return;
    }

    request({
      targetApi: () => settingApi.modify(formData),
      onSuccess: (res) => {
        const response = res as { message: string };
        openModal(
          <DefaultModal
            status="success"
            title={t(response.message as keyof ApiSuccessErrorMessage)}
          />
        );
        setSystemConfig((prev) => ({
          ...prev,
          setting: formData,
        }));
      },
      onError: (err) => {
        openModal(
          <DefaultModal
            status="error"
            title={t(
              err?.response.data.message as keyof ApiSuccessErrorMessage
            )}
          />
        );
      },
    });
  }, [
    setSystemConfig,
    request,
    formData,
    isRuntimeStart,
    setRuntimeStartModal,
    openModal,
    t,
  ]);

  return {
    formData,
    tableData,
    isLoading,
    isModified,
    handleSelectIp,
    handleSelectNumberOfConnections,
    handleChangeInputNumber,
    handleChangeCheckEnable,
    handleChangeLogBackupInputNumber,
    handleChangeLogBackupText,
    handleChangeCapacityInputNumber,
    handleChangeChargeDischargeRateInputNumber,
    handleChangeSoCInputNumber,
    handleChangeText,
    handleClickButtonSave,
  };
}

export default useSetting;
