import MDVerticalBarChart from "components/MDChart/VerticalBar";
import React, { memo } from "react";
import isEqual from "lodash.isequal";
import { useTranslation } from "react-i18next";
import {Grid} from "@mui/material";
import DefaultCard from "../cards/defaultCard";

type Props = {
  title: string;
  chartData: number[];
  icon?: string;
  chartTitle?: string;
  chartDescription?: string;
  xl?: number;
  yMin?: number;
  yMax?: number;
};

function CardChart({
  title,
  chartData,
  icon = "bar_chart",
  chartTitle = "",
  chartDescription = "",
  xl = 4,
  yMin,
  yMax,
}: Props) {
  const { t } = useTranslation("Translation");
  return (
    <Grid item xs={12} xl={xl}>
      <DefaultCard icon={icon} title={title}>
        <MDVerticalBarChart
          chart={{
            labels: [t("Min"), t("Avg"), t("Max")],
            yMin,
            yMax,
            datasets: [
              {
                label: "",
                color: ["warning", "success", "error"],
                data: chartData,
              },
            ],
          }}
          title={chartTitle}
          description={chartDescription}
        />
      </DefaultCard>
    </Grid>
  );
}

export default memo(CardChart, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
