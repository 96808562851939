import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Colors } from "lib/types/material";
import {getDateTime} from "lib/utils/common";
import React, { useCallback, useEffect, useState } from "react";
import useInterval from "lib/hooks/useInterval";
import {INTERVAL_TIME} from "lib/consts/common";

type Props = {
  color: Colors;
};

function CurrentDateTime({ color }: Props) {
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const setCurrentDate = useCallback((date: Date) => {
    const now = date;
    const language = "en-US";
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

    setTime(now.toLocaleTimeString(language, { timeZone }));
    setDate(
      now.toLocaleDateString(language, {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone,
      })
    );
  }, []);

  useEffect(() => {
    setCurrentDate(getDateTime());
  }, [setCurrentDate]);

  useInterval(() => {
      setCurrentDate(getDateTime());
    },
    INTERVAL_TIME,
    true
  );

  return (
    <MDBox
      mx={1.2}
      px={1}
      py={2}
      border={`2px solid ${color}`}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <MDTypography variant="h2" color={color} className="digital-time">
        {time}
      </MDTypography>
      <MDTypography variant="subtitle2" color={color}>
        {date}
      </MDTypography>
    </MDBox>
  );
}

export default CurrentDateTime;
