const se804St = {
  NONE: 0x00000000,
  STRING_ENABLED: 0x00000001,
  CONTACTOR_STATUS: 0x00000002,
  PRE_CHARGE_STATUS: 0x00000004,
} as const;

const se804ConFail = {
  NO_FAILURE: 0,
  BUTTON_PUSHED: 1,
  STR_GROUND_FAULT: 2,
  OUTSIDE_VOLTAGE_RANGE: 3,
  STRING_NOT_ENABLED: 4,
  FUSE_OPEN: 5,
  CONTACTOR_FAILURE: 6,
  PRE_CHARGE_FAILURE: 7,
  STRING_FAULT: 8,
} as const;

const se804ConSt = {
  CONTACTOR_NONE: 0x00000000,
  CONTACTOR_0: 0x00000001,
  CONTACTOR_1: 0x00000002,
  CONTACTOR_2: 0x00000004,
  CONTACTOR_3: 0x00000008,
  CONTACTOR_4: 0x00000010,
  CONTACTOR_5: 0x00000020,
  CONTACTOR_6: 0x00000040,
  CONTACTOR_7: 0x00000080,
  CONTACTOR_8: 0x00000100,
  CONTACTOR_9: 0x00000200,
  CONTACTOR_10: 0x00000400,
  CONTACTOR_11: 0x00000800,
  CONTACTOR_12: 0x00001000,
  CONTACTOR_13: 0x00002000,
  CONTACTOR_14: 0x00004000,
  CONTACTOR_15: 0x00008000,
  CONTACTOR_16: 0x00010000,
  CONTACTOR_17: 0x00020000,
  CONTACTOR_18: 0x00040000,
  CONTACTOR_19: 0x00080000,
  CONTACTOR_20: 0x00100000,
  CONTACTOR_21: 0x00200000,
  CONTACTOR_22: 0x00400000,
  CONTACTOR_23: 0x00800000,
  CONTACTOR_24: 0x01000000,
  CONTACTOR_25: 0x02000000,
  CONTACTOR_26: 0x04000000,
  CONTACTOR_27: 0x08000000,
  CONTACTOR_28: 0x10000000,
  CONTACTOR_29: 0x20000000,
  CONTACTOR_30: 0x40000000,
} as const;

const se804Evt1 = {
  NO_ERROR: 0x00000000,
  COMMUNICATION_ERROR: 0x00000001,
  OVER_TEMP_ALARM: 0x00000002,
  OVER_TEMP_WARNING: 0x00000004,
  UNDER_TEMP_ALARM: 0x00000008,
  UNDER_TEMP_WARNING: 0x00000010,
  OVER_CHARGE_CURRENT_ALARM: 0x00000020,
  OVER_CHARGE_CURRENT_WARNING: 0x00000040,
  OVER_DISCHARGE_CURRENT_ALARM: 0x00000080,
  OVER_DISCHARGE_CURRENT_WARNING: 0x00000100,
  OVER_VOLT_ALARM: 0x00000200,
  OVER_VOLT_WARNING: 0x00000400,
  UNDER_VOLT_ALARM: 0x00000800,
  UNDER_VOLT_WARNING: 0x00001000,
  UNDER_SOC_MIN_ALARM: 0x00002000,
  UNDER_SOC_MIN_WARNING: 0x00004000,
  OVER_SOC_MAX_ALARM: 0x00008000,
  OVER_SOC_MAX_WARNING: 0x00010000,
  VOLTAGE_IMBALANCE_WARNING: 0x00020000, // CELL_VOLT_IMBALANCE_WARNING
  TEMPERATURE_IMBALANCE_ALARM: 0x00040000,
  TEMPERATURE_IMBALANCE_WARNING: 0x00080000,
  CONTACTOR_ERROR: 0x00100000,
  FAN_ERROR: 0x00200000,
  GROUND_FAULT: 0x00400000,
  OPEN_DOOR_ERROR: 0x00800000,
  RESERVED_1: 0x01000000,
  OTHER_ALARM: 0x02000000,
  OTHER_WARNING: 0x04000000,
  RESERVED_2: 0x08000000,
  CONFIGURATION_ALARM: 0x10000000,
  CONFIGURATION_WARNING: 0x20000000,
} as const;

const se804Evt2 = {
  NO_ERROR: 0x00000000,
} as const;

const se804EvtVnd1 = {
  NO_ERROR: 0x00000000,
  OVER_CELL_VOLT_ALARM: 0x00000001,
  OVER_CELL_VOLT_WARNING: 0x00000002,
  UNDER_CELL_VOLT_ALARM: 0x00000004,
  UNDER_CELL_VOLT_WARNING: 0x00000008,
  CELL_VOLT_IMBALANCE_ALARM: 0x00000010,
  MODULE_VOLT_IMBALANCE_WARNING: 0x00000020,
  MODULE_VOLT_IMBALANCE_ALARM: 0x00000040,
  OVER_MODULE_TEMP_ALARM: 0x00000080,
  OVER_MODULE_TEMP_WARNING: 0x00000100,
  UNDER_MODULE_TEMP_ALARM: 0x00000200,
  UNDER_MODULE_TEMP_WARNING: 0x00000400,
  MODULE_TEMP_IMBALANCE_ALARM: 0x00000800,
  MODULE_TEMP_IMBALANCE_WARNING: 0x00001000,
  MISMATCH_CURRENT_ALARM: 0x00002000,
  MISMATCH_VOLT_ALARM: 0x00004000,
  MISMATCH_INTERNAL_24V_VOLT_ALARM: 0x00008000,
  MISMATCH_INTERNAL_5V_VOLT_ALARM: 0x00010000,
  MISMATCH_INTERNAL_3_3V_VOLT_ALARM: 0x00020000,
  CURRENT_SENSOR_ERROR: 0x00040000,
  TEMPERATURE_SENSOR_ERROR: 0x00080000,
  EMERGENCY_ALARM: 0x00100000,
  OPEN_WIRE_ERROR: 0x00200000,
  RTC_ERROR: 0x00400000,
  FIRE_OUTBREAK: 0x00800000,
  FUSE_OPEN: 0x01000000,
} as const;

const se804EvtVnd2 = {
  NO_ERROR: 0x00000000,
} as const;

const se804SetEna = {
  NONE: 0,
  ENABLE: 1,
  DISABLE: 2,
} as const;

const se804SetCon = {
  NONE: 0,
  CONNECT: 1,
  DISCONNECT: 2,
} as const;

const se804SetBal = {
  NONE: 0,
  ENABLE: 1,
  DISABLE: 2,
} as const;

const se805CellSt = {
  CELL_NONE: 0x00000000,
  CELL_IS_BALANCING: 0x00000001,
} as const;

const se1001SetWarnLight = {
  NONE: 0,
  OFF: 1,
  ON: 2,
} as const;

const se1001Evt1 = {
  NO_ERROR: 0x00000000,
  FIRE_OUTBREAK: 0x00000001,
  OPEN_DOOR: 0x00000002,
} as const;

const balancingMode = {
  NORMAL: "NORMAL",
  FORCE: "FORCE",
  ODD_EVEN: "ODD_EVEN",
  SELECT: "SELECT",
  CHARGE_SNAPSHOT: "CHARGE_SNAPSHOT",
  DISCHARGE_SNAPSHOT: "DISCHARGE_SNAPSHOT",
} as const;

const balancingCondition = {
  NONE: 0x00000000,
  CHARGE: 0x00000001,
  DISCHARGE: 0x00000002,
  IDLE: 0x00000004,
} as const;

const balancingCriteria = {
  NONE: "NONE",
  SYSTEM_BMS: "SYSTEM_BMS",
  PACK_BMS: "PACK_BMS",
} as const;

const packBMS = {
  se804St,
  se804ConFail,
  se804ConSt,
  se804Evt1,
  se804Evt2,
  se804EvtVnd1,
  se804EvtVnd2,
  se804SetEna,
  se804SetCon,
  se804SetBal,
  se805CellSt,
  se1001SetWarnLight,
  se1001Evt1,
  balancingMode,
  balancingCondition,
  balancingCriteria,
};

export default packBMS;
