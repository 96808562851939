import { recoilKey } from "lib/consts/recoilKey";
import { storageKey } from "lib/consts/storageKey";
import { Colors } from "lib/types/material";
import storage from "lib/utils/storage";
import { atom, AtomEffect } from "recoil";

export type StorageUIConfig = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  darkMode: boolean;
  sidenavColor: Colors;
  fixedNavbar: boolean;
  transparentHeader: boolean;
};

const INITIAL_UI_CONFIG: StorageUIConfig = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  darkMode: false,
  sidenavColor: "white",
  fixedNavbar: true,
  transparentHeader: false,
};

const storageUIConfigEffect =
  (key: string): AtomEffect<StorageUIConfig> =>
  ({ setSelf, onSet }) => {
    const savedStorageValue = storage.get<StorageUIConfig>(key);

    if (savedStorageValue) {
      setSelf(savedStorageValue);
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? storage.remove(key)
        : storage.set(key, newValue);
    });
  };

export const uiConfigState = atom<StorageUIConfig>({
  key: recoilKey.UI_CONFIG,
  default: INITIAL_UI_CONFIG,
  effects: [storageUIConfigEffect(storageKey.UI_CONFIG)],
});
