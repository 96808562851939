import DefaultInputCell from "layouts/components/dataTable/defaultInputCell";
import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import DefaultCheckboxCell from "layouts/components/dataTable/defaultCheckboxCell";

export type TableData = {
  columns: {
    Header: string;
    accessor: string;
    width: string;
    align: string;
    Cell: ({ value }: any) => ReactElement;
  }[];
  rows: any[];
};

function usePackBMSTableData() {
  const { t } = useTranslation("Translation");

  const packBMSTableData: TableData = {
    columns: [
      {
        Header: `${t("No.")}`,
        accessor: "no",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val] = value;
          return (
            <DefaultInputCell
              use={true}
              input={false}
              index={index}
              type="text"
              label={t("No.")}
              value={val}
            />
          );
        },
      },
      {
        Header: `${t("IP")}`,
        accessor: "ip",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val, onChangeHandler] = value;
          return (
            <DefaultInputCell
              use={true}
              input={true}
              index={index}
              type="text"
              name="ip"
              label={t("IP")}
              value={val}
              onChangeHandler={onChangeHandler}
            />
          );
        },
      },
      {
        Header: `${t("TCP port")}`,
        accessor: "tcpPort",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val, onChangeHandler] = value;
          return (
            <DefaultInputCell
              use={true}
              input={true}
              index={index}
              type="number"
              name="port"
              label={t("TCP port")}
              value={val}
              onChangeHandler={onChangeHandler}
            />
          );
        },
      },
      {
        Header: `${t("Sensor")}`,
        accessor: "useSensor",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, checked, onChange] = value;
          return (
            <DefaultCheckboxCell
              use={true}
              index={index}
              name="useSensor"
              checked={checked}
              onChange={onChange}
            />
          );
        },
      },
      {
        Header: `${t("Fan")}`,
        accessor: "useFan",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, checked, onChange] = value;
          return (
            <DefaultCheckboxCell
              use={true}
              index={index}
              name="useFan"
              checked={checked}
              onChange={onChange}
            />
          );
        },
      },
    ],
    rows: [] as any[],
  };

  return {
    packBMSTableData,
  };
}

export default usePackBMSTableData;
