import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import React, {ReactElement} from "react";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import useChangePassword from "./hooks/useChangePassword";
import {Grid} from "@mui/material";
import DefaultCard from "layouts/components/cards/defaultCard";
import pxToRem from "assets/theme/functions/pxToRem";

function ChangePassword(): ReactElement {
  const {
    currentAccount,
    changePasswordValue,
    isLoading,
    disabledSaveButton,
    handleClickSaveButton,
    handleChangeInput,
    currentPasswordCheck,
    newPasswordCheck,
    confirmNewPasswordCheck,
  } = useChangePassword();
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      <MDBox mb={1}>
        <DefaultCard icon="password" title={t("Change password")}>
          <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={4}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {t("ID")}
                </MDTypography>
              </Grid>
              <Grid item sm={8}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {currentAccount?.username}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={4}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {t("Current password")}
                </MDTypography>
              </Grid>
              <Grid item sm={8}>
                <MDInput
                  type="password"
                  name="currentPassword"
                  label={t("Current password")}
                  value={changePasswordValue.currentPassword}
                  fullWidth
                  onChange={handleChangeInput}
                  disabled={isLoading}
                  success={currentPasswordCheck}
                  error={!currentPasswordCheck}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={4}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {t("New password")}
                </MDTypography>
              </Grid>
              <Grid item sm={8}>
                <MDInput
                  type="password"
                  name="newPassword"
                  label={t("New password")}
                  value={changePasswordValue.newPassword}
                  fullWidth
                  onChange={handleChangeInput}
                  disabled={isLoading}
                  success={newPasswordCheck}
                  error={!newPasswordCheck}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
            <Grid container alignItems="center">
              <Grid item sm={4}>
                <MDTypography
                  variant="h6"
                  fontWeight="medium"
                  textTransform="capitalize"
                  textAlign="center"
                >
                  {t("Confirm new password")}
                </MDTypography>
              </Grid>
              <Grid item sm={8}>
                <MDInput
                  type="password"
                  name="confirmNewPassword"
                  label={t("Confirm new password")}
                  value={changePasswordValue.confirmNewPassword}
                  fullWidth
                  onChange={handleChangeInput}
                  disabled={isLoading}
                  success={confirmNewPasswordCheck}
                  error={!confirmNewPasswordCheck}
                />
              </Grid>
            </Grid>
          </MDBox>
        </DefaultCard>
      </MDBox>
      <MDButton
        disabled={disabledSaveButton || isLoading}
        color="info"
        name="save"
        onClick={handleClickSaveButton}
        fullWidth
        sx={{ fontSize: pxToRem(18), padding: pxToRem(12) }}
      >
        {t("Save")}
      </MDButton>
    </MDBox>
  );
}

export default ChangePassword;
