import {
  MenuItem,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@mui/material";
import React, { FC, forwardRef, useCallback } from "react";
import MDSelectRoot from "components/MDSelect/MDSelectRoot";
import pxToRem from "assets/theme/functions/pxToRem";

interface Props
  extends Omit<OutlinedTextFieldProps | StandardTextFieldProps, "variant"> {
  variant?: "standard" | "outlined";
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  options?: [];
  value?: string;
}

const MDSelect: FC<Props | any> = forwardRef(
  (
    { error, success, disabled, options, value, ...rest },
    ref
  ) => {
    const renderOptionItem = useCallback(
      (opt: any) => (
        <MenuItem key={opt.name} value={opt.value}>
          {opt.name}
        </MenuItem>
      ),
      []
    );

    return (
      <MDSelectRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
        value={value}
        select
        menuprops={{ PaperProps: { sx: { maxHeight: pxToRem(350) } } }}
      >
        {options.map(renderOptionItem)}
      </MDSelectRoot>
    );
  }
);

// Setting default values for the props of MDInput
MDSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  options: [],
  label: "",
  value: "",
  placeholder: "Select",
};

export default MDSelect;
