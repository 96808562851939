import api from "apis/api";
import { requestPathname } from "lib/consts/api";

const getLog = async () => {
  const response = await api.get(requestPathname.LOG);
  return response.data;
};

const createLog = async (data: {
  startDateTime: string;
  endDateTime: string;
}) => {
  const response = await api.post(requestPathname.LOG, data, {
    responseType: "blob",
  });
  return response.data;
};

const downloadLog = async (fileFullPath: string) => {
  const response = await api.post(
    requestPathname.LOG_DOWNLOAD,
    { fileFullPath },
    { responseType: "blob" }
  );
  return response.data;
};

const deleteLog = async (fileFullPath: string) => {
  const response = await api.post(requestPathname.LOG_DELETE, {
    fileFullPath,
  });
  return response.data;
};

const getLogEvent = async (data: {
  startDateTime: string;
  endDateTime: string;
}) => {
  const response = await api.post(requestPathname.LOG_EVENT, data);
  return response.data;
};

const getLogEventCount = async () => {
  const response = await api.get(requestPathname.LOG_EVENT);
  return response.data;
};

const logApi = {
  getLog,
  getLogEvent,
  getLogEventCount,
  createLog,
  downloadLog,
  deleteLog,
};

export default logApi;
