// @mui material components
import { Theme } from "@mui/material/styles";

function collapseItem(theme: Theme | any, ownerState: any) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } =
    theme;
  const {
    active,
    color,
    children,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = ownerState;

  const { white, transparent, dark } = palette;
  const { md } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem, rgba } = functions;

  return {
    background: () => {
      let backgroundValue;

      if (children === null) {
        backgroundValue = active ? palette[color].main : transparent.main;
      } else {
        backgroundValue = active
          ? rgba(palette[color].main, 0.2)
          : transparent.main;
      }

      return backgroundValue;
    },
    color:
      (transparentSidenav && !darkMode) || whiteSidenav
        ? dark.main
        : white.main,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    margin: `${pxToRem(1.5)} ${pxToRem(16)}`,
    borderRadius: borderRadius.md,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow:
      active && !whiteSidenav && !darkMode && !transparentSidenav ? md : "none",
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },

    "&:hover, &:focus": {
      backgroundColor:
        !active || (active && children !== null)
          ? rgba(palette[color].main, 0.2)
          : palette[color].main,
    },
    /*"&:hover, &:focus": {
      backgroundColor:
        transparentSidenav && !darkMode
          ? grey[300]
          : rgba(whiteSidenav ? grey[400] : white.main, 0.2),
    },*/
  };
}

function collapseIconBox(theme: Theme, ownerState: any) {
  const { palette, transitions, borders, functions } = theme;
  const {
    transparentSidenav,
    whiteSidenav,
    darkMode,
    active,
    color,
    children,
  } = ownerState;

  const { white, dark, black } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color:
      (((color === "light" && !darkMode) || color === "white") &&
        children == null &&
        active) ||
      (((transparentSidenav && !darkMode) || whiteSidenav) &&
        (!active || (children !== null && active)))
        ? black.main
        : white.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    },
  };
}

const collapseIcon = (
  { palette: { white, gradients } }: Theme,
  { active }: any
) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(theme: any, ownerState: any) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    active,
    color,
    children,
  } = ownerState;

  const { size, fontWeightMedium, fontWeightLight } = typography;
  const { pxToRem } = functions;
  const { white, black } = palette;

  return {
    marginLeft: pxToRem(10),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft:
        miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      color:
        (((color === "light" && !darkMode) || color === "white") &&
          children == null &&
          active) ||
        (((transparentSidenav && !darkMode) || whiteSidenav) &&
          (!active || (children !== null && active)))
          ? black.main
          : white.main,
      fontWeight: active ? fontWeightMedium : fontWeightLight,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

function collapseArrow(theme: Theme, ownerState: any) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    noCollapse,
    transparentSidenav,
    whiteSidenav,
    miniSidenav,
    open,
    active,
    darkMode,
  } = ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: () => {
      let colorValue;

      if (transparentSidenav && darkMode) {
        colorValue = open || active ? white.main : rgba(white.main, 0.25);
      } else if (transparentSidenav || whiteSidenav) {
        colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
      } else {
        colorValue = open || active ? white.main : rgba(white.main, 0.5);
      }

      return colorValue;
    },
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

export {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
};
