import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";
import MDBadge from "components/MDBadge";
import { Colors } from "lib/types/material";
import React, { memo } from "react";

type Props = {
  title: string;
  color: Colors;
  width?: string;
  height?: string;
  sx?: SxProps<Theme>
};

function MemeBadge({ title, color, width, height, sx }: Props) {
  return (
    <MDBadge
      badgeContent={title}
      color={color}
      container
      size="lg"
      width={width}
      height={height}
      sx={sx}
    />
  );
}

export default memo(MemeBadge);
