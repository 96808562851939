import { AxiosError } from "axios";
import { ErrorDataType, MessageType } from "lib/types/api";
import { useCallback, useEffect, useState } from "react";

type RequestParamsType = {
  targetApi: () => Promise<any>;
  onSuccess?: (data: unknown) => void;
  onError?: (error: AxiosError<ErrorDataType>) => void;
};

type ApiData<T> = {
  isLoading: boolean;
  isError: boolean;
  message: MessageType;
  data: T;
};

const INIT_MESSAGE: MessageType = {
  status: "idle",
  content: "",
};

function useApiRequest<T>() {
  const [apiData, setApiData] = useState<ApiData<T>>({
    isLoading: false,
    isError: false,
    message: INIT_MESSAGE,
    data: undefined,
  });
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const request = useCallback(
    ({ targetApi, onSuccess, onError }: RequestParamsType) => {
      if (!isMounted) return;
      setApiData((prev) => {
        return {
          ...prev,
          isLoading: true,
          isError: false,
          message: INIT_MESSAGE,
        };
      });
      targetApi()
        .then((res: T & { message?: string }) => {
          if (!isMounted) return;
          setApiData((prev) => {
            return {
              ...prev,
              isLoading: false,
              isError: false,
              message: {
                status: "success",
                content: res?.message,
              },
              data: res,
            };
          });
          onSuccess && onSuccess(res);
        })
        .catch((err: AxiosError<ErrorDataType>) => {
          if (!isMounted) return;
          setApiData((prev) => {
            return {
              ...prev,
              isLoading: false,
              isError: true,
              message: {
                status: "error",
                content: err.response?.data?.message,
              },
            };
          });
          onError && onError(err);
        });
    },
    [isMounted]
  );

  return {
    request,
    isLoading: apiData.isLoading,
    isError: apiData.isError,
    message: apiData.message,
    data: apiData.data,
  };
}

export default useApiRequest;
