import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { ControlType } from "lib/types/api";

const control = async (
  packBMSNo: number,
  moduleNo: number,
  data: { type: ControlType; value: number }
) => {
  const response = await api.post(
    requestPathname.SYSTEM_BMS_MODULE_CONTROL(packBMSNo, moduleNo),
    data
  );
  return response.data;
};

const moduleApi = {
  control,
};

export default moduleApi;
