import { Grid, SelectChangeEvent } from "@mui/material";
import MDBox from "components/MDBox";
import MDCheckBox from "components/MDCheckBox";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { PropsWithChildren, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  checkName: string;
  isCheck: boolean;
  onChangeCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  selectName: string;
  selectValue: number;
  selectOptions: string[];
  selectOnChange: (e: SelectChangeEvent<number>) => void;
};

const GridSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed !important" : "default",
});

function TableCard({
  children,
  title,
  checkName,
  isCheck,
  onChangeCheck,
  selectName,
  selectValue,
  selectOptions,
  selectOnChange,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="lan" title={title}>
      <MDBox mb={1} mx={6}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Enable")}
            </MDTypography>
          </Grid>
          <Grid item sm={1} textAlign="center">
            <MDCheckBox
              name={checkName}
              checked={isCheck}
              onChange={onChangeCheck}
            />
          </Grid>
          <Grid item sm={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Number of connections")}
            </MDTypography>
          </Grid>
          <Grid item sm={8} sx={GridSX(!isCheck)}>
            <MDSelect
              label={t("Number of connections")}
              name={selectName}
              fullWidth
              value={selectValue}
              disabled={!isCheck}
              onChange={selectOnChange}
              options={selectOptions.map((option: string) => {
                return { name: option, value: option };
              })}
            />
          </Grid>
        </Grid>
      </MDBox>
      {children}
    </DefaultCard>
  );
}

export default TableCard;
