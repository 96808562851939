// @mui material components
import { Theme } from "@mui/material/styles";

function item(theme: Theme | any, ownerState: any) {
  const { palette, borders, functions, transitions } = theme;
  const { active, color, miniSidenav } = ownerState;

  const { transparent } = palette;
  const { borderRadius } = borders;
  const { rgba } = functions;

  return {
    pl: miniSidenav ? 0.5 : 2.2,
    mt: 0.375,
    mb: 0.3,
    width: "100%",
    borderRadius: borderRadius.md,
    cursor: "pointer",
    backgroundColor: () => {
      let backgroundValue;

      if (!active) {
        backgroundValue = transparent.main;
      } else {
        backgroundValue = palette[color].main;
      }

      return backgroundValue;
    },
    transition: transitions.create("background-color", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    "&:hover, &:focus": {
      backgroundColor: !active && rgba(palette[color].main, 0.2),
    },
  };
}

function itemContent(theme: Theme, ownerState: any) {
  const { palette, typography, transitions, functions } = theme;
  const {
    miniSidenav,
    active,
    color,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = ownerState;

  const { white, dark, black } = palette;
  const { size, fontWeightMedium, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: `${pxToRem(10)} ${pxToRem(6)}`,
    userSelect: "none",
    position: "relative",

    "& span": {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) &&
        (active === "isParent" || !active)
          ? dark.main
          : ((color === "light" && !darkMode) ||
              (color === "light" && darkMode && whiteSidenav) ||
              color === "white") &&
            active
          ? black.main
          : white.main,
      fontWeight: active ? fontWeightMedium : fontWeightLight,
      fontSize: size.sm,
      transition: transitions.create(["opacity", "color"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "&::before": {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) &&
        (active === "isParent" || !active)
          ? dark.main
          : ((color === "light" && !darkMode) ||
              (color === "light" && darkMode && whiteSidenav) ||
              color === "white") &&
            active
          ? black.main
          : white.main,
      fontWeight: fontWeightMedium,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: miniSidenav ? pxToRem(-28) : pxToRem(-15),
      opacity: 1,
      borderRadius: "50%",
      fontSize: size.sm,
    },
  };
}

function itemArrow(theme: Theme, ownerState: any) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const {
    noCollapse,
    transparentSidenav,
    whiteSidenav,
    miniSidenav,
    open,
    active,
    darkMode,
  } = ownerState;

  const { white, dark } = palette;
  const { size } = typography;
  const { pxToRem, rgba } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginBottom: pxToRem(-1),
    transform: open ? "rotate(0)" : "rotate(-180deg)",
    color: () => {
      let colorValue;

      if (transparentSidenav && darkMode) {
        colorValue = open || active ? white.main : rgba(white.main, 0.25);
      } else if (transparentSidenav || whiteSidenav) {
        colorValue = open || active ? dark.main : rgba(dark.main, 0.25);
      } else {
        colorValue = open || active ? white.main : rgba(white.main, 0.5);
      }

      return colorValue;
    },
    transition: transitions.create(["color", "transform", "opacity"], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? "none !important"
          : "block !important",
    },
  };
}

function itemIcon(theme: Theme, ownerState: any) {
  const { palette, transitions, borders, functions, typography } = theme;
  const {
    transparentSidenav,
    whiteSidenav,
    darkMode,
    active,
    color,
    children,
  } = ownerState;

  const { white, dark, black } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;
  const { size } = typography;

  return {
    marginRight: pxToRem(10),
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color:
      (((color === "light" && !darkMode) || color === "white") &&
        children == null &&
        active) ||
      (((transparentSidenav && !darkMode) || whiteSidenav) &&
        (!active || (children !== null && active)))
        ? black.main
        : white.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
   

    "& span": {
      fontSize: `${size["2xl"]} !important`,
    },
    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    },
  };
}

function itemText(theme: Theme, ownerState: any) {
  const {
    miniSidenav
  } = ownerState;

  return {
    opacity: miniSidenav ? 0 : 1
  };
}


export { item, itemContent, itemArrow, itemIcon, itemText };
