import React, {ReactElement, ReactNode} from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { Colors } from "lib/types/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

// Declaring prop types for the ComplexProjectCard
interface Props {
  color?:
    | "white"
    | "black"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark";
  icon: string;
  title: string;
  titleColor?: Colors;
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

// Custom styles for DefaultCard
function DefaultCard({
  color = "dark",
  icon,
  title,
  titleColor,
  sx = { overflow: "auto" },
  children,
}: Props): ReactElement {
  return (
    <Card sx={sx}>
      <MDBox p={1} display="flex" justifyContent="center">
        <Grid container spacing={1} alignItems="center">
          <Grid item ml={2} mt={0.5} lineHeight={1}>
            <MDBox
              display="grid"
              justifyContent="center"
              alignItems="center"
              bgColor={color}
              color="white"
              width="2rem"
              height="2rem"
              shadow="md"
              borderRadius="lg"
              variant="gradient"
            >
              <Icon fontSize="medium">{icon}</Icon>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={1} lineHeight={1}>
              <MDTypography variant="h4" fontWeight="medium" color={titleColor}>
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {children}
    </Card>
  );
}

export default DefaultCard;
