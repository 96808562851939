import {
  InteractionModeMap,
  Scriptable,
  ScriptableScaleContext,
} from "chart.js";
import { useRef } from "react";

function Config(labels: any, datasets: any, chartOptions: any) {
  const currentOptions = useRef({
    responsive: true,
    maintainAspectRatio: false,
    animations: {
      y: {
        duration: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index" as keyof InteractionModeMap,
    },
    scales: {
      y: {
        min: 0,
        suggestedMin: chartOptions?.yMin,
        suggestedMax: chartOptions?.yMax,
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: false,
          borderDash: [5, 5],
          color: "#c1c4ce5c",
        },
        ticks: {
          autoSkip: false,
          display: true,
          padding: 10,
          color: "#9ca2b7",
          font: {
            size: 14,
            weight: "300" as Scriptable<string, ScriptableScaleContext>,
            family: "Roboto",
            style: "normal" as Scriptable<
              "inherit" | "initial" | "normal" | "italic" | "oblique",
              ScriptableScaleContext
            >,
            lineHeight: 2,
          },
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [5, 5],
          color: "#c1c4ce5c",
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          maxRotation: 0,
          color: "#9ca2b7",
          padding: 10,
          font: {
            size: 14,
            weight: "300" as Scriptable<string, ScriptableScaleContext>,
            family: "Roboto",
            style: "normal" as Scriptable<
              "inherit" | "initial" | "normal" | "italic" | "oblique",
              ScriptableScaleContext
            >,
            lineHeight: 2,
          },
        },
      },
    },
  });
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: currentOptions.current,
  };
}

export default Config;
