import Grid from "@mui/material/Grid";
import { Fade } from "@mui/material";
import { changeDecimalPoint } from "lib/utils/common";
import ModuleCard from "./components/moduleCard";
import useModule from "./hooks/useModule";
import { Se805 } from "lib/types/pack";
import MDBox from "components/MDBox";
import breakpoints from "assets/theme/base/breakpoints";
import { useWindowVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import {ReactElement} from "react";
import {useRecoilValue} from "recoil";
import {uiConfigState} from "store/uiConfig";

type Props = {
  packBMSNo: number;
};
const rowSize = window.innerWidth >= breakpoints.values.xl ? 2 : 1;
const FADE_MILLISECOND_TIME = 200;

function Modules({ packBMSNo }: Props): ReactElement {
  const { darkMode } = useRecoilValue(uiConfigState);
  const { moduleStatusData, isShow, modules } = useModule({
    packBMSNo,
  });
  const { getVirtualItems, getTotalSize, options } = useWindowVirtualizer({
    count: Math.ceil(modules.length / rowSize),
    estimateSize: () => 470,
  });

  const renderModuleRow = (virtualItem: VirtualItem) => {
    const startIndex = virtualItem.index * rowSize;
    const currentRowModules = modules.slice(startIndex, startIndex + rowSize);

    return (
      <Grid
        key={virtualItem.key}
        container
        position="absolute"
        spacing={1}
        sx={{
          transform: `translateY(${
            virtualItem.start - options.scrollMargin
          }px)`,
        }}
      >
        {currentRowModules.map((module, moduleIndex) =>
          renderModuleItem(module, moduleIndex + startIndex)
        )}
      </Grid>
    );
  };

  const renderModuleItem = (module: Se805, moduleIndex: number) => {
    const cells = module.repeatBlockList;
    const cellV = cells.map((cell) =>
      changeDecimalPoint(cell.cellV, module.fixedBlock.cellV_SF)
    );
    const cellVColors = cells.map((cell) => (cell.cellSt ? "warning" : darkMode ? "secondary" : "dark"));
    const cellTmp = cells
      .filter((cell, index) => index % 2 === 0)
      .map((cell, index) =>
        changeDecimalPoint(cell.cellTmp, module.fixedBlock.tmp_SF)
      );
    const cellTmpColors = cells
      .filter((cell, index) => index % 2 === 0)
      .map((cell) => (cell.cellTmp > 0 ? "error" : "primary"));
    const moduleStatus = moduleStatusData
      ? moduleStatusData.moduleIsoSpiStatusList[moduleIndex]
      : false;

    return (
      <ModuleCard
        key={moduleIndex}
        module={module}
        moduleIndex={moduleIndex}
        moduleStatus={moduleStatus}
        cellVMinCell={module.fixedBlock.cellVMinCell}
        cellVMin={changeDecimalPoint(
          module.fixedBlock.cellVMin,
          module.fixedBlock.cellV_SF
        )}
        cellVMaxCell={module.fixedBlock.cellVMaxCell}
        cellVMax={changeDecimalPoint(
          module.fixedBlock.cellVMax,
          module.fixedBlock.cellV_SF
        )}
        cellVAvg={changeDecimalPoint(
          module.fixedBlock.cellVAvg,
          module.fixedBlock.cellV_SF
        )}
        cellVColors={cellVColors}
        cellV={cellV}
        cellVSF={module.fixedBlock.cellV_SF}
        cellTmpMinCell={module.fixedBlock.cellTmpMinCell}
        cellTmpMin={changeDecimalPoint(
          module.fixedBlock.cellTmpMin,
          module.fixedBlock.tmp_SF
        )}
        cellTmpMaxCell={module.fixedBlock.cellTmpMaxCell}
        cellTmpMax={changeDecimalPoint(
          module.fixedBlock.cellTmpMax,
          module.fixedBlock.tmp_SF
        )}
        cellTmpAvg={changeDecimalPoint(
          module.fixedBlock.cellTmpAvg,
          module.fixedBlock.tmp_SF
        )}
        cellTmpColors={cellTmpColors}
        cellTmp={cellTmp}
        tmpSF={module.fixedBlock.tmp_SF}
      />
    );
  };

  return (
    <Fade in={isShow} timeout={{ enter: FADE_MILLISECOND_TIME }}>
      <MDBox height={getTotalSize()} position="relative">
        {getVirtualItems().map(renderModuleRow)}
      </MDBox>
    </Fade>
  );
}

export default Modules;
