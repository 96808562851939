import ButtonGroup from "@mui/material/ButtonGroup";
import pxToRem from "assets/theme/functions/pxToRem";
import MDButton from "components/MDButton";
import { Colors } from "lib/types/material";
import React, { MouseEvent } from "react";

type Props = {
  color: [Colors, Colors];
  onClick: [
    (e: MouseEvent<HTMLButtonElement>) => void,
    (e: MouseEvent<HTMLButtonElement>) => void
  ];
  disabled: [boolean, boolean];
  icon: [React.ReactNode, React.ReactNode];
};

const ButtonSX = { width: pxToRem(130) };

function ControlButtonGroup({ color, onClick, disabled, icon }: Props) {
  return (
    <ButtonGroup size="medium" disableElevation variant="text">
      <MDButton
        sx={ButtonSX}
        color={color[0]}
        variant="gradient"
        onClick={onClick[0]}
        disabled={disabled[0]}
      >
        {icon[0]}
      </MDButton>
      <MDButton
        sx={ButtonSX}
        color={color[1]}
        variant="gradient"
        onClick={onClick[1]}
        disabled={disabled[1]}
      >
        {icon[1]}
      </MDButton>
    </ButtonGroup>
  );
}

export default ControlButtonGroup;
