import { TFunction } from "i18next";
import {
  AMP_SALT,
  CELL_TEMPERATURE_MAX,
  CELL_TEMPERATURE_MIN,
  CELL_VOLTAGE_MAX,
  CELL_VOLTAGE_MIN,
  KW_SALT,
  VOLTAGE_SALT,
} from "lib/consts/common";
import dashboard from "lib/consts/dashboard";
import {
  Se802,
  Se803FixedBlock,
  SystemBMSStatus,
} from "lib/types/dashboard";
import {
  changeDecimalPoint,
  getProgressValue,
  getDateTime,
  sunssf,
} from "./common";
import {PackBMSData} from "../types/pack";

export const getDashboardStatusColor = ({
  buttonName,
  se802,
  systemBMSStatus,
}: {
  buttonName: string;
  se802: Se802;
  systemBMSStatus?: SystemBMSStatus;
}) => {
  switch (buttonName) {
    case "charge_capacity_button":
      return "info";
    case "energy_capacity_button":
      return "info";
    case "max_charge_rate_button":
      return "info";
    case "max_discharge_rate_button":
      return "info";
    case "max_soc_button":
      return "info";
    case "min_soc_button":
      return "info";
    case "max_reserve_soc_button":
      return "info";
    case "min_reserve_soc_button":
      return "info";
    case "write_alarm_reset_button":
      return "success";
    case "write_contactor_connect_button":
      if (se802.ctlOp === dashboard.se802SetOp.CONNECT) {
        return "success";
      } else {
        if (se802.ctlOp === dashboard.se802SetOp.DISCONNECT) {
          return "error";
        }
      }
      return "error";
    case "write_balancing_button":
      if (systemBMSStatus?.ctlBal === dashboard.se804SetBal.ENABLE) {
        return "success";
      } else if (systemBMSStatus?.ctlBal === dashboard.se804SetBal.DISABLE) {
        return "error";
      } else {
        return "error";
      }
    case "write_warning_light_button":
      if (systemBMSStatus?.ctlWarnLight === dashboard.se1001SetWarnLight.ON) {
        return "warning";
      } else if (systemBMSStatus?.ctlWarnLight === dashboard.se1001SetWarnLight.OFF) {
        return "error";
      } else {
        return "light";
      }
    case "heartbeat_button":
      return systemBMSStatus?.status ? "success" : "error";
    case "pms_heartbeat_button":
      return se802.ctrlHb === 0 ? "error" : "success";
    case "inverter_status_button":
      if (se802.ctlInvState === 1) { //STOPPED
        return "error";
      } else if (se802.ctlInvState === 2) { //STANDBY
        return "info";
      } else if (se802.ctlInvState === 3) { //STARTED
        return "success";
      } else {
        return "error";
      }
    case "state_button":
      if (
        se802.state === dashboard.se802State.FAULT ||
        se802.state === dashboard.se802State.DISCONNECTED
      ) {
        return "error";
      } else if (
        se802.state === dashboard.se802State.INITIALIZING ||
        se802.state === dashboard.se802State.SUSPENDING
      ) {
        return "warning";
      } else if (se802.state === dashboard.se802State.CONNECTED) {
        return "success";
      } else {
        return "error";
      }
    case "operate_state_button":
      if (se802.stateVnd === dashboard.se802StateVnd.NONE) {
        return "error";
      } else if (se802.stateVnd === dashboard.se802StateVnd.NORMAL) {
        return "success";
      } else if (se802.stateVnd === dashboard.se802StateVnd.PARTIAL_OPERATION) {
        return "warning";
      } else {
        return "error";
      }
    case "emergency_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.EMERGENCY_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "charge_button":
      if (se802.chaSt === dashboard.se802ChaSt.CHARGING) {
        return "success";
      } else {
        return "error";
      }
    case "discharge_button":
      if (se802.chaSt === dashboard.se802ChaSt.DISCHARGING) {
        return "success";
      } else {
        return "error";
      }
    case "pre_charge_button":
      if (se802.chaSt === dashboard.se802ChaSt.OFF) {
        return "success";
      } else {
        return "error";
      }
    case "pack_iso_spi_communication_button":
      if (se802.evt1 & dashboard.se802Evt1.COMMUNICATION_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_24v_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MISMATCH_INTERNAL_24V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_5v_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MISMATCH_INTERNAL_5V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_internal_voltage_3_3v_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MISMATCH_INTERNAL_3_3V_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_contactor_error_button":
      if (se802.evt1 & dashboard.se802Evt1.CONTACTOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_ground_fault_button":
      if (se802.evt1 & dashboard.se802Evt1.GROUND_FAULT) {
        return "error";
      } else {
        return "success";
      }
    case "pack_current_sensor_error_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.CURRENT_SENSOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_temperature_sensor_error_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.TEMPERATURE_SENSOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "pack_current_mismatch_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MISMATCH_CURRENT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_voltage_mismatch_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MISMATCH_VOLT_ALARM) {
        return "error";
      } else {
        return "success";
      }
    case "pack_over_voltage_button":
      if (se802.evt1 & dashboard.se802Evt1.OVER_VOLT_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.OVER_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_voltage_button":
      if (se802.evt1 & dashboard.se802Evt1.UNDER_VOLT_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.UNDER_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_temperature_button":
      if (se802.evt1 & dashboard.se802Evt1.OVER_TEMP_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.OVER_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_temperature_button":
      if (se802.evt1 & dashboard.se802Evt1.UNDER_TEMP_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.UNDER_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_imbalance_temperature_button":
      if (se802.evt1 & dashboard.se802Evt1.TEMPERATURE_IMBALANCE_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.TEMPERATURE_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_charge_current_button":
      if (se802.evt1 & dashboard.se802Evt1.OVER_CHARGE_CURRENT_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.OVER_CHARGE_CURRENT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_discharge_current_button":
      if (se802.evt1 & dashboard.se802Evt1.OVER_DISCHARGE_CURRENT_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.OVER_DISCHARGE_CURRENT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_over_soc_button":
      if (se802.evt1 & dashboard.se802Evt1.OVER_SOC_MAX_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.OVER_SOC_MAX_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "pack_under_soc_button":
      if (se802.evt1 & dashboard.se802Evt1.UNDER_SOC_MIN_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.UNDER_SOC_MIN_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_imbalance_voltage_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MODULE_VOLT_IMBALANCE_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.MODULE_VOLT_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_over_temperature_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.OVER_MODULE_TEMP_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.OVER_MODULE_TEMP_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "module_under_temperature_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.UNDER_MODULE_TEMP_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.UNDER_MODULE_TEMP_ALARM) {
        return "warning";
      } else {
        return "success";
      }
    case "module_imbalance_temperature_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.MODULE_TEMP_IMBALANCE_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.MODULE_TEMP_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_over_voltage_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.OVER_CELL_VOLT_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.OVER_CELL_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_under_voltage_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.UNDER_CELL_VOLT_ALARM) {
        return "error";
      } else if (se802.evtVnd1 & dashboard.se802EvtVnd1.UNDER_CELL_VOLT_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "cell_imbalance_voltage_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.CELL_VOLT_IMBALANCE_ALARM) {
        return "error";
      } else if (se802.evt1 & dashboard.se802Evt1.VOLTAGE_IMBALANCE_WARNING) {
        return "warning";
      } else {
        return "success";
      }
    case "open_wire_error_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.OPEN_WIRE_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "rtc_error_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.RTC_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "fire_outbreak_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.FIRE_OUTBREAK) {
        return "error";
      } else {
        return "success";
      }
    case "fuse_open_button":
      if (se802.evtVnd1 & dashboard.se802EvtVnd1.FUSE_OPEN) {
        return "error";
      } else {
        return "success";
      }
    case "open_door_button":
      if (se802.evtVnd1 & dashboard.se802Evt1.OPEN_DOOR_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "fan_button":
      if (se802.evt1 & dashboard.se802Evt1.FAN_ERROR) {
        return "error";
      } else {
        return "success";
      }
    case "firmware_update_button":
      if (systemBMSStatus?.firmwareFileExist) {
        return "success";
      } else {
        return "light";
      }
    default:
      return "light";
  }
};

export const getDashboardVoltageMax = ({
  packBMSDataList,
}: {
  packBMSDataList: PackBMSData[];
}) =>
    packBMSDataList ? Math.max(
    ...packBMSDataList.map(({ se804, se805List }) => {
      const numberOfModule = se804.fixedBlock.nmod;
      const numberOfCellsPerModule = (se805List.length > 0) ? Math.max(...se805List.map(({ fixedBlock }) => {
        return fixedBlock.ncell;
      })) : 0;
      return VOLTAGE_SALT * numberOfModule * numberOfCellsPerModule;
    })
  ) : 0;

export const getDashboardAmpMax = ({
  packBMSDataList,
}: {
  packBMSDataList: PackBMSData[],
}) => AMP_SALT * packBMSDataList?.length;

export const getDashboardProgressItems = (
  se802: Se802,
  se803FixedBlock: Se803FixedBlock,
  packBMSDataList: PackBMSData[],
  t: TFunction<"Translation", undefined, "Translation">
) => {
  return [
    getProgressValue({
      value: se802?.soc,
      sf: se802?.soc_SF,
      title: t("System SoC"),
      color: "info",
      unit: "%",
    }),
    getProgressValue({
      value: se802?.a,
      sf: se802?.a_SF,
      max: getDashboardAmpMax({
        packBMSDataList,
      }),
      title: t("System current"),
      color: "error",
      unit: "A",
    }),
    getProgressValue({
      value: se802?.v,
      sf: se802?.v_SF,
      max: getDashboardVoltageMax({
        packBMSDataList,
      }),
      title: t("System DC link voltage"),
      color: "warning",
      unit: "V",
    }),
    getProgressValue({
      value: se803FixedBlock?.strVAvg,
      sf: se803FixedBlock?.v_SF,
      max: getDashboardVoltageMax({
        packBMSDataList,
      }),
      title: t("System string link voltage"),
      color: "success",
      unit: "V",
    }),
  ];
};

export const getDashboardChartItems = (
  se802: Se802,
  se803FixedBlock: Se803FixedBlock,
  packBMSDataList: PackBMSData[],
  t: TFunction<"Translation", undefined, "Translation">
) => {
  return [
    {
      title: t("String link voltage"),
      data: [
        changeDecimalPoint(se803FixedBlock?.strVMin, se803FixedBlock?.v_SF),
        changeDecimalPoint(se803FixedBlock?.strVAvg, se803FixedBlock?.v_SF),
        changeDecimalPoint(se803FixedBlock?.strVMax, se803FixedBlock?.v_SF),
      ],
      unit: "V",
      description: `${
        se803FixedBlock
          ? t("Min") +
          `: P ` + String(se803FixedBlock.strVMinStr + 1).padStart(2, "0") 
          + `\n` +
          t("Max") +
          `: P ` + String(se803FixedBlock.strVMaxStr + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: 0,
      yMax: getDashboardVoltageMax({
        packBMSDataList,
      }),
    },
    {
      title: `${t("Current")}`,
      data: [
        changeDecimalPoint(se803FixedBlock?.strAMin, se803FixedBlock?.a_SF),
        changeDecimalPoint(se803FixedBlock?.strAAvg, se803FixedBlock?.a_SF),
        changeDecimalPoint(se803FixedBlock?.strAMax, se803FixedBlock?.a_SF),
      ],
      unit: "A",
      description: `${
        se803FixedBlock
          ? t("Min") +
          `: P ` + String(se803FixedBlock.strAMinStr + 1).padStart(2, "0") 
          + `\n` +
            t("Max") +
          `: P ` + String(se803FixedBlock.strAMaxStr + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: AMP_SALT * -1,
      yMax: AMP_SALT,
    },
    {
      title: `${t("Cell voltage")}`,
      data: [
        changeDecimalPoint(se802?.cellVMin, se802?.cellV_SF),
        changeDecimalPoint(se802?.cellVAvg, se802?.cellV_SF),
        changeDecimalPoint(se802?.cellVMax, se802?.cellV_SF),
      ],
      unit: "V",
      description: `${
        se802
          ? t("Min") +
          `: P ` + String(se802.cellVMinStr + 1).padStart(2, "0") 
          + ` - M ` + String((se802.cellVMinMod >> 8) + 1).padStart(2, "0") 
          + ` - C ` +  String((se802.cellVMinMod & 0x00ff) + 1).padStart(2, "0") 
          + `\n` +
          t("Max") + 
          `: P ` + String(se802.cellVMaxStr + 1).padStart(2, "0") 
          + ` - M ` +  String((se802.cellVMaxMod >> 8) + 1).padStart(2, "0") 
          + ` - C ` + String((se802.cellVMaxMod & 0x00ff) + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: CELL_VOLTAGE_MIN,
      yMax: CELL_VOLTAGE_MAX,
    },
    {
      title: `${t("Module temperature")}`,
      data: [
        changeDecimalPoint(
          se803FixedBlock?.modTmpMin,
          se803FixedBlock?.modTmp_SF
        ),
        changeDecimalPoint(
          se803FixedBlock?.modTmpAvg,
          se803FixedBlock?.modTmp_SF
        ),
        changeDecimalPoint(
          se803FixedBlock?.modTmpMax,
          se803FixedBlock?.modTmp_SF
        ),
      ],
      unit: "ºC",
      description: `${
        se803FixedBlock
          ? t("Min") +
          `: P ` + String(se803FixedBlock.modTmpMinStr + 1).padStart(2, "0")
          + ` - M ` + String((se803FixedBlock.modTmpMinMod >> 8) + 1).padStart(2, "0")
          + ` - T ` +  String(Math.floor((se803FixedBlock.modTmpMinMod & 0x00ff) / 2) + 1).padStart(2, "0") 
          + `\n` +
          t("Max") +
          `: P ` + String(se803FixedBlock.modTmpMaxStr + 1).padStart(2, "0")
          + ` - M ` +  String((se803FixedBlock.modTmpMaxMod >> 8) + 1).padStart(2, "0")
          + ` - T ` + String(Math.floor((se803FixedBlock.modTmpMaxMod & 0x00ff) / 2) + 1).padStart(2, "0")
          : t("Min") + ": - \n" + t("Max") + ": - "
      }`,
      yMin: CELL_TEMPERATURE_MIN,
      yMax: CELL_TEMPERATURE_MAX,
    },
  ];
};

export const getKwChartData = (se802: Se802) => {
  if (!se802)
    return {
      label: "",
      data: 0,
    };

  const now = getDateTime();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");
  const second = String(now.getSeconds()).padStart(2, "0");
  const timestamp = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

  const w_SF = sunssf(se802.w_SF);
  const w_SF_Fixed = Math.abs(se802.w_SF);
  const kW = Math.abs(
    Number(Number(se802.w * w_SF * KW_SALT).toFixed(w_SF_Fixed))
  );
  return {
    label: timestamp,
    data: kW,
  };
};
