import Grid from "@mui/material/Grid";
import React, { memo } from "react";
import isEqual from "lodash.isequal";
import MemoBadge from "../memoBadge";
import pxToRem from "assets/theme/functions/pxToRem";
import { SystemButton } from "lib/hooks/useSystemButtons";

type Props = {
  buttons: SystemButton[];
};

const BadgeSX = {
  minWidth: pxToRem(230),
  minHeight: pxToRem(32),
  maxHeight: pxToRem(32),
};

function StatusButtons({ buttons }: Props) {
  return (
    <Grid container gap={1}>
      {buttons.map(({ title, color }, index) => {
        return (
          <MemoBadge
            key={index}
            title={title}
            color={color}
            width="100%"
            sx={BadgeSX}
          />
        );
      })}
    </Grid>
  );
}

export default memo(StatusButtons, (prevProps, nextProps) =>
  isEqual(prevProps.buttons, nextProps.buttons)
);
