import MDBadge from "components/MDBadge";
import DefaultInputCell from "layouts/components/dataTable/defaultInputCell";
import { EventLogColor } from "lib/types/log";
import React, {ReactElement, useMemo} from "react";
import { useTranslation } from "react-i18next";
import { CellProps } from "react-table";

export type TableColumnCell = { data: string; color?: EventLogColor };
export type TableRow = {
  type: TableColumnCell;
  date: string;
  description: TableColumnCell;
  status: TableColumnCell;
};
export type TableColumn = {
  Header: ReactElement | string;
  accessor: string;
  width: string;
  align: string;
  Cell: (row: CellProps<TableRow>) => ReactElement;
};
export type DataLogTable = {
  columns: TableColumn[];
  rows: TableRow[];
};

function useEventLogTableData() {
  const { t } = useTranslation("Translation")

  const eventLogTableData: DataLogTable = useMemo(() => {
    return {
      columns: [
        {
          Header: t("Type"),
          accessor: "type",
          width: "20%",
          align: "center",
          Cell: ({ value }: { value: TableColumnCell }) => {
            const { data } = value;
            return (
              <DefaultInputCell
                use={true}
                input={false}
                type="text"
                value={data}
              />
            );
          },
        },
        {
          Header: t("Date time"),
          accessor: "date",
          width: "30%",
          align: "center",
          Cell: ({ value }: { value: string }) => {
            return (
              <DefaultInputCell
                use={true}
                input={false}
                type="text"
                value={value.split("T").join(" ")}
              />
            );
          },
        },
        {
          Header: t("Description"),
          accessor: "description",
          width: "30%",
          align: "center",
          Cell: ({ value }: { value: TableColumnCell }) => {
            const { data } = value;
            return (
              <DefaultInputCell
                use={true}
                input={false}
                type="text"
                value={data}
              />
            );
          },
        },
        {
          Header: t("Status"),
          accessor: "status",
          width: "20%",
          align: "center",
          Cell: ({ value }: { value: TableColumnCell }) => {
            const { data, color } = value;
            const badgeColor = color === "DANGER" ? "error" : color;
            return (
              <MDBadge
                size="lg"
                color={badgeColor.toLowerCase()}
                badgeContent={data}
              />
            );
          },
        },
      ],
      rows: [] as TableRow[],
    };
  }, [t]);

  return {
    eventLogTableData,
  };
}

export default useEventLogTableData;
