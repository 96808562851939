import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { ControlType } from "lib/types/api";

export type BalancingSettingPayload = {
  criteria: string;
  threshold: number;
  tolerance: number;
  activePeriod: number;
  inactivePeriod: number;
  ratio: number;
  useNoBalancing: boolean;
  noBalancingMinCurrent: number;
  noBalancingMaxCurrent: number;
  useReservation: boolean;
  numberOfReservation: number;
  balancingReservationList: BalancingReservation[];
};

export type BalancingReservation = {
  startDateTime: string;
  endDateTime: string;
};

export type BalancingSettingRange = {
  criteria: string;
  threshold: number;
  tolerance: number;
  activePeriod: number;
  inactivePeriod: number;
  ratio: number;
};

export type BalancingSettingNoBalancing = {
  useNoBalancing: boolean;
  noBalancingMinCurrent: number;
  noBalancingMaxCurrent: number;
};

export type BalancingSettingReservation = {
  useReservation: boolean;
  numberOfReservation: number;
  balancingReservationList: BalancingReservation[];
};

const getData = async (packBMSNo: number) => {
  const response = await api.get(
    requestPathname.SYSTEM_BMS_PACK_DATA(packBMSNo)
  );
  return response.data;
};

const getStatus = async (packBMSNo: number) => {
  const response = await api.get(
    requestPathname.SYSTEM_BMS_PACK_STATUS(packBMSNo)
  );
  return response.data;
};

const control = async (
  packBMSNo: number,
  data: {
    type: ControlType;
    value: number;
    balancingSetting?: BalancingSettingPayload;
  }
) => {
  const response = await api.post(
    requestPathname.SYSTEM_BMS_PACK_CONTROL(packBMSNo),
    data
  );
  return response.data;
};

const overviewApi = {
  getStatus,
  getData,
  control,
};

export default overviewApi;
