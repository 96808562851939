import React, {ReactElement, useCallback, useContext, useEffect} from "react";

// @material-ui core components
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";

// Custom styles for DashboardNavbar
import { navbarRow } from "layouts/components/navbars/dashboardNavbar/styles";

// api
import runtimeApi from "apis/runtime/api";
import { runtimeCommand } from "lib/types/runtime";
import useWebSocket from "lib/hooks/useWebSocket";
import useApiRequest from "lib/hooks/useApiRequest";
import ControlButtonGroup from "../../controlButtonGroup";
import PopoverContext from "context/popover/popoverContext";
import { useTranslation } from "react-i18next";
import { getUserRole } from "lib/utils/common";
import { requestSocketPathname } from "lib/consts/socket";
import { SRuntime } from "lib/types/socket";
import { useRecoilState } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { userState } from "store/user";

// Declaring prop types
interface Props {
  isMini?: boolean;
}

function CommonControls({ isMini = false }: Props): ReactElement {
  const [systemConfig, setSystemConfig] = useRecoilState(systemConfigState);
  const [user] = useRecoilState(userState);
  const { runtime } = systemConfig;
  const { subscribe, isConnected } = useWebSocket();
  const ROLE = getUserRole(user);
  const {
    request: startStopControlRequest,
    isLoading: isStartStopControlLoading,
  } = useApiRequest();
  const {
    request: lockUnlockControlRequest,
    isLoading: isLockUnlockControlLoading,
  } = useApiRequest();
  const {
    request: localRemoteControlRequest,
    isLoading: isLocalRemoteControlLoading,
  } = useApiRequest();
  const { openPopover } = useContext(PopoverContext);
  const { t } = useTranslation("Translation");

  const initialApiRequest = useCallback(async () => {
    if (user) {
      if (!ROLE.ADMIN && !ROLE.CONTROL) return null;
      const {
        getStarted,
        getLock,
        getLocal,
        getStartStopProcessing,
        getLocalRemoteProcessing,
        getLockUnlockProcessing,
      } = runtimeApi;
      const runtimeInfo = await Promise.all<boolean>([
        getStarted(),
        getLock(),
        getLocal(),
        getStartStopProcessing(),
        getLockUnlockProcessing(),
        getLocalRemoteProcessing(),
      ]);
      const result = {
        isStart: runtimeInfo[0],
        isLock: runtimeInfo[1],
        isLocal: runtimeInfo[2],
        isStartStopProcessing: runtimeInfo[3],
        isLockUnlockProcessing: runtimeInfo[4],
        isLocalRemoteProcessing: runtimeInfo[5],
      };
      setSystemConfig((prev) => ({
        ...prev,
        runtime: result,
      }));
    }
  }, [user, ROLE.ADMIN, ROLE.CONTROL, setSystemConfig]);

  useEffect(() => {
    initialApiRequest().then();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;

    subscribe(requestSocketPathname.RUNTIME, (body: SRuntime) => {
      switch (body.commandStatus) {
        case "STARTED":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isStart: body.status,
            },
          }));
          break;
        case "START_STOP_PROCESSING":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isStartStopProcessing: body.status,
            },
          }));
          break;
        case "LOCK":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isLock: body.status,
            },
          }));
          break;
        case "LOCK_UNLOCK_PROCESSING":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isLockUnlockProcessing: body.status,
            },
          }));
          break;
        case "LOCAL":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isLocal: body.status,
            },
          }));
          break;
        case "LOCAL_REMOTE_PROCESSING":
          setSystemConfig((prev) => ({
            ...prev,
            runtime: {
              ...prev.runtime,
              isLocalRemoteProcessing: body.status,
            },
          }));
          break;
        default:
          break;
      }
    });
  }, [subscribe, isConnected, runtime, setSystemConfig]);

  const handleStart = useCallback(
    (start: boolean) => {
      startStopControlRequest({
        targetApi: () =>
          runtimeApi.command(
            start ? runtimeCommand.START : runtimeCommand.STOP
          ),
      });
    },
    [startStopControlRequest]
  );

  const handleLock = useCallback(
    (lock: boolean) => {
      lockUnlockControlRequest({
        targetApi: () =>
          runtimeApi.command(
            lock ? runtimeCommand.LOCK : runtimeCommand.UNLOCK
          ),
      });
    },
    [lockUnlockControlRequest]
  );

  const handleLocal = useCallback(
    (local: boolean) => {
      localRemoteControlRequest({
        targetApi: () =>
          runtimeApi.command(
            local ? runtimeCommand.LOCAL : runtimeCommand.REMOTE
          ),
      });
    },
    [localRemoteControlRequest]
  );

  if (!ROLE.ADMIN && !ROLE.CONTROL) return null;
  return (
    <MDBox
      pb={1}
      color="inherit"
      mb={{ xs: 1, md: 0 }}
      sx={(theme) => navbarRow(theme, { isMini })}
    >
      <MDBox pr={1} pl={1}>
        <ControlButtonGroup
          color={["success", "error"]}
          onClick={[
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleStart(true),
              }),
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleStart(false),
              }),
          ]}
          disabled={[
            runtime.isStart ||
              isStartStopControlLoading ||
              runtime.isStartStopProcessing,
            !runtime.isStart ||
              isStartStopControlLoading ||
              runtime.isStartStopProcessing,
          ]}
          icon={[
            <>
              <Icon>play_arrow</Icon>&nbsp; {t("Start")}
            </>,
            <>
              <Icon>stop</Icon>&nbsp; {t("Stop")}
            </>,
          ]}
        />
      </MDBox>
      <MDBox pr={1}>
        <ControlButtonGroup
          color={["success", "error"]}
          onClick={[
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleLock(true),
              }),
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleLock(false),
              }),
          ]}
          disabled={[
            runtime.isLock ||
              isLockUnlockControlLoading ||
              runtime.isLockUnlockProcessing,
            !runtime.isLock ||
              isLockUnlockControlLoading ||
              runtime.isLockUnlockProcessing,
          ]}
          icon={[
            <>
              <Icon>lock_open</Icon>&nbsp; {t("Lock")}
            </>,
            <>
              <Icon>lock</Icon>&nbsp; {t("Unlock")}
            </>,
          ]}
        />
      </MDBox>
      <MDBox pr={1}>
        <ControlButtonGroup
          color={["success", "error"]}
          onClick={[
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleLocal(true),
              }),
            (e) =>
              openPopover({
                event: e,
                onSuccess: () => handleLocal(false),
              }),
          ]}
          disabled={[
            runtime.isLocal ||
              isLocalRemoteControlLoading ||
              runtime.isLocalRemoteProcessing,
            !runtime.isLocal ||
              isLocalRemoteControlLoading ||
              runtime.isLocalRemoteProcessing,
          ]}
          icon={[
            <>
              <Icon>videogame_asset</Icon>&nbsp; {t("Local")}
            </>,
            <>
              <Icon>videogame_asset_off</Icon>&nbsp; {t("Remote")}
            </>,
          ]}
        />
      </MDBox>
    </MDBox>
  );
}

export default CommonControls;
