import storage from "../utils/storage";
import {storageKey} from "../consts/storageKey";
import {StorageAccount} from "store/user";
import {StorageUIConfig} from "store/uiConfig";

export const getCurrentUser = () => {
  return storage.get<StorageAccount>(storageKey.USER);
};

export const getCurrentUIConfig = () => {
  return storage.get<StorageUIConfig>(storageKey.UI_CONFIG);
};