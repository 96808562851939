import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import DefaultTextFieldCell from "layouts/components/dataTable/defaultTextFieldCell";
import DefaultInputCell from "layouts/components/dataTable/defaultInputCell";

export type TableData = {
  columns: {
    Header: string;
    accessor: string;
    width: string;
    align: string;
    Cell: ({ value }: any) => ReactElement;
  }[];
  rows: any[];
};

function useReservationTableData() {
  const { t } = useTranslation("Translation");

  const reservationTableData: TableData = {
    columns: [
      {
        Header: `${t("No.")}`,
        accessor: "no",
        width: "20%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val] = value;
          return (
            <DefaultInputCell
              use={true}
              input={false}
              index={index}
              type="text"
              label={t("No.")}
              value={val}
            />
          );
        },
      },
      {
        Header: `${t("Start date time")}`,
        accessor: "startDateTime",
        width: "40%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val, onChangeHandler] = value;
          return (
            <DefaultTextFieldCell
              use={true}
              index={index}
              id="datetime-local"
              type="datetime-local"
              name="startDateTime"
              label={t("Start date time")}
              value={val}
              onChangeHandler={onChangeHandler}
            />
          );
        },
      },
      {
        Header: `${t("End date time")}`,
        accessor: "endDateTime",
        width: "40%",
        align: "center",
        Cell: ({ value }: any) => {
          const [index, val, onChangeHandler] = value;
          return (
            <DefaultTextFieldCell
              use={true}
              index={index}
              id="datetime-local"
              type="datetime-local"
              name="endDateTime"
              label={t("End date time")}
              value={val}
              onChangeHandler={onChangeHandler}
            />
          );
        },
      },
    ],
    rows: [] as any[],
  };

  return {
    reservationTableData,
  };
}

export default useReservationTableData;
