import {useMemo, ReactNode, memo, ReactElement} from "react";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import ChartDataZoom from "chartjs-plugin-zoom";

// @mui material components
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import Config from "./Config";

// Base Styles
import colors from "assets/theme/base/colors";
import isEqual from "lodash.isequal";

// Declaring props types for DefaultLineChart
interface Props {
  icon?: {
    color?:
      | "white"
      | "black"
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "light"
      | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    datasets: {
      label: string;
      color:
        | "white"
        | "black"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning"
        | "error"
        | "light"
        | "dark";
      data: (number | string)[];
    }[];
  };
  [key: string]: any;
  chartOptions?: {
    yMax?: number;
    yMin?: number;
  };
}

function DefaultLineChart({
  icon,
  title,
  description,
  height,
  chart,
  chartOptions,
}: Props): ReactElement {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
        ...dataset,
        tension: 0.1,
        pointRadius: 1.2,
        borderWidth: 2,
        backgroundColor: "transparent",
        fill: true,
        pointBackgroundColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        borderColor: colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
        maxBarThickness: 6,
      }))
    : [];

  const { data, options } = Config(
    chart.labels || [],
    chartDatasets,
    chartOptions
  );

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox px={5} mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h4">{title}</MDTypography>}
            <MDBox mb={1}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Line data={data} options={options} plugins={[ChartDataZoom]} />
          </MDBox>
        ),
        [height, data, options]
      )}
    </MDBox>
  );

  return title || description ? <>{renderChart}</> : renderChart;
}

// Declaring default props DefaultLineChart
DefaultLineChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "17.5rem",//"19.125rem",
};

export default memo(DefaultLineChart, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
