import {ReactElement, ReactNode, useMemo} from "react";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartDataZoom from "chartjs-plugin-zoom";

// @mui material components
import Icon from "@mui/material/Icon";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// VerticalBarChart configurations
import Config from "./Config";

// Base Styles
import colors from "assets/theme/base/colors";

// Declaring props types for HorizontalBarChart
interface Props {
  icon?: {
    color?:
      | "white"
      | "black"
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning"
      | "error"
      | "light"
      | "dark";
    component: ReactNode;
  };
  title?: string;
  description?: string | ReactNode;
  height?: string | number;
  chart: {
    labels: string[];
    yMin?: number;
    yMax?: number;
    datasets: {
      label: string;
      color: (
        | "white"
        | "black"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning"
        | "error"
        | "light"
        | "dark"
      )[];
      data: number[];
    }[];
    dataLabelsRotation?: boolean;
  };

  [key: string]: any;
}

function MDVerticalBarChart({
  icon,
  title,
  description,
  height,
  chart,
}: Props): ReactElement {
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset, index) => ({
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: dataset.color.map((color) =>
          colors[color] ? colors[color].main : colors.dark.main
        ),
        fill: false,
        maxBarThickness: 100,
        min: chart.yMin,
        max: chart.yMax,
      }))
    : [];

  const { data, options } = Config({
    labels: chart.labels || [],
    datasets: chartDatasets,
    yMin: chart.yMin,
    yMax: chart.yMax,
    dataLabelsRotation: chart.dataLabelsRotation,
  });

  const renderChart = (
    <MDBox pr={1} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "info"}
              variant="gradient"
              coloredShadow={icon.color || "info"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h5">{title}</MDTypography>}
            <MDBox mb={1}>
              <MDTypography fontWeight="medium" color="text" variant="h6" style={{ whiteSpace: "pre-wrap"}}>
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Bar
              data={data}
              options={options}
              plugins={[ChartDataLabels, ChartDataZoom]}
            />
          </MDBox>
        ),
        [height, data, options]
      )}
    </MDBox>
  );

  return title || description ? <>{renderChart}</> : renderChart;
}

// Declaring default props for VerticalBarChart
MDVerticalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "18.075rem",//"19.125rem",
};

export default MDVerticalBarChart;
