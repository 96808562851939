import overviewApi from "apis/devices/packBMS/overview/api";
import { requestSocketPathname } from "lib/consts/socket";
import useApiRequest from "lib/hooks/useApiRequest";
import useWebSocket from "lib/hooks/useWebSocket";
import {
  Se805,
  PackBMSData, PackBMSStatus,
} from "lib/types/pack";
import { useCallback, useEffect, useState } from "react";

type Props = {
  packBMSNo: number;
};

const MODULE_FIXED_BLOCK_INIT_DATA = {
  cellTmpAvg: 0,
  cellTmpMax: 0,
  cellTmpMaxCell: 0,
  cellTmpMin: 0,
  cellTmpMinCell: 0,
  cellVAvg: 0,
  cellVMax: 0,
  cellVMaxCell: 0,
  cellVMin: 0,
  cellVMinCell: 0,
  cellV_SF: 0,
  dod: 0,
  dod_SF: 0,
  length: 42,
  modIdx: 0,
  modelID: 805,
  ncell: 0,
  ncellBal: 0,
  ncyc: 0,
  sn: "",
  soc: 0,
  soc_SF: 0,
  soh: 0,
  soh_SF: 0,
  strIdx: 0,
  tmp_SF: 0,
  v: 0,
  v_SF: 0,
};
const REPEAT_BLOCK_INIT_DATA = {
  cellSt: 0,
  cellTmp: 0,
  cellV: 0,
};

const MODULES_INIT_DATA = (numberOfModules: number, se805List: Se805[]) =>
  Array.from({
    length: numberOfModules,
  }).map(() => {
    const REPEAT_BLOCK_LIST = Array.from({
      length: se805List ? Math.max(...se805List.map(({ fixedBlock }) => {
        return fixedBlock.ncell;
      })) : 0,
    }).map(() => {
      return REPEAT_BLOCK_INIT_DATA;
    });
    return {
      fixedBlock: MODULE_FIXED_BLOCK_INIT_DATA,
      repeatBlockList: REPEAT_BLOCK_LIST,
    };
  });

function useModule({ packBMSNo }: Props) {
  const { subscribe, isConnected } = useWebSocket();
  const [isShow, setIsShow] = useState(false);
  const [moduleData, setModuleData] = useState<PackBMSData>();
  const [moduleStatusData, setModuleStatusData] = useState<PackBMSStatus>();
  const [modules, setModules] = useState<Se805[]>(MODULES_INIT_DATA(moduleData?.se804?.fixedBlock.nmod, moduleData?.se805List));
  const { request: moduleRequest } = useApiRequest<PackBMSData>();
  const { request: moduleStatusRequest } =
    useApiRequest<PackBMSStatus>();

  const init = useCallback(() => {
    setModules(MODULES_INIT_DATA(moduleData?.se804?.fixedBlock.nmod, moduleData?.se805List));
    setIsShow(true);
  }, [moduleData?.se804?.fixedBlock.nmod, moduleData?.se805List]);

  useEffect(() => {
    setIsShow(false);
    if (!moduleData || !moduleStatusData) return;
    if (!moduleStatusData.status) {
      init();
      return;
    }
    setModules(moduleData.se805List);
    setIsShow(true);
  }, [moduleData, moduleStatusData, init]);

  const moduleRequestOnSuccess = useCallback(
    (response: PackBMSData) => {
      setModuleData(response);
    },
    []
  );

  const moduleStatusRequestOnSuccess = useCallback(
    (response: PackBMSStatus) => {
      setModuleStatusData(response);
    },
    []
  );

  const initialApiRequest = useCallback(() => {
    moduleRequest({
      targetApi: () => overviewApi.getData(packBMSNo),
      onSuccess: (response) => moduleRequestOnSuccess(response as PackBMSData),
    });
    moduleStatusRequest({
      targetApi: () => overviewApi.getStatus(packBMSNo),
      onSuccess: (response) =>
        moduleStatusRequestOnSuccess(response as PackBMSStatus),
    });
  }, [
    moduleRequest,
    moduleStatusRequest,
    moduleRequestOnSuccess,
    moduleStatusRequestOnSuccess,
    packBMSNo,
  ]);

  useEffect(() => {
    initialApiRequest();
  }, [initialApiRequest]);

  useEffect(() => {
    if (!isConnected) return;
    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_DATA(packBMSNo),
      (body: PackBMSData) => {
        setModuleData(body);
      }
    );
    subscribe(
      requestSocketPathname.SYSTEM_BMS_TARGET_PACK_STATUS(packBMSNo),
      (body: PackBMSStatus) => {
        setModuleStatusData(body);
      }
    );
  }, [subscribe, isConnected, packBMSNo]);

  return { moduleStatusData, isShow, modules };
}

export default useModule;
