import {ReactElement, useEffect, useState} from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Components
import SidenavCollapse from "layouts/components/sidenav/sidenavCollapse";
import SidenavList from "layouts/components/sidenav/sidenavList";
import SidenavItem from "layouts/components/sidenav/sidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "layouts/components/sidenav/sidenavRoot";
import sidenavLogoLabel from "layouts/components/sidenav/styles/sidenav";

import CurrentDateTime from "../time/current";
import { CustomRoute } from "routes";
import { useRecoilState } from "recoil";
import { uiConfigState } from "store/uiConfig";
import isEqual from "lodash.isequal";
import throttle from "lodash.throttle";
import { Colors } from "lib/types/material";
import { INNER_WIDTH_LIMIT_BOUNDARY } from "lib/consts/common";

// Declaring props types for Sidenav
interface Props {
  color?: Colors;
  brand?: string;
  brandName: string;
  routes: CustomRoute[];
  brandMini?: string;
  [key: string]: any;
}

function Sidenav({
  color = "info",
  brand = "",
  brandName,
  version,
  brandMini,
  routes,
  ...rest
}: Props): ReactElement {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<
    boolean | string
  >(false);
  const [uiConfig, setUIConfig] = useRecoilState(uiConfigState);
  const { whiteSidenav, darkMode, transparentSidenav, miniSidenav } = uiConfig;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const textColor =
    (whiteSidenav && darkMode) ||
    (!darkMode && (transparentSidenav || whiteSidenav))
      ? "black"
      : "white";
  const isDividerColorLight =
    (!darkMode && !whiteSidenav && !transparentSidenav) ||
    (darkMode && !transparentSidenav && whiteSidenav);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setUIConfig((prev) => {
        const nextValue = {
          ...prev,
          miniSidenav:
            window.innerWidth < INNER_WIDTH_LIMIT_BOUNDARY,
          transparentSidenav:
            window.innerWidth < INNER_WIDTH_LIMIT_BOUNDARY
              ? false
              : prev.transparentSidenav,
          whiteSidenav:
            window.innerWidth < INNER_WIDTH_LIMIT_BOUNDARY
              ? false
              : prev.whiteSidenav,
        };
        const isChanged = !isEqual(prev, nextValue);
        if (isChanged) return nextValue;
        return prev;
      });
    }

    const throttledHandleMiniSidenav = throttle(handleMiniSidenav, 300);
    window.addEventListener("resize", throttledHandleMiniSidenav);
    handleMiniSidenav();

    return () =>
      window.removeEventListener("resize", throttledHandleMiniSidenav);
  }, [location, setUIConfig]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, key, href }: any) =>
      href ? (
        <Link key={key} href={href} target="_blank" rel="noreferrer">
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, route, href, key, icon }: any) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) =>
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
            icon={icon}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link href={href} key={key} target="_blank" rel="noreferrer">
            <SidenavItem
              color={color}
              name={name}
              active={key === itemName || key === items.join("/")}
              icon={icon}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavItem
              color={color}
              name={name}
              active={key === itemName || key === items.join("/")}
              icon={icon}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, collapse, noCollapse, key, route }) => {
      if (type === "collapse") {
        if (noCollapse && route) {
          return (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
                color={color}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        }
        return (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            color={color}
            open={openCollapse === key}
            onClick={() =>
              openCollapse === key
                ? setOpenCollapse(false)
                : setOpenCollapse(key)
            }
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      }

      if (type === "title") {
        return (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="none"
            pl={2}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      }
      if (type === "divider") {
        return (
          <Divider
            key={key}
            light={isDividerColorLight}
            sx={{ height: "1px" }}
          />
        );
      }

      return <></>;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{
        transparentSidenav,
        whiteSidenav,
        miniSidenav,
        darkMode,
      }}
    >
      {miniSidenav && (
        <MDBox
          pt={2}
          px={2.5}
          component="img"
          src={brandMini}
          alt="Brand-Mini"
          width="100%"
        />
      )}
      {!miniSidenav && (
        <MDBox pt={2} pb={1} px={4} textAlign="center">
          <MDBox component={NavLink} to="/" display="flex" alignItems="center">
            {brand && (
              <MDBox component="img" src={brand} alt="Brand" width="100%" />
            )}
          </MDBox>
          <MDBox
            pt={2}
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
          >
            <MDBox
              alignItems="center"
              width="100%"
              sx={(theme: any) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <MDTypography
                align="center"
                component="h6"
                variant="button"
                fontWeight="medium"
                color={textColor}
              >
                {brandName}
              </MDTypography>
              <MDTypography
                align="center"
                component="h6"
                variant="button"
                fontWeight="medium"
                color={textColor}
              >
                {version}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
      {!miniSidenav && <CurrentDateTime color={textColor} />}
      <Divider />
      <List>{renderRoutes}</List>
      <Divider />
    </SidenavRoot>
  );
}

export default Sidenav;
