import MDBox from "components/MDBox";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, {ReactElement} from "react";
import { useTranslation } from "react-i18next";
import useEnvironment from "./hooks/useEnvironment";
import ControlButtons from "layouts/components/controlButtons";
import StatusButtons from "layouts/components/statusButtons";

type Props = {
  packBMSNo: number;
};

function Environment({ packBMSNo }: Props): ReactElement {
  const {
    isUseSensor,
    isUseFan,
    sensorButtons,
    fanButtons,
    isControlButtonsLoading,
    ROLE,
    handleClickButton,
  } = useEnvironment({ packBMSNo });
  const { t } = useTranslation("Translation");

  return (
    <MDBox>
      {isUseSensor && (
        <MDBox mb={1}>
          <DefaultCard icon="build" title={t("Sensor")}>
            <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
              <ControlButtons
                buttons={sensorButtons.sensorControl}
                isLoading={isControlButtonsLoading}
                onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "SENSOR") : null}
              />
            </MDBox>
            <MDBox borderBottom="1px dotted gray" pb={2} mb={1} mx={2}>
              <StatusButtons buttons={sensorButtons.sensorTemperature}/>
            </MDBox>
            <MDBox mb={1} mx={2}>
              <StatusButtons buttons={sensorButtons.sensorOther}/>
            </MDBox>
          </DefaultCard>
        </MDBox>
      )}
      {isUseFan && (
        <MDBox mb={1}>
          <DefaultCard icon="memory" title={t("Fan")}>
            <MDBox mb={1} mx={2}>
              <ControlButtons
                buttons={fanButtons.fan}
                isLoading={isControlButtonsLoading}
                onClick={(e) => (ROLE.ADMIN || ROLE.CONTROL) ? handleClickButton(e, "FAN") : null}
              />
            </MDBox>
          </DefaultCard>
        </MDBox>
      )}
    </MDBox>
  );
}

export default Environment;
