import CustomPopover from "layouts/components/customPopover";
import React, { PropsWithChildren, useState } from "react";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import PopoverContext, { PopoverType } from "./popoverContext";

function PopoverProvider({ children }: PropsWithChildren) {
  const { runtime } = useRecoilValue(systemConfigState);
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [onSuccess, setOnSuccess] = useState<(input?: string) => void>(() => {});
  const [popoverType, setPopoverType] = useState<PopoverType>();
  const [text, setText] = useState<string>();

  const open = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const success = (input?: string) => {
    setAnchorEl(null);
    setOnSuccess(() => {});
    onSuccess(input);
  };

  const close = () => {
    setAnchorEl(null);
    setOnSuccess(() => {});
  };

  const openPopover = ({
    type,
    text,
    event,
    onSuccess,
  }: {
    type?: PopoverType;
    text?: string;
    event: React.MouseEvent<HTMLElement>;
    onSuccess: () => void;
  }) => {
    if (type === undefined || type === "PASSWORD") {
      if (runtime.isLock) {
        open(event);
        setOnSuccess(() => onSuccess);
        setPopoverType(type);
        if (text) {
          setText(text);
        }
        return;
      }
    } else {
      open(event);
      setOnSuccess(() => onSuccess);
      setPopoverType(type);
      if (text) {
        setText(text);
      }
      return;
    }
    onSuccess();
  };

  return (
    <PopoverContext.Provider value={{ anchorEl, openPopover }}>
      {children}
      <CustomPopover
        AnchorElement={anchorEl}
        popoverType={popoverType}
        text={text}
        onSuccess={(input?: string) => success(input)}
        onClose={close}
      />
    </PopoverContext.Provider>
  );
}

export default PopoverProvider;
