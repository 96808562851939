import Grid from "@mui/material/Grid";
import React, { memo, MouseEvent } from "react";
import isEqual from "lodash.isequal";
import MemoButton from "../memoButton";
import pxToRem from "assets/theme/functions/pxToRem";
import { SystemButton } from "lib/hooks/useSystemButtons";

type Props = {
  buttons: SystemButton[];
  isLoading: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

const ButtonSX = {
  minWidth: pxToRem(230),
  minHeight: pxToRem(32),
  maxHeight: pxToRem(32),
};

function ControlButtons({ buttons, isLoading, onClick }: Props) {
  return (
    <Grid container gap={1}>
      {buttons.map(({ name, color, title }, index) => {
        return (
          <MemoButton
            key={index}
            title={title}
            name={name}
            color={color}
            onClick={onClick}
            disabled={isLoading || color === "light"}
            sx={ButtonSX}
          />
        );
      })}
    </Grid>
  );
}

export default memo(ControlButtons, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
