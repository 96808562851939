import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import packBMS from "lib/consts/pack";

export type BalancingSelectItem = {
  name: string;
  title: string;
  options: { name: string; value: string | number }[];
  defaultValue?: string | number;
  defaultOptions?: { name: string; value: string | number }[];
};

function useBalancingSelect() {
  const { t } = useTranslation("Translation");
  const balancingSelectItems: BalancingSelectItem[] = useMemo(
    () => {
      const CRITERIA_OPTIONS = [
        {
          name: `${t("System BMS")}`,
          value: packBMS.balancingCriteria.SYSTEM_BMS,
        },
        {
          name: `${t("Pack BMS")}`,
          value: packBMS.balancingCriteria.PACK_BMS,
        }
      ]
      const THRESHOLD_OPTIONS = Array.from({ length: 51 }).map((_, index) => {
        return {
          name: String(index * 10 + 1100),
          value: index * 10 + 1100,
        };
      });

      const TOLERANCE_OPTIONS = Array.from({ length: 101 }).map((_, index) => {
        return {
          name: String(index),
          value: index,
        };
      });

      const PERIOD_OPTIONS = Array.from({ length: 61 }).map((_, index) => {
        return {
          name: String(index),
          value: index,
        };
      });

      const RATIO_OPTIONS = Array.from({ length: 101 }).map((_, index) => {
        return {
          name: String(index),
          value: index,
        };
      });

      const DEFAULT_CRITERIA_OPTIONS = [{ name: `${t("None")}`, value: packBMS.balancingCriteria.NONE }];
      const DEFAULT_RANGE_OPTIONS = [{ name: "∞", value: -1 }];
      return [
      {
        name: "criteria",
        title: `${t("Criteria")}`,
        options: CRITERIA_OPTIONS,
        defaultOptions: DEFAULT_CRITERIA_OPTIONS,
      },
      {
        name: "threshold",
        title: `${t("Threshold")} (mv)`,
        options: THRESHOLD_OPTIONS,
        defaultOptions: DEFAULT_RANGE_OPTIONS,
      },
      {
        name: "tolerance",
        title: `${t("Tolerance")} (mv)`,
        options: TOLERANCE_OPTIONS,
        defaultOptions: DEFAULT_RANGE_OPTIONS,
      },
      {
        name: "activePeriod",
        title: `${t("Active period")} (s)`,
        options: PERIOD_OPTIONS,
        defaultOptions: DEFAULT_RANGE_OPTIONS,
      },
      {
        name: "inactivePeriod",
        title: `${t("Inactive period")} (s)`,
        options: PERIOD_OPTIONS,
        defaultOptions: DEFAULT_RANGE_OPTIONS,
      },
      {
        name: "ratio",
        title: `${t("Ratio")} (%)`,
        options: RATIO_OPTIONS,
        defaultOptions: DEFAULT_RANGE_OPTIONS,
      },
    ]},
    [t]
  );

  return { balancingSelectItems };
}

export default useBalancingSelect;
