import MDVerticalBarChart from "components/MDChart/VerticalBar";
import { Colors } from "lib/types/material";
import isEqual from "lodash.isequal";
import React, { memo, ReactNode } from "react";

interface Props {
  title?: string;
  description?: string | ReactNode;
  chart: {
    labels: string[];
    yMin?: number;
    yMax?: number;
    datasets: {
      label: string;
      color: Colors[];
      data: number[];
    }[];
    dataLabelsRotation?: boolean;
  };

  [key: string]: any;
}

function MemoBarChart({ title, description, chart }: Props) {
  return (
    <MDVerticalBarChart title={title} description={description} chart={chart} />
  );
}

export default memo(MemoBarChart, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
