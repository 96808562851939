import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import MDCheckBox from "components/MDCheckBox";

type Props = {
  title: string;
  isCheck: boolean;
  urlValue: string;
  onChangeCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  urlOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const GridSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed !important" : "default",
});

function WebHookCard({
  title,
  isCheck,
  urlValue,
  onChangeCheck,
  urlOnChange,
}: Props) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="webhook" title={title}>
      <MDBox mb={1} mx={6}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Enable")}
            </MDTypography>
          </Grid>
          <Grid item sm={1} textAlign="center">
            <MDCheckBox
              name= { "useWebHook" }
              checked={isCheck}
              onChange={onChangeCheck}
            />
          </Grid>
          <Grid item sm={2}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("URL")}
            </MDTypography>
          </Grid>
          <Grid item sm={8} sx={GridSX(!isCheck)}>
            <MDInput
              type="text"
              label={t("URL")}
              name="webHookUrl"
              value={urlValue}
              onChange={urlOnChange}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </DefaultCard>
  );
}

export default WebHookCard;
