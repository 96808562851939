import { Grid } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";
import MemoButton from "layouts/components/memoButton";
import { QCPackBMS } from "lib/types/qc";
import { PackBMSStatus } from "lib/types/pack";
import isEqual from "lodash.isequal";
import React, { MouseEvent, memo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isActive: boolean;
  isCell: boolean;
  isLoading: boolean;
  packBMSList: QCPackBMS[];
  packBMSStatus?: PackBMSStatus[];
  onClick: (
      e: MouseEvent<HTMLButtonElement>,
      target?: ButtonTargetPayload
  ) => void;
};

export type ButtonTargetPayload = {
  packBMSIndex?: number;
  moduleIndex?: number;
  moduleSecondIndex?: number;
  packColorList?: ("success" | "error" | "light" | "warning")[][];
  start: boolean;
};

function ControlButtons({
  isActive,
  isCell,
  isLoading,
  packBMSList,
  packBMSStatus,
  onClick,
}: Props) {
  const { t } = useTranslation("Translation");
  const packColorList = packBMSList ? packBMSList.map(({ qcModuleList }, packBMSIndex) => {
    return qcModuleList
    .map((module, moduleIndex) => {
      const moduleColor = packBMSStatus ? packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
          moduleIndex
          ]
          ? module.start
              ? "success"
              : "error"
          : "light" : "light";
      if (!isCell) return isActive ? moduleColor : "light";
      if (moduleIndex % 2) return null;
      const isLastOddItem =
          qcModuleList.length % 2 === 1 &&
          qcModuleList.length - 1 === moduleIndex;
      const isModuleWarning =
          isLastOddItem || !isActive
              ? false
              : (packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                      moduleIndex
                      ] ||
                  packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                  moduleIndex + 1
                      ]) &&
              !(
                  packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                      moduleIndex
                      ] &&
                  packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                  moduleIndex + 1
                      ]
              );

      const color = isActive
          ? isModuleWarning
              ? "warning"
              : moduleColor
          : "light";
      return color;
    })
    .filter((module) => module);
  }) : null;

  const systemBMSColor = packColorList ? (packColorList.some((pack) =>
      pack.some((moduleColor) => moduleColor === "error")
  )
      ? "error"
      : "success") : null;

  return (
      <MDBox ml={2} mb={1} display="flex">
        <MemoButton
            title={t("All")}
            name="systemBMS"
            onClick={(e) =>
                onClick(e, {
                  start: systemBMSColor === "success",
                  packColorList,
                })
            }
            sx={{
              width: pxToRem(110),
              minWidth: pxToRem(110),
              minHeight: pxToRem(38),
            }}
            color={isActive ? systemBMSColor : "light"}
            disabled={isLoading || !isActive}
        />
        <MDBox ml={1} display="flex" flexDirection="column" >
          {packBMSList?.map(({ qcModuleList }, packBMSIndex) => {
            const packColor = packColorList[packBMSIndex].some(
                (moduleColor) => moduleColor === "error"
            )
                ? "error"
                : "success";

            return (
                <MDBox key={packBMSIndex} display="flex" mb={(packBMSIndex !== (packBMSList?.length - 1)) ? 1 : 0}>
                  <MemoButton
                      title={`${t("Pack BMS")} #${String(packBMSIndex + 1).padStart(
                          2,
                          "0"
                      )}`}
                      name="packBMS"
                      onClick={(e) =>
                          onClick(e, {
                            packBMSIndex,
                            start: packColor === "success" ? true : false,
                            packColorList,
                          })
                      }
                      sx={{
                        width: pxToRem(110),
                        minWidth: pxToRem(110),
                        minHeight: pxToRem(38),
                      }}
                      color={isActive ? packColor : "light"}
                      disabled={isLoading}
                  />
                  <Grid container gap={0.5} mx={1}>
                    {isCell
                        ? qcModuleList.map((_, moduleIndex) => {
                          if (moduleIndex % 2) return null;
                          const moduleColor =
                              packColorList[packBMSIndex][
                                  Math.floor(moduleIndex / 2)
                                  ];
                          const isLastOddItem =
                              qcModuleList.length % 2 === 1 &&
                              qcModuleList.length - 1 === moduleIndex;
                          const isWarningModule = moduleColor === "warning";
                          const isModuleOn =
                              (isLastOddItem
                                  ? packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                                      moduleIndex
                                      ]
                                  : packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                                      moduleIndex
                                      ] ||
                                  packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                                  moduleIndex + 1
                                      ]) === true;

                          return (
                              <MemoButton
                                  key={moduleIndex}
                                  title={
                                    isLastOddItem
                                        ? `#${String(moduleIndex + 1).padStart(2, "0")}`
                                        : `#${String(moduleIndex + 1).padStart(
                                            2,
                                            "0"
                                        )} #${String(moduleIndex + 2).padStart(2, "0")}`
                                  }
                                  name="module"
                                  disabled={isLoading || !isModuleOn || isWarningModule}
                                  onClick={(e) =>
                                      isModuleOn &&
                                      !isWarningModule &&
                                      onClick(
                                          e,
                                          isLastOddItem
                                              ? {
                                                packBMSIndex,
                                                moduleIndex: moduleIndex,
                                                start:
                                                    moduleColor === "success" ? true : false,
                                                packColorList,
                                              }
                                              : {
                                                packBMSIndex,
                                                moduleIndex: moduleIndex,
                                                moduleSecondIndex: moduleIndex + 1,
                                                start:
                                                    moduleColor === "success" ? true : false,
                                                packColorList,
                                              }
                                      )
                                  }
                                  sx={{
                                    width: pxToRem(92),
                                    minWidth: pxToRem(92),
                                    minHeight: pxToRem(38),
                                  }}
                                  color={isActive && isModuleOn ? moduleColor : "light"}
                              />
                          );
                        })
                        : qcModuleList.map((_, moduleIndex) => {
                          const moduleColor =
                              packColorList[packBMSIndex][moduleIndex];
                          const isModuleOn =
                              isActive &&
                              packBMSStatus[packBMSIndex].moduleIsoSpiStatusList[
                                  moduleIndex
                                  ];

                          return (
                              <MemoButton
                                  key={moduleIndex}
                                  title={`${moduleIndex + 1}`}
                                  name="module"
                                  onClick={(e) =>
                                      onClick(e, {
                                        packBMSIndex,
                                        moduleIndex,
                                        start: moduleColor === "success" ? true : false,
                                        packColorList,
                                      })
                                  }
                                  sx={{
                                    width: pxToRem(92),
                                    minWidth: pxToRem(92),
                                    minHeight: pxToRem(38),
                                  }}
                                  color={isActive && isModuleOn ? moduleColor : "light"}
                                  disabled={isLoading || !isModuleOn}
                              />
                          );
                        })}
                  </Grid>
                </MDBox>
            );
          })}
        </MDBox>
      </MDBox>
  );
}

export default memo(ControlButtons, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
