import { Colors } from "lib/types/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export type SystemButtons = {
  dashboardNamePlate: SystemButton[];
  dashboardControl: SystemButton[];
  dashboardHeartbeat: SystemButton[];
  dashboardStatus: SystemButton[];
  packControl: SystemButton[];
  packBalancingMode?: SystemButton[];
  packBalancingCondition?: SystemButton[];
  packStatus: SystemButton[];
  packContactorStatus: SystemButton[];
  statusDetail: SystemButton[];
  firmwareUpdate: SystemButton[];
  sensorControl: SystemButton[];
  sensorTemperature: SystemButton[];
  sensorOther: SystemButton[];
  fan: SystemButton[];
};

export type SystemButton = {
  name: string;
  title: string;
  color: Colors;
};

function useSystemButtons() {
  const { t } = useTranslation("Translation");

  const systemButtons: SystemButtons = useMemo(
    () => ({
      dashboardNamePlate: [
        {
          name: "charge_capacity_button",
          title: t("Charge capacity"),
          color: "light",
        },
        {
          name: "energy_capacity_button",
          title: t("Energy capacity"),
          color: "light",
        },
        {
          name: "max_charge_rate_button",
          title: t("Max charge rate"),
          color: "light",
        },
        {
          name: "max_discharge_rate_button",
          title: t("Max discharge rate"),
          color: "light",
        },
        {
          name: "max_soc_button",
          title: t("Max SoC"),
          color: "light",
        },
        {
          name: "min_soc_button",
          title: t("Min SoC"),
          color: "light",
        },
        {
          name: "max_reserve_soc_button",
          title: t("Max reserve SoC"),
          color: "light",
        },
        {
          name: "min_reserve_soc_button",
          title: t("Min reserve SoC"),
          color: "light",
        },
      ],
      dashboardControl: [
        {
          name: "write_alarm_reset_button",
          title: t("Alarm reset"),
          color: "light",
        },
        {
          name: "write_contactor_connect_button",
          title: t("Contactor connect"),
          color: "light",
        },
        {
          name: "write_balancing_button",
          title: t("Balancing"),
          color: "light",
        },
        {
          name: "write_warning_light_button",
          title: t("Warning light"),
          color: "light",
        },
      ],
      dashboardHeartbeat: [
        {
          name: "heartbeat_button",
          title: t("Heartbeat"),
          color: "light",
        },
        {
          name: "pms_heartbeat_button",
          title: t("PMS Heartbeat"),
          color: "light",
        },
        {
          name: "inverter_status_button",
          title: t("Inverter status"),
          color: "light",
        },
      ],
      dashboardStatus: [
        {
          name: "state_button",
          title: t("System status"),
          color: "light",
        },
        {
          name: "operate_state_button",
          title: t("Operate status"),
          color: "light",
        },
        {
          name: "emergency_button",
          title: t("Emergency"),
          color: "light",
        },
        {
          name: "charge_button",
          title: t("Charge"),
          color: "light",
        },
        {
          name: "discharge_button",
          title: t("Discharge"),
          color: "light",
        },
        {
          name: "pre_charge_button",
          title: t("Pre-Charge"),
          color: "light",
        },
      ],
      packControl: [
        {
          name: "write_enable_button",
          title: t("Enable"),
          color: "light",
        },
        {
          name: "write_contactor_connect_button",
          title: t("Contactor connect"),
          color: "light",
        },
        {
          name: "write_balancing_button",
          title: t("Balancing"),
          color: "light",
        },
      ],
      packBalancingMode: [
        {
          name: "write_normal_button",
          title: t("Normal"),
          color: "light",
        },
        {
          name: "write_force_button",
          title: t("Force"),
          color: "light",
        },
        {
          name: "write_even_odd_button",
          title: t("Even Odd"),
          color: "light",
        },
        {
          name: "write_select_button",
          title: t("Select"),
          color: "light",
        },
        {
          name: "write_charge_snapshot_button",
          title: t("Charge Snapshot"),
          color: "light",
        },
        {
          name: "write_discharge_snapshot_button",
          title: t("Discharge Snapshot"),
          color: "light",
        },
      ],
      packBalancingCondition: [
        {
          name: "write_charge_button",
          title: t("Charge"),
          color: "light",
        },
        {
          name: "write_discharge_button",
          title: t("Discharge"),
          color: "light",
        },
        {
          name: "write_idle_button",
          title: t("Idle"),
          color: "light",
        },
      ],
      packStatus: [
        {
          name: "version_button",
          title: t("Version"),
          color: "light",
        },
        {
          name: "state_button",
          title: t("Pack BMS status"),
          color: "light",
        },
        {
          name: "emergency_button",
          title: t("Emergency"),
          color: "light",
        },
        {
          name: "charge_button",
          title: t("Charge"),
          color: "light",
        },
        {
          name: "discharge_button",
          title: t("Discharge"),
          color: "light",
        },
        {
          name: "pre_charge_button",
          title: t("Pre-Charge"),
          color: "light",
        },
      ],
      packContactorStatus: [
        {
          name: "causes_of_connection_failure_button",
          title: t("Causes of connection failure"),
          color: "light",
        },
        {
          name: "plus_contactor_state_button",
          title: t("+ Contactor status"),
          color: "light",
        },
        {
          name: "minus_contactor_state_button",
          title: t("- Contactor status"),
          color: "light",
        },
        {
          name: "pre_charge_contactor_state_button",
          title: t("Pre-Charge contactor status"),
          color: "light",
        },
      ],
      statusDetail: [
        {
          name: "pack_iso_spi_communication_button",
          title: t("Pack ISO-SPI communication"),
          color: "light",
        },
        {
          name: "pack_internal_voltage_24v_button",
          title: t("Pack 24V internal voltage"),
          color: "light",
        },
        {
          name: "pack_internal_voltage_5v_button",
          title: t("Pack 5V internal voltage"),
          color: "light",
        },
        {
          name: "pack_internal_voltage_3_3v_button",
          title: t("Pack 3.3V internal voltage"),
          color: "light",
        },
        {
          name: "pack_contactor_error_button",
          title: t("Pack contactor error"),
          color: "light",
        },
        {
          name: "pack_ground_fault_button",
          title: t("Pack ground fault"),
          color: "light",
        },
        {
          name: "pack_current_sensor_error_button",
          title: t("Pack current sensor error"),
          color: "light",
        },
        {
          name: "pack_temperature_sensor_error_button",
          title: t("Pack temperature sensor error"),
          color: "light",
        },
        {
          name: "pack_current_mismatch_button",
          title: t("Pack current mismatch"),
          color: "light",
        },
        {
          name: "pack_voltage_mismatch_button",
          title: t("Pack voltage mismatch"),
          color: "light",
        },
        {
          name: "pack_over_voltage_button",
          title: t("Pack over voltage"),
          color: "light",
        },
        {
          name: "pack_under_voltage_button",
          title: t("Pack under voltage"),
          color: "light",
        },
        {
          name: "pack_over_temperature_button",
          title: t("Pack over temperature"),
          color: "light",
        },
        {
          name: "pack_under_temperature_button",
          title: t("Pack under temperature"),
          color: "light",
        },
        {
          name: "pack_imbalance_temperature_button",
          title: t("Pack imbalance temperature"),
          color: "light",
        },
        {
          name: "pack_over_charge_current_button",
          title: t("Pack over charge current"),
          color: "light",
        },
        {
          name: "pack_over_discharge_current_button",
          title: t("Pack over discharge current"),
          color: "light",
        },
        {
          name: "pack_over_soc_button",
          title: t("Pack over SoC"),
          color: "light",
        },
        {
          name: "pack_under_soc_button",
          title: t("Pack under SoC"),
          color: "light",
        },
        {
          name: "module_imbalance_voltage_button",
          title: t("Module imbalance voltage"),
          color: "light",
        },
        {
          name: "module_over_temperature_button",
          title: t("Module over temperature"),
          color: "light",
        },
        {
          name: "module_under_temperature_button",
          title: t("Module under temperature"),
          color: "light",
        },
        {
          name: "module_imbalance_temperature_button",
          title: t("Module imbalance temperature"),
          color: "light",
        },
        {
          name: "cell_over_voltage_button",
          title: t("Cell over voltage"),
          color: "light",
        },
        {
          name: "cell_under_voltage_button",
          title: t("Cell under voltage"),
          color: "light",
        },
        {
          name: "cell_imbalance_voltage_button",
          title: t("Cell imbalance voltage"),
          color: "light",
        },
        {
          name: "open_wire_error_button",
          title: t("Open wire error"),
          color: "light",
        },
        {
          name: "rtc_error_button",
          title: t("RTC error"),
          color: "light",
        },
        {
          name: "fire_outbreak_button",
          title: t("Fire outbreak"),
          color: "light",
        },
        {
          name: "fuse_open_button",
          title: t("Fuse open"),
          color: "light",
        },
        {
          name: "open_door_button",
          title: t("Open door"),
          color: "light",
        },
        {
          name: "fan_button",
          title: t("Fan"),
          color: "light",
        },
      ],
      firmwareUpdate: [
        {
          name: "firmware_update_button",
          title: t("Firmware update"),
          color: "light",
        },
      ],
      sensorControl: [
        {
          name: "write_warning_light_button",
          title: t("Warning light"),
          color: "light",
        },
      ],
      sensorTemperature: [
        {
          name: "temperature_1_button",
          title: `${t("Temperature")} #1`,
          color: "light",
        },
        {
          name: "temperature_2_button",
          title: `${t("Temperature")} #2`,
          color: "light",
        },
        {
          name: "temperature_3_button",
          title: `${t("Temperature")} #3`,
          color: "light",
        },
        {
          name: "temperature_4_button",
          title: `${t("Temperature")} #4`,
          color: "light",
        },
        {
          name: "temperature_5_button",
          title: `${t("Temperature")} #5`,
          color: "light",
        },
        {
          name: "temperature_6_button",
          title: `${t("Temperature")} #6`,
          color: "light",
        },
        {
          name: "temperature_7_button",
          title: `${t("Temperature")} #7`,
          color: "light",
        },
        {
          name: "temperature_8_button",
          title: `${t("Temperature")} #8`,
          color: "light",
        },
      ],
      sensorOther: [
        {
          name: "hydrogen1_button",
          title: `${t("Hydrogen")} #1`,
          color: "light",
        },
        {
          name: "hydrogen2_button",
          title: `${t("Hydrogen")} #2`,
          color: "light",
        },
        {
          name: "humidity_button",
          title: t("Humidity"),
          color: "light",
        },
        {
          name: "fire_outbreak_button",
          title: t("Fire outbreak"),
          color: "light",
        },
        {
          name: "fuse_open_button",
          title: t("Fuse open"),
          color: "light",
        },
        {
          name: "open_door_button",
          title: t("Open door"),
          color: "light",
        },
      ],
      fan: [
        {
          name: "fan_1_button",
          title: `${t("Fan")} #1`,
          color: "light",
        },
        {
          name: "fan_2_button",
          title: `${t("Fan")} #2`,
          color: "light",
        },
        {
          name: "fan_3_button",
          title: `${t("Fan")} #3`,
          color: "light",
        },
        {
          name: "fan_4_button",
          title: `${t("Fan")} #4`,
          color: "light",
        },
        {
          name: "fan_5_button",
          title: `${t("Fan")} #5`,
          color: "light",
        },
        {
          name: "fan_6_button",
          title: `${t("Fan")} #6`,
          color: "light",
        },
        {
          name: "fan_7_button",
          title: `${t("Fan")} #7`,
          color: "light",
        },
        {
          name: "fan_8_button",
          title: `${t("Fan")} #8`,
          color: "light",
        },
        {
          name: "fan_9_button",
          title: `${t("Fan")} #9`,
          color: "light",
        },
        {
          name: "fan_10_button",
          title: `${t("Fan")} #10`,
          color: "light",
        },
        {
          name: "fan_11_button",
          title: `${t("Fan")} #11`,
          color: "light",
        },
        {
          name: "fan_12_button",
          title: `${t("Fan")} #12`,
          color: "light",
        },
        {
          name: "fan_13_button",
          title: `${t("Fan")} #13`,
          color: "light",
        },
        {
          name: "fan_14_button",
          title: `${t("Fan")} #14`,
          color: "light",
        },
        {
          name: "fan_15_button",
          title: `${t("Fan")} #15`,
          color: "light",
        },
        {
          name: "fan_16_button",
          title: `${t("Fan")} #16`,
          color: "light",
        },
        {
          name: "fan_17_button",
          title: `${t("Fan")} #17`,
          color: "light",
        },
        {
          name: "fan_18_button",
          title: `${t("Fan")} #18`,
          color: "light",
        },
        {
          name: "fan_19_button",
          title: `${t("Fan")} #19`,
          color: "light",
        },
        {
          name: "fan_20_button",
          title: `${t("Fan")} #20`,
          color: "light",
        },
        {
          name: "fan_21_button",
          title: `${t("Fan")} #21`,
          color: "light",
        },
        {
          name: "fan_22_button",
          title: `${t("Fan")} #22`,
          color: "light",
        },
        {
          name: "fan_23_button",
          title: `${t("Fan")} #23`,
          color: "light",
        },
        {
          name: "fan_24_button",
          title: `${t("Fan")} #24`,
          color: "light",
        },
      ],
    }),
    [t]
  );

  return {
    systemButtons,
  };
}

export default useSystemButtons;
