import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDCheckBox from "components/MDCheckBox";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { PropsWithChildren, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import MDInput from "../../../../../../components/MDInput";

type Props = {
  title: string;
  isCheck: boolean;
  ipValue: string;
  portValue: number;
  userNameValue: string;
  passwordValue: string;
  dirPathValue: string;
  onChangeCheck: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeNumber: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
};

const GridSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed !important" : "default",
});

function LogBackupCard({
  title,
  isCheck,
  ipValue,
  portValue,
  userNameValue,
  passwordValue,
  dirPathValue,
  onChangeCheck,
  onChangeNumber,
  onChangeText,
}: PropsWithChildren<Props>) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="backup" title={title}>
      <MDBox mb={1} mx={6}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Enable")}
            </MDTypography>
          </Grid>
          <Grid item sm={1} textAlign="center">
            <MDCheckBox
              name= { "useLogBackup" }
              checked={isCheck}
              onChange={onChangeCheck}
            />
          </Grid>
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("IP")}
            </MDTypography>
          </Grid>
          <Grid item sm={2} sx={GridSX(!isCheck)}>
            <MDInput
              type="text"
              label={t("IP")}
              name="ip"
              value={ipValue}
              onChange={onChangeText}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Port")}
            </MDTypography>
          </Grid>
          <Grid item sm={2} sx={GridSX(!isCheck)}>
            <MDInput
              type="number"
              label={t("Port")}
              name="port"
              value={portValue}
              onChange={onChangeNumber}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Account")}
            </MDTypography>
          </Grid>
          <Grid item sm={1} sx={GridSX(!isCheck)}>
            <MDInput
              type="text"
              label={t("Account")}
              name="userName"
              value={userNameValue}
              onChange={onChangeText}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Password")}
            </MDTypography>
          </Grid>
          <Grid item sm={1} sx={GridSX(!isCheck)}>
            <MDInput
              type="password"
              label={t("Password")}
              name="password"
              value={passwordValue}
              onChange={onChangeText}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={1} mx={6}>
        <Grid container alignItems="center">
          <Grid item sm={1}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("Directory Path")}
            </MDTypography>
          </Grid>
          <Grid item sm={11} sx={GridSX(!isCheck)}>
            <MDInput
              type="text"
              label={t("Directory Path")}
              name="dirPath"
              value={dirPathValue}
              onChange={onChangeText}
              disabled={!isCheck}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </DefaultCard>
  );
}

export default LogBackupCard;