import React, {
  FocusEvent, ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

// Components
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";
import MDCheckBox from "components/MDCheckBox";

// Declaring props types for DefaultCheckboxCell
interface Props {
  use?: boolean;
  index?: number;
  checked?: boolean;
  name?: string;
  placeholder?: string;
  onChange?: any;
  disabled?: boolean;
  isChangeOn?: boolean;
}

const BoxSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed" : "default",
  minHeight: pxToRem(34),
  maxHeight: pxToRem(34),
});

function DefaultCheckboxCell({
  use,
  index,
  checked,
  name,
  placeholder,
  onChange,
  disabled,
  isChangeOn = false,
}: Props): ReactElement {
  const [checkedValue, setCheckboxEnable] = useState(false);

  useEffect(() => {
    setCheckboxEnable(checked);
  }, [checked]);

  const handleChangeCheckboxEnable = useCallback(
    (e: { target: { checked: any; }; }) => {
      const { checked } = e.target;
      setCheckboxEnable(checked);
      isChangeOn && onChange(index, checked, name);
    },
    [index, name, onChange, isChangeOn]
  );

  const handleBlurCheckboxEnable = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (checked === checkedValue) return;
      const { name } = e.target;
      onChange(index, checkedValue, name);
    },
    [index, checked, checkedValue, onChange]
  );

  return use ? (
    <MDBox display="flex" alignItems="center" justifyContent="center" sx={BoxSX(disabled)}>
      <MDCheckBox
        placeholder={placeholder}
        checked={checkedValue}
        name={name}
        onChange={handleChangeCheckboxEnable}
        onBlur={handleBlurCheckboxEnable}
        disabled={disabled}
      />
    </MDBox>
  ) : (
  <MDBox display="flex" alignItems="center" justifyContent="center"></MDBox>
  );
}

// Declaring default props for DefaultCheckboxCell
DefaultCheckboxCell.defaultProps = {
  use: false,
  index: -1,
  checked: "",
  onChange: null,
};

export default DefaultCheckboxCell;
