import {Colors} from "lib/types/material";
import {StorageAccount} from "store/user";

export function sunssf(sf: number) {
  if (sf !== 0 && !sf) return 0;
  return Math.pow(10, sf);
}

export const getPercent = (value: number, max: number = 100) => {
  if (!value) return 0;
  return parseFloat((Math.floor((value / max) * 100 * 100) * 0.01).toFixed(2));
};

export const getProgressValue = ({
  value,
  sf,
  unit,
  max,
  title = "",
  color = "light",
}: {
  value: number;
  sf: number;
  unit: string;
  max?: number;
  title?: string;
  color?: Colors;
}) => {
  const VALUE = (value || 0) * sunssf(sf);
  return {
    realValue: VALUE,
    viewValue: VALUE.toFixed(Math.abs(sf || 0)),
    percentValue: getPercent(Math.abs(VALUE), max),
    title,
    color,
    unit,
  };
};

let backendDateTime: Date = null;
export const getBackendDateTime = () => {
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open("HEAD", window.location.href.toString(), false);
  xmlHttp.setRequestHeader("Content-Type", "text/html");
  xmlHttp.send("");

  backendDateTime = new Date(xmlHttp.getResponseHeader("Date"));
};

export const setDateTime = (dateTime: Date) => {
  backendDateTime = dateTime;
};

export const getDateTime = () => {
  if (backendDateTime === null) {
    getBackendDateTime();
  }
  return new Date(backendDateTime);
};

export const changeDecimalPoint = (value: number, decimal: number): number =>
  Number(((value || 0) * sunssf(decimal)).toFixed(Math.abs(decimal)));

export const getDate = (
  dayCount: number = 0,
  options?: { hour?: number; minute?: number; }
) => {
  const isParamHour = Boolean(options && typeof options.hour === "number");
  const isParamMinute = Boolean(options && typeof options.minute === "number");

  const now = getDateTime();
  const offset = now.getTimezoneOffset() * 60000 * -1;
  const date = new Date(now.setDate(now.getDate() + dayCount) + offset)
    .toISOString()
    .split("T")[0];
  const hour = String(isParamHour ? options.hour : now.getHours()).padStart(
    2,
    "0"
  );
  const minute = String(
    isParamMinute ? options.minute : now.getMinutes()
  ).padStart(2, "0");
  const time = `T${hour}:${minute}`;

  return `${date}${time}`;
};

export const getUserRole = (user: StorageAccount) => {
  if (!user) return;
  return {
    MONITOR: user.roles.some((role) => role === "ROLE_MONITOR"),
    CONTROL: user.roles.some((role) => role === "ROLE_CONTROL"),
    ACCOUNT: user.roles.some((role) => role === "ROLE_ACCOUNT"),
    SETTING: user.roles.some((role) => role === "ROLE_SETTING"),
    LOG: user.roles.some((role) => role === "ROLE_LOG"),
    QC: user.roles.some((role) => role === "ROLE_QC"),
    ADMIN: user.roles.some((role) => role === "ROLE_ADMIN"),
  };
};
