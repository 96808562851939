import React, {ReactElement} from "react";
import { TFunction } from "i18next";

import Icon from "@mui/material/Icon";

import SignIn from "layouts/pages/authentication/sign-in";
import SignOut from "./layouts/pages/authentication/sign-out";
import Account from "layouts/pages/configuration/account";
import Settings from "layouts/pages/configuration/setting";
import Log from "layouts/pages/etc/log";
import Dashboard from "layouts/pages/dashboard";
import DevicesPackBMSOverview from "layouts/pages/devices/packBMS/overview";
import DevicesPackBMSModules from "layouts/pages/devices/packBMS/modules";
import DevicesPackBMSEnvironment from "layouts/pages/devices/packBMS/environment";
import QCCell from "layouts/pages/qc/cell";
import QCModule from "layouts/pages/qc/module";
import { Setting } from "lib/types/setting";
import { getUserRole } from "lib/utils/common";
import { routePathname } from "lib/consts/routePathname";
import {StorageAccount} from "./store/user";
import ChangePassword from "./layouts/pages/authentication/change-password";

export type CustomRoute = {
  type?: string;
  name?: string;
  title?: string;
  key?: string;
  icon?: ReactElement;
  component?: ReactElement;
  route?: string;
  noCollapse?: boolean;
  collapse?: CustomRoute[];
};

function routes(
  user: StorageAccount,
  setting: Setting,
  t: TFunction<"Translation", undefined, "Translation">
) {
  const routes: CustomRoute[] = [];
  const ROLE = getUserRole(user);

  if (Boolean(user) && setting) {
    routes.push({
      type: "collapse",
      name: user.username,
      key: "auth",
      icon: (
        <Icon fontSize="medium">
          {ROLE.ADMIN ? "admin_panel_settings" : "person"}
        </Icon>
      ),
      collapse: [
        {
          name: `${t("Change password")}`,
          key: "auth/change-password",
          route: routePathname.CHANGE_PASSWORD,
          component: <ChangePassword />,
          icon: <Icon fontSize="medium">password</Icon>,
        },
        {
          name: `${t("Sign out")}`,
          key: "auth/sign-out",
          route: routePathname.SIGN_OUT,
          component: <SignOut />,
          icon: <Icon fontSize="medium">logout</Icon>,
        },
      ],
    });

    routes.push({ type: "divider", key: "divider-0" });
    routes.push({
      type: "collapse",
      name: `${t("Dashboard")}`,
      key: "dashboard",
      icon: <Icon fontSize="medium">dashboard</Icon>,
      route: routePathname.DASHBOARD,
      component: <Dashboard />,
      noCollapse: true,
    });

    if (ROLE) {
      if (ROLE.ADMIN || ROLE.ACCOUNT || ROLE.SETTING) {
        routes.push({ type: "divider", key: "divider-1" });
        routes.push({
          type: "title",
          title: `${t("Config")}`,
          key: "title-configuration",
        });
      }
      if (ROLE.ADMIN || ROLE.ACCOUNT) {
        routes.push({
          type: "collapse",
          name: `${t("Account")}`,
          key: "account",
          icon: <Icon fontSize="medium">manage_accounts</Icon>,
          route: routePathname.ACCOUNT,
          component: <Account />,
          noCollapse: true,
        });
      }
      if (ROLE.ADMIN || ROLE.SETTING) {
        routes.push({
          type: "collapse",
          name: `${t("Setting")}`,
          key: "setting",
          icon: <Icon fontSize="medium">settings</Icon>,
          route: routePathname.SETTING,
          component: <Settings />,
          noCollapse: true,
        });
      }

      if (ROLE.ADMIN || ROLE.MONITOR || ROLE.CONTROL) {
        routes.push({ type: "divider", key: "divider-2" });
        routes.push({
          type: "title",
          title: `${t("Devices")}`,
          key: "title-devices",
        });

        if (setting.usePackBMS === true) {
          for (let packBMSIndex = 0; packBMSIndex < setting.numberOfPackBMS; ++packBMSIndex) {
            const packBMS_name = `${t("Pack BMS")} #${packBMSIndex + 1}`;
            const packBMS_key = `packBMS${packBMSIndex + 1}`;
            const packBMS_route = `/packBMS${packBMSIndex + 1}`;
            const packBMSSetting = setting.packBMSSettingList[packBMSIndex];
            if (packBMSSetting.useSensor || packBMSSetting.useFan) {
              routes.push({
                type: "collapse",
                name: packBMS_name,
                key: packBMS_key,
                icon: <Icon fontSize="medium">battery_saver</Icon>,
                collapse: [
                  {
                    name: `${t("Overview")}`,
                    key: packBMS_key + "/overview",
                    route: packBMS_route + "/overview",
                    component: (
                      <DevicesPackBMSOverview
                        key={packBMSIndex}
                        packBMSNo={packBMSIndex}
                      />
                    ),
                    icon: <Icon fontSize="medium">desktop_mac</Icon>,
                  },
                  {
                    name: `${t("Module")}`,
                    key: packBMS_key + "/modules",
                    route: packBMS_route + "/modules",
                    component: (
                      <DevicesPackBMSModules
                        key={packBMSIndex}
                        packBMSNo={packBMSIndex}
                      />
                    ),
                    icon: <Icon fontSize="medium">web_stories</Icon>,
                  },
                  {
                    name: `${t("Environment")}`,
                    key: packBMS_key + "/environment",
                    route: packBMS_route + "/environment",
                    component: (
                      <DevicesPackBMSEnvironment
                        key={packBMSIndex}
                        packBMSNo={packBMSIndex}
                      />
                    ),
                    icon: <Icon fontSize="medium">sensors</Icon>,
                  },
                ],
              });
            } else {
              routes.push({
                type: "collapse",
                name: packBMS_name,
                key: packBMS_key,
                icon: <Icon fontSize="medium">battery_saver</Icon>,
                collapse: [
                  {
                    name: `${t("Overview")}`,
                    key: packBMS_key + "/overview",
                    route: packBMS_route + "/overview",
                    component: (
                      <DevicesPackBMSOverview
                        key={packBMSIndex}
                        packBMSNo={packBMSIndex}
                      />
                    ),
                    icon: <Icon fontSize="medium">desktop_mac</Icon>,
                  },
                  {
                    name: `${t("Module")}`,
                    key: packBMS_key + "/modules",
                    route: packBMS_route + "/modules",
                    component: (
                      <DevicesPackBMSModules
                        key={packBMSIndex}
                        packBMSNo={packBMSIndex}
                      />
                    ),
                    icon: <Icon fontSize="medium">web_stories</Icon>,
                  },
                ],
              });
            }
          }
        }
      }

      if (ROLE.ADMIN || ROLE.LOG) {
        routes.push({type: "divider", key: "divider-3"});
        routes.push({
          type: "title",
          title: `${t("Etc")}`,
          key: "title-etc",
        });
        routes.push({
          type: "collapse",
          name: `${t("Log")}`,
          key: "log",
          icon: <Icon fontSize="medium">dataset</Icon>,
          route: routePathname.LOG,
          component: <Log/>,
          noCollapse: true,
        });
      }
      if (ROLE.ADMIN || ROLE.QC) {
        routes.push({ type: "divider", key: "divider-4" });
        routes.push({
          type: "title",
          title: `${t("QC")}`,
          key: "title-qc",
        });
        routes.push({
          type: "collapse",
          name: `${t("Module")}`,
          key: "qc-module",
          icon: <Icon fontSize="medium">cable</Icon>,
          route: routePathname.QC_MODULE,
          component: <QCModule />,
          noCollapse: true,
        });
        routes.push({
          type: "collapse",
          name: `${t("Cell")}`,
          key: "qc-cell",
          icon: <Icon fontSize="medium">construction</Icon>,
          route: routePathname.QC_CELL,
          component: <QCCell />,
          noCollapse: true,
        });
      }
    }
  } else {
    routes.push({
      type: "collapse",
      key: "signIn",
      name: "",
      route: routePathname.SIGN_IN,
      component: <SignIn />,
      noCollapse: true,
    });
  }

  return routes;
}
export default routes;
