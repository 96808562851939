import { Grid, SelectChangeEvent } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDSelect from "components/MDSelect";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  ipName: string;
  ipValue: string;
  ipOnChange: (e: SelectChangeEvent<number>) => void;
  ipOptions: string[];
  portName: string;
  portValue: number;
  portOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function EthernetCard({
  title,
  ipName,
  ipValue,
  ipOptions,
  ipOnChange,
  portName,
  portValue,
  portOnChange,
}: Props) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="public" title={title}>
      <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
        <Grid container alignItems="center">
          <Grid item sm={4}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("IP")}
            </MDTypography>
          </Grid>
          <Grid item sm={8}>
            <MDSelect
              label={t("IP")}
              placeholder={t("IP")}
              name={ipName}
              fullWidth
              value={ipValue}
              onChange={ipOnChange}
              options={ipOptions.map((option: string) => {
                return { name: option, value: option };
              })}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox ml={{ xs: 0, sm: 3 }} mr={{ xs: 0, sm: 3 }} mb={{ xs: 0, sm: 2 }}>
        <Grid container alignItems="center">
          <Grid item sm={4}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {t("TCP port")}
            </MDTypography>
          </Grid>
          <Grid item sm={8}>
            <MDInput
              type="number"
              label={t("TCP port")}
              name={portName}
              fullWidth
              value={portValue}
              onChange={portOnChange}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DefaultCard>
  );
}

export default EthernetCard;
