import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Colors } from "lib/types/material";
import { Balancing, Se805 } from "lib/types/pack";
import React, { memo, MouseEvent, useContext } from "react";
import isEqual from "lodash.isequal";
import MemoBadge from "layouts/components/memoBadge";
import { changeDecimalPoint } from "lib/utils/common";
import { useTranslation } from "react-i18next";
import pxToRem from "assets/theme/functions/pxToRem";
import MemoButton from "layouts/components/memoButton";
import DefaultModal from "layouts/components/modal/default";
import { useRecoilValue } from "recoil";
import { systemConfigState } from "store/systemConfig";
import { ModalContext } from "context/modal/modalContext";
import PopoverContext from "context/popover/popoverContext";
import BalancingCellsModal from "layouts/components/modal/balancingCells";
import moduleApi from "apis/devices/packBMS/module/api";
import useApiRequest from "lib/hooks/useApiRequest";

type Props = {
  packBMSIndex: number;
  moduleIndex: number;
  se805: Se805;
  balancing: Balancing;
  color: Colors;
};

const ButtonSX = { width: pxToRem(108), marginRight: 2, marginBottom: 1 };
function Module({
  packBMSIndex,
  moduleIndex,
  se805,
  balancing,
  color = "light",
}: Props) {
  const { t } = useTranslation("Translation");
  const { runtime } = useRecoilValue(systemConfigState);
  const { openModal } = useContext(ModalContext);
  const { openPopover } = useContext(PopoverContext);
  const isRemote = !runtime.isLocal || runtime.isLocalRemoteProcessing;
  const { request: controlRequest, isLoading: isControlButtonsLoading } =
    useApiRequest();
  const handleOpenModal = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.target instanceof HTMLButtonElement) {
      if (isRemote) {
        openModal(
          <DefaultModal status="error" title={t("Unable to control due to remote condition.")} />
        );
        return;
      }

      openModal(
        <BalancingCellsModal
          title={`${t("Module")} #${String(moduleIndex + 1).padStart(2, "0")} ${t(
            "Balancing setting"
          )}`}
          color={color}
          onSuccess={(setBalSt) => {
            controlRequest({
              targetApi: () =>
                moduleApi.control(packBMSIndex, moduleIndex, {
                  type: "SET_BAL_ST",
                  value: setBalSt,
                }),
            });
          }}
          se805RepeatBlockList={se805?.repeatBlockList}
          selectedCellsOfModule={
            balancing?.balancingSetting.selectedCellsOfModule[moduleIndex]
          }
        />
      );
    }
  };

  return (
    <MDBox display="flex" pl={2} pr={2}>
      <MemoButton
        title={`${t("Module")} #${String(moduleIndex + 1).padStart(2, "0")}`}
        name={`${t("Module")} #${String(moduleIndex + 1).padStart(2, "0")}`}
        color={color}
        sx={ButtonSX}
        disabled={isControlButtonsLoading}
        onClick={(e) =>
          openPopover({
            event: e,
            onSuccess: () => handleOpenModal(e),
          })
        }
      />
      <Grid container alignItems="center">
        <Grid item xs={12} xl={8}>
          {se805.repeatBlockList.map((cell, cellIndex) => {
            return (
              <MemoBadge
                key={cellIndex}
                title={`${changeDecimalPoint(
                  cell.cellV,
                  se805.fixedBlock.cellV_SF
                )} V`}
                color={cell.cellSt === 1 ? "warning" : color}
                width={pxToRem(74)}
                sx={{ height:pxToRem(38), marginRight: 1, marginBottom: 1 }}
              />
            );
          })}
        </Grid>
        <Grid item xs={12} xl={4}>
          {se805.repeatBlockList.map((cell, cellIndex) => {
            if (cellIndex % 2) return null;
            return (
              <MemoBadge
                key={cellIndex}
                title={`${changeDecimalPoint(
                  cell.cellTmp,
                  se805.fixedBlock.tmp_SF
                )} ºC`}
                color={color}
                width={pxToRem(74)}
                sx={{ height:pxToRem(38), marginRight: 1, marginBottom: 1 }}
              />
            );
          })}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default memo(Module, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
