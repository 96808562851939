import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DefaultCard from "layouts/components/cards/defaultCard";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  name: string;
  value: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function PeriodCard({ title, name, value, onChange }: Props) {
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="date_range" title={title}>
      <MDBox ml={2} mr={2} mb={1}>
        <Grid container alignItems="center">
          <Grid item sm={4}>
            <MDTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
              textAlign="center"
            >
              {`${t("Retention period")} (${t("Unit")})`}
            </MDTypography>
          </Grid>
          <Grid item sm={8}>
            <MDInput
              type="number"
              label={t("Retention period")}
              name={name}
              fullWidth
              value={value}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DefaultCard>
  );
}

export default PeriodCard;
