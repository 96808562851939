import Grid from "@mui/material/Grid";
import pxToRem from "assets/theme/functions/pxToRem";
import MDBox from "components/MDBox";
import MemoBadge from "layouts/components/memoBadge";
import isEqual from "lodash.isequal";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {PackBMSData, PackBMSStatus} from "lib/types/pack";
import {getPackBMSStatusColor} from "lib/utils/pack";
import {useNavigate} from "react-router-dom";
import MemoButton from "layouts/components/memoButton";

type Props = {
  packData: PackBMSData;
  packStatus: PackBMSStatus;
  moduleIsoSpiStatusList: boolean[];
  packIndex: number;
  isStart: boolean;
};

function Pack({ packData, packStatus, moduleIsoSpiStatusList, packIndex, isStart }: Props) {
  const { t } = useTranslation("Translation");
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/packBMS" + String(packIndex + 1) + "/overview", { replace: true });
  };

  return (
    <MDBox display="flex" mb={1} mr={1}>
      <MemoButton
        title={`${t("Pack BMS")} #${String(packIndex + 1).padStart(2, "0")}`}
        color={isStart ? (packStatus.status ? getPackBMSStatusColor({
          buttonName: "state_button",
          se804FixedBlock: packData.se804.fixedBlock,
          balancingSetting: packData.balancing.balancingSetting
        }) : "error") : "light"}
        sx={{
          width: pxToRem(148),
        }}
        onClick={handleButtonClick}
      />
      {moduleIsoSpiStatusList && Boolean(moduleIsoSpiStatusList.length) && (
        <MDBox>
          <Grid container gap={0.5} mx={1}>
            {moduleIsoSpiStatusList.map((module, moduleIndex) => {
              return (
                <MemoBadge
                  key={moduleIndex}
                  title={`${moduleIndex + 1}`}
                  color={isStart ? (module ? "success" : "error") : "light"}
                  width={pxToRem(44)}
                  sx={{ height:pxToRem(38)}}
                />
              );
            })}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

export default memo(Pack, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
