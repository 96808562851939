import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { ModalContext } from "context/modal/modalContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {useRecoilState} from "recoil";
import {uiConfigState} from "../../../../store/uiConfig";

type TitleStatus = "none" | "success" | "error" | "warning";
type ModalType = "default" | "confirm";
type Props = {
  type?: ModalType;
  status?: TitleStatus;
  title?: string;
  description?: string;
  maxWidth?: number;
  onSuccess?: () => void;
};

function DefaultModal({
  type = "default",
  status = "none",
  title = "",
  description = "",
  maxWidth,
  onSuccess = () => {},
}: Props) {
  const [uiConfig] = useRecoilState(uiConfigState);
  const { darkMode } = uiConfig;
  const { t } = useTranslation("Translation");
  const { closeModal } = useContext(ModalContext);
  const handleConfirm = () => {
    onSuccess();
    closeModal();
  };
  const getTitleColor = (status: TitleStatus) => {
    switch (status) {
      case "success":
        return "success";
      case "warning":
        return "warning";
      case "error":
        return "error";
      default:
        return;
    }
  };
  const getIcon = (status: TitleStatus) => {
    switch (status) {
      case "success":
        return "check_circle";
      case "warning":
        return "warning";
      case "error":
        return "error";
      default:
        return;
    }
  };

  return (
    <MDBox maxWidth={maxWidth}>
      <MDTypography
        sx={{ display: "flex", alignItems: "center" }}
        variant="h6"
        color={darkMode ? "white" : "dark"}
      >
        {status !== "none" && (
          <Icon sx={{ marginRight: 1, marginBottom: 0.2 }} color={getTitleColor(status)}>{getIcon(status)}</Icon>
        )}
        {title}
      </MDTypography>
      <MDTypography>{description}</MDTypography>
      <Grid container spacing={2} mt={1}>
        {type !== "default" && (
          <Grid item xs={12} xl={6}>
            <MDButton fullWidth color="error" size="large" onClick={closeModal}>
              {t("Cancel")}
            </MDButton>
          </Grid>
        )}
        <Grid item xs={12} xl={type !== "default" ? 6 : 12}>
          <MDButton
            autoFocus
            fullWidth
            color="info"
            size="large"
            onClick={handleConfirm}
          >
            {t("OK")}
          </MDButton>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default DefaultModal;
