import {PropsWithChildren, ReactElement} from "react";

// Components
import MDBox from "components/MDBox";

// Images
import backgroundImage from "assets/images/header.png";

function Body({ children }: PropsWithChildren): ReactElement {
  return (
    <MDBox position="relative">
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        minHeight="9.75rem"
        maxHeight="9.75rem"
        borderRadius="xl"
        overflow="hidden"
      >
        <img width="100%" src={backgroundImage} alt="main-cover" />
      </MDBox>
      <MDBox position="relative" mt={-8} py={2} px={2}>
        {children}
      </MDBox>
    </MDBox>
  );
}

export default Body;
