import { QCType } from "lib/types/qc";

export const requestPathname = {
  SIGN_IN: "/auth/signIn",
  SIGN_OUT: "/auth/signOut",
  REFRESH_TOKEN: "/auth/refreshToken",
  CHECK_AUTH: "/auth/checkAuth",
  GET_ALREADY_SIGN_IN: "/auth/getAlreadySignIn",
  GET_SESSION_TIME: "/auth/getSessionTime",
  INIT_SESSION_TIME: "/auth/initSessionTime",
  ACCOUNT_ALL: "/account/all",
  ACCOUNT: "/account",
  SETTING: "/setting",
  SYSTEM_BMS_DATA: "/systemBMS/data",
  SYSTEM_BMS_STATUS: "/systemBMS/status",
  SYSTEM_BMS_CONTROL: "/systemBMS/control",
  SYSTEM_BMS_DELETE_FIRMWARE_FILE: "/systemBMS/deleteFirmwareFile",
  SYSTEM_BMS_PACK_DATA: (packBMSNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/data`,
  SYSTEM_BMS_PACK_STATUS: (packBMSNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/status`,
  SYSTEM_BMS_PACK_CONTROL: (packBMSNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/control`,
  SYSTEM_BMS_MODULE_CONTROL: (packBMSNo: number, moduleNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/module${moduleNo}/control`,
  SYSTEM_BMS_SENSOR_CONTROL: (packBMSNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/sensor/control`,
  SYSTEM_BMS_FAN_CONTROL: (packBMSNo: number, fanNo: number) =>
    `/systemBMS/packBMS${packBMSNo}/fan${fanNo}/control`,
  RUNTIME_IS_START: "/runtime/started",
  RUNTIME_IS_LOCK: "/runtime/lock",
  RUNTIME_IS_LOCAL: "/runtime/local",
  RUNTIME_PROCESSING_START_STOP: "/runtime/startStopProcessing",
  RUNTIME_PROCESSING_LOCK_UNLOCK: "/runtime/lockUnlockProcessing",
  RUNTIME_PROCESSING_LOCAL_REMOTE: "/runtime/localRemoteProcessing",
  RUNTIME_COMMAND: "/runtime/command",
  LOG: "/log",
  LOG_DOWNLOAD: "/log/download",
  LOG_DELETE: "/log/delete",
  LOG_EVENT: "/log/event",
  QC: (qcType: QCType) => `/qc/${qcType}`,
  QC_FILES: (qcType: QCType) => `/qc/${qcType}/files`,
  QC_DELETE: "/qc/delete",
  QC_DOWNLOAD: "/qc/download",
} as const;
