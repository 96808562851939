import api from "apis/api";
import { requestPathname } from "lib/consts/api";
import { QCData, QCType } from "lib/types/qc";

const getQCData = async (qcType: QCType) => {
  const response = await api.get(requestPathname.QC(qcType));
  return response.data;
};

const getQCFileData = async (qcType: QCType) => {
  const response = await api.get(requestPathname.QC_FILES(qcType));
  return response.data;
};

const controlQC = async ({
  qcType,
  payload,
}: {
  qcType: QCType;
  payload: QCData;
}) => {
  const response = await api.post(requestPathname.QC(qcType), payload);
  return response.data;
};

const downloadQCLog = async (fileFullPath: string) => {
  const response = await api.post(
    requestPathname.QC_DOWNLOAD,
    { fileFullPath },
    { responseType: "blob" }
  );
  return response.data;
};

const deleteQCLog = async (fileFullPath: string) => {
  const response = await api.post(requestPathname.QC_DELETE, {
    fileFullPath,
  });
  return response.data;
};

const qcApi = {
  getQCData,
  getQCFileData,
  controlQC,
  downloadQCLog,
  deleteQCLog,
};

export default qcApi;
