import MDBox from "components/MDBox";
import React from "react";

type Props = {
  isShow: boolean;
};

function Dimmer({ isShow }: Props) {
  if (!isShow) return null;
  return (
    <MDBox
      position="fixed"
      width="100%"
      height="100vh"
      bgColor="black"
      opacity={0.5}
      zIndex={1250}
    />
  );
}

export default Dimmer;
