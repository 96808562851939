import {ReactElement, ReactNode} from "react";

// @mui material components
import List from "@mui/material/List";

const ListSX = {
  px: 2,
  my: 0.3,
};

function SidenavList({ children }: { children: ReactNode }): ReactElement {
  return <List sx={ListSX}>{children}</List>;
}

export default SidenavList;
