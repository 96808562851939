export const routePathname = {
  SIGN_IN: "/sign-in",
  CHANGE_PASSWORD: "/auth/change-password",
  SIGN_OUT: "/auth/sign-out",
  DASHBOARD: "/dashboard",
  ACCOUNT: "/account",
  SETTING: "/setting",
  LOG: "/log",
  QC_MODULE: "/qc-module",
  QC_CELL: "/qc-cell"
} as const;
