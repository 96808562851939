import React, {
  ChangeEvent,
  FocusEvent, ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

// Components
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import pxToRem from "assets/theme/functions/pxToRem";

// Declaring props types for DefaultInputCell
interface Props {
  use?: boolean;
  input?: boolean;
  index?: number;
  type?: string;
  label?: string;
  value?: string;
  name?: string;
  placeholder?: string;
  onChangeHandler?: any;
  disabled?: boolean;
  isChangeOn?: boolean;
}

const BoxSX = (disabled: boolean) => ({
  cursor: disabled ? "not-allowed" : "default",
  minHeight: pxToRem(34),
  maxHeight: pxToRem(34),
});

function DefaultInputCell({
  use,
  input,
  index,
  type,
  label,
  value,
  name,
  placeholder,
  onChangeHandler,
  disabled,
  isChangeOn = false,
}: Props): ReactElement {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChangeInputValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      isChangeOn && onChangeHandler(index, value, name);
    },
    [index, name, onChangeHandler, isChangeOn]
  );

  const handleBlurInputValue = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (value === inputValue) return;
      const { name } = e.target;
      onChangeHandler(index, inputValue, name);
    },
    [index, value, inputValue, onChangeHandler]
  );

  return use ? (
    input ? (
      <MDBox display="flex" alignItems="center" sx={BoxSX(disabled)}>
        <MDInput
          type={type}
          label={label}
          placeholder={placeholder}
          fullWidth
          value={inputValue}
          name={name}
          onChange={handleChangeInputValue}
          onBlur={handleBlurInputValue}
          disabled={disabled}
        />
      </MDBox>
    ) : (
      <MDTypography variant="caption" fontWeight="medium" color="text">
        {inputValue}
      </MDTypography>
    )
  ) : (
    <MDBox display="flex" alignItems="center"></MDBox>
  );
}

// Declaring default props for DefaultInputCell
DefaultInputCell.defaultProps = {
  use: false,
  input: false,
  index: -1,
  type: "text",
  label: "",
  value: "",
  onChangeHandler: null,
};

export default DefaultInputCell;
