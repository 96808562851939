import {ReactElement, ReactNode } from "react";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Components
import PageLayout from "layouts/components/layoutContainers/pageLayout";
import Card from "@mui/material/Card";

// Declaring props types for IllustrationLayout
interface Props {
  title?: string;
  description?: string;
  children: ReactNode;
}

function AuthenticationApiLayout({
  title = "",
  description = "",
  children,
}: Props): ReactElement {

  return (
    <PageLayout>
      <video
        autoPlay
        muted
        loop
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      >
        <source src="/video/intro_1080p.m4v" type="video/mp4" />
      </video>
      <Card
        sx={{
          overflow: "hidden",
          top: "50%",
          left: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <MDBox py={2} px={2} textAlign="center">
          <MDBox mb={1} textAlign="center">
            <MDTypography
              variant="h4"
              fontWeight="bold"
              color="white"
            >
              {title}
            </MDTypography>
          </MDBox>
          <MDTypography variant="body2" color="white">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>{children}</MDBox>
      </Card>
    </PageLayout>
  );
}

export default AuthenticationApiLayout;
