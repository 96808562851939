import DataTable from "layouts/components/dataTable";
import DefaultCard from "layouts/components/cards/defaultCard";
import useAccount from "./hooks/useAccount";
import { useTranslation } from "react-i18next";
import {ReactElement} from "react";
import pxToRem from "../../../../assets/theme/functions/pxToRem";

function Account(): ReactElement {
  const { accountTableData } = useAccount();
  const { t } = useTranslation("Translation");

  return (
    <DefaultCard icon="manage_accounts" title={t("Account")}>
      {accountTableData.rows.length !== 0 && (
        <DataTable
          table={accountTableData}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noPagination={true}
          scrollHeight={pxToRem(866)}
        />
      )}
    </DefaultCard>
  );
}

export default Account;
