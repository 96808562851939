import {ReactElement, ReactNode} from "react";

// Components
import MDBox from "components/MDBox";

// Declaring props types for PageLayout
interface Props {
  background?: "white" | "light" | "default";
  children: ReactNode;
}

const BoxSX = { overflow: "hidden" };

function PageLayout({ background, children }: Props): ReactElement {
  return (
    <MDBox
      width="100vw"
      height="100vh"
      bgColor={background}
      sx={BoxSX}
    >
      {children}
    </MDBox>
  );
}

// Declaring default props for PageLayout
PageLayout.defaultProps = {
  background: "default",
};

export default PageLayout;
