import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ko from "./ko/index.json";
import en from "./en/index.json";

const lng =
  navigator.language === "ko" || navigator.language === "ko-KR" ? "ko" : "en";

export const resources = {
  en,
  ko,
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
