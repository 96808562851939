import {useMemo, useEffect, memo, useRef, SyntheticEvent, ReactElement, useCallback} from "react";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  HeaderGroup,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

// Components
import MDBox from "components/MDBox";

// Components
import { isEqual } from "lodash";
import pxToRem from "assets/theme/functions/pxToRem";
import { useTranslation } from "react-i18next";
import Pagination from "./pagination";
import TotalEntries from "./totalEntries.tsx";
import NoData from "./noData";
import EntriesPerPage from "./entriesPerPage";
import TableRows from "./tableRows";
import TableHeader from "./tableHeader";
import { Colors } from "lib/types/material";
import { useRecoilValue } from "recoil";
import { uiConfigState } from "store/uiConfig";

const ENTRIES_ALL = "All";

export interface PaginationStyle {
  variant: "contained" | "gradient";
  color: Colors;
}

interface Props {
  entriesPerPage?:
    | false
    | {
        defaultValue: number;
        entries: string[];
        isEntriesUseAll?: boolean;
      };
  showTotalEntries?: boolean;
  table: {
    columns: { [key: string]: any }[];
    rows: { [key: string]: any }[];
  };
  paginationStyle?: PaginationStyle;
  isSorted?: boolean;
  isVirtual?: boolean;
  noEndBorder?: boolean;
  noPagination?: boolean;
  currentPage?: number;
  setPage?: (page: number) => void;
  setCSVPageItems?: (pageItems: Record<string, any>[]) => void;
  excludeSortColumns?: string[];
  scrollHeight?: number | string;
}

function DataTable({
  table,
  entriesPerPage = { defaultValue: 10, entries: ["5", "10", "15", "20", "25"] },
  showTotalEntries = true,
  paginationStyle = { variant: "gradient", color: "info" },
  isSorted = true,
  isVirtual = false,
  noEndBorder = false,
  noPagination = false,
  currentPage = 0,
  setPage = () => {},
  setCSVPageItems = () => {},
  excludeSortColumns = [],
  scrollHeight = pxToRem(738),
}: Props): ReactElement {
  const { darkMode } = useRecoilValue(uiConfigState);
  const { t } = useTranslation("Translation");
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const columns = useMemo<any>(() => table.columns, [table]);
  const data = useMemo<any>(() => table.rows, [table]);
  const defaultValue = useMemo(
    () => (entriesPerPage ? entriesPerPage.defaultValue : 10),
    [entriesPerPage]
  );
  const entries = useMemo(
    () =>
      entriesPerPage
        ? entriesPerPage.isEntriesUseAll
          ? [...entriesPerPage.entries, ENTRIES_ALL]
          : entriesPerPage.entries
        : [],
    [entriesPerPage]
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage },
      autoResetSortBy: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  // Set the default value for the entries per page when component mounts
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  const entriesEnd =
    pageIndex === 0 ? pageSize : pageSize * pageIndex + page.length;

  useEffect(() => {
    const rowItems = page.map((column) => column.values);
    setCSVPageItems(rowItems);
  }, [setCSVPageItems, page]);

  useEffect(() => setPageSize(defaultValue), [defaultValue, setPageSize]);
  useEffect(() => {
    if (
      entriesPerPage &&
      !entries.includes(String(pageSize)) &&
      pageSize !== rows.length
    )
      setPageSize(rows.length ? rows.length : defaultValue);
  }, [entriesPerPage, pageSize, setPageSize, rows, entries, defaultValue]);
  // Set the entries per page value based on the select value
  const setEntriesPerPage = useCallback((value: string) => {
    const pageSize =
      value === ENTRIES_ALL
        ? rows.length
          ? rows.length
          : defaultValue
        : Number(value);

    setPageSize(pageSize);
    setPage(0);
    gotoPage(0);
  }, [defaultValue, gotoPage, rows.length, setPage, setPageSize]);

  const customGotoPage = useCallback((page: number) => {
    tableContainerRef.current && tableContainerRef.current.scrollTo(0, 0);
    setPage(page);
    gotoPage(page);
  }, [setPage, gotoPage]);

  const handleChangeEntriesPerPage = useCallback((
    event: SyntheticEvent<Element, Event>,
    value: string
  ) => setEntriesPerPage(value), [setEntriesPerPage]);

  // A function that sets the sorted value for the table
  const setSortedValue = useCallback((column: HeaderGroup) => {
    if (excludeSortColumns.includes(column.id)) return false;
    if (isSorted && column.isSorted)
      return column.isSortedDesc ? "desc" : "asce";
    if (isSorted) return "none";
    return false;
  }, [excludeSortColumns, isSorted]);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <EntriesPerPage
        isShow={Boolean(entriesPerPage)}
        value={pageSize === rows.length ? ENTRIES_ALL : String(pageSize)}
        entries={entries}
        onChange={handleChangeEntriesPerPage}
        title={t("Entries per page")}
      />
      {tableContainerRef && <MDBox
        maxHeight={scrollHeight}
        overflow="auto"
        ref={!isVirtual ? null : tableContainerRef}
      >
        <Table {...getTableProps()}>
          <TableHeader
            headerBgColor={darkMode ? "dark" : "white"}
            headerGroups={headerGroups}
            isSorted={isSorted}
            excludeSortColumns={excludeSortColumns}
            setSortedValue={setSortedValue}
          />
          <TableBody {...getTableBodyProps()}>
            <TableRows
              virtualContainerRef={tableContainerRef}
              page={page}
              rows={rows}
              noEndBorder={noEndBorder}
              isVirtual={isVirtual}
              noPagination={noPagination}
              prepareRow={prepareRow}
            />
          </TableBody>
        </Table>
      </MDBox>}
      <NoData isShow={!rows.length} title={t("No data.")} />
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={
          (!showTotalEntries && pageOptions.length === 1) || noPagination
            ? 0
            : 3
        }
      >
        <TotalEntries
          isShow={showTotalEntries}
          title={t("Showing entries of all page", {
            totalItems: rows.length,
            startIndex: entriesStart,
            endIndex: entriesEnd > rows.length ? rows.length : entriesEnd,
          })}
        />
        <Pagination
          isShow={!noPagination && pageOptions.length > 1}
          pages={pageOptions}
          currentPage={pageIndex}
          setCurrentPage={customGotoPage}
          paginationStyle={paginationStyle}
        />
      </MDBox>
    </TableContainer>
  );
}

export default memo(DataTable, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
