import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { routePathname } from "lib/consts/routePathname";
import throttle from "lodash.throttle";
import React, {useCallback, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useRecoilState} from "recoil";
import {userState} from "store/user";
import useInterval from "lib/hooks/useInterval";
import authenticationApi from "apis/authentication/api";
import {INTERVAL_TIME} from "lib/consts/common";

function CurrentSessionTime() {
  const SESSION_SECOND = 300; // Second
  const SESSION_WARNING_CHECK_SECOND = 10; // Second
  const SESSION_REFRESH_CHECK_SECOND = 270; // Second

  const [user] = useRecoilState(userState);
  const [initSessionTime, setInitSessionTime] = useState(false);
  const [currentSessionTime, setCurrentSessionTime] = useState(SESSION_SECOND);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();
  const workerRef = useRef<Worker | null>(null);

  const convertSessionTime = (remainSecond: number) => {
    const now = new Date(remainSecond * 1000);
    const minute = String(now.getMinutes()).padStart(2, "0");
    const second = String(now.getSeconds()).padStart(2, "0");

    return [minute, second].join(" : ");
  };

  const getCurrentSessionTime = useCallback(async () => {
    if (user) {
      const sessionTime = await authenticationApi.getSessionTime(user);
      setCurrentSessionTime(sessionTime);
      if (!isInitialized) {
        setIsInitialized(true);
      }
    }
  }, [user, isInitialized]);

  useEffect(() => {
    getCurrentSessionTime().then();
  }, [getCurrentSessionTime]);

  const initSession = useCallback(() => {
    if (user && isInitialized) {
      if (currentSessionTime < SESSION_REFRESH_CHECK_SECOND) {
        setInitSessionTime(true);
      }
    }
  }, [user, isInitialized, currentSessionTime]);
  const throttledSessionInit = throttle(initSession, 1000);

  useEffect(() => {
    window.addEventListener("mousemove", throttledSessionInit);
    window.addEventListener('touchstart', throttledSessionInit);
    window.addEventListener('touchmove', throttledSessionInit);
    window.addEventListener('touchend', throttledSessionInit);
    return () => {
      window.removeEventListener("mousemove", throttledSessionInit);
      window.removeEventListener('touchstart', throttledSessionInit);
      window.removeEventListener('touchmove', throttledSessionInit);
      window.removeEventListener('touchend', throttledSessionInit);
    };
  }, [throttledSessionInit]);

  function sessionTimeWorkerFunction() {
    const INTERVAL_TIME = 1000;
    function startInterval() {
      setInterval(() => {
        postMessage("tick");
      }, INTERVAL_TIME);
    }
    onmessage = (event) => {
      if (event.data === "start") {
        startInterval();
      }
    };
  }

  useEffect(() => {
    const workerBlob = new Blob([`(${sessionTimeWorkerFunction})()`], { type: 'application/javascript' });
    workerRef.current = new Worker(URL.createObjectURL(workerBlob));
    workerRef.current.postMessage("start");
    workerRef.current.onmessage = () => {
      if (user && isInitialized) {
        getCurrentSessionTime().then();
      }
    };
    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [getCurrentSessionTime, currentSessionTime, user, isInitialized]);

  useInterval(() => {
    if (user && isInitialized) {
      if (currentSessionTime === 0) {
        navigate(routePathname.SIGN_OUT, {replace: true});
      } else {
        if (initSessionTime) {
          setInitSessionTime(false);
          authenticationApi.initSessionTime(user).then();
        }
      }
    }
    },
    INTERVAL_TIME,
    true
  );

  return (
    <MDBox
      borderRadius="10px"
      display="flex"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      bgColor={
        currentSessionTime < SESSION_WARNING_CHECK_SECOND
          ? "error"
          : "#485866"
      }
      minHeight={32}
      minWidth={60}

    >
      <MDTypography variant="h5" color="white" className="digital-time" sx={{mt: 0.4}}>
        {convertSessionTime(currentSessionTime)}
      </MDTypography>
    </MDBox>
  );
}

export default CurrentSessionTime;
